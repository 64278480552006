import React, {useRef, useState} from "react";
import { observer } from "mobx-react-lite";
import {Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import {CCol, CContainer, CInputGroup, CSearchBtn} from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import {findCommKrnm} from "../../utils/commcode";

/**
 * @param {Function} callbackFn: 결과값 받을 콜백 함수
 * @param {String} column: 반환값 받을때 같이 받을 컬럼명(구분값)
*/
const EstimateSearch =({ callbackFn=null, column='', ...props })=> {
    const gridRef = useRef();
    const [customerNo, setCustomerNo] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [saleUserName, setSaleUserName] = useState('');
    const [estimateList, setEstimateList] = useState([]);
    const [status, setStatus] = useState(2);

    const onSearchEstimateList = async () => {
        const result = await axios.get('/estimate/list', { params: {customerNo, customerName, saleUserName, status}});
        setEstimateList(result);
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data, column);
        AppStore.toggleEstimateSearchModal();
    }

    const refresh = () => {
        setCustomerNo('');
        setCustomerName('');
        setEstimateList([]);
    }

    return (
        <Modal
            title={'견적조회'}
            show={AppStore.isOpenEstimateSearch}
            onHide={() => AppStore.toggleEstimateSearchModal()}
            toggleValue={AppStore.isOpenEstimateSearch}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup label={'고객코드'} labelId={'customerNo'} value={customerNo} onChange={(v)=> setCustomerNo(v.target.value)} enterKeyHint={onSearchEstimateList} />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'고객명'} labelId={'customerName'} value={customerName} onChange={(v)=> setCustomerName(v.target.value)} enterKeyHint={onSearchEstimateList} />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'견적담당자'} labelId={'saleUserName'} value={saleUserName} onChange={(v)=> setSaleUserName(v.target.value)} enterKeyHint={onSearchEstimateList} />
                    </CCol>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isContracted"
                            label="견적 완료건"
                            style={{marginTop: 4}}
                            checked={status === 2}
                            onChange={v => setStatus(v.target.checked ? 2 : null)}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearchEstimateList} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={40}
                    rowData={estimateList}
                    columnDefs={
                        [
                            {field: "companyName", headerName: "회사", width: 150},
                            {field: "estimateNo", headerName: "견적번호", width: 120},
                            {field: "status", headerName: "상태", valueFormatter: v => v.value === 2 ? '완료' : v.value === 9 ? '취소' : '진행중', width: 90},
                            {field: "estimateDate", headerName: "견적일자", width: 180},
                            {field: "customerNo", headerName: "고객번호", hide: true},
                            {field: "customerName", headerName: "고객명", width: 160},
                            {field: "contractType", headerName: "계약유형", width: 120, valueFormatter: v => findCommKrnm(v.value, '1')},
                            {field: "productName", headerName: "견적품목", width: 190},
                            {field: "saleUserName", headerName: "견적담당자", width: 110},
                            {field: "managerName", headerName: "고객담당자", width: 110},
                            {field: "contractStatus", headerName: "계약여부", width: 120},
                            {field: "saleUserSeq", headerName: "견적담당자 seq", hide: true},
                            {field: "rentMonth", headerName: "계약월", hide: true},
                            {field: "rentDays", headerName: "계약일", hide: true},
                            {field: "contractDate", headerName: "계약일자", hide: true},
                            {field: "contractStartDate", headerName: "계약개시일", hide: true},
                            {field: "contractEndDate", headerName: "계약종료일", hide: true},
                            {field: "isPenaltyFee", headerName: "위약금여부", hide: true},
                            {field: "penaltyPercent", headerName: "위약금율", hide: true},
                            {field: "isDeliveryPrice", headerName: "운반비여부", hide: true},
                            {field: "deliveryPrice", headerName: "운반비", hide: true},
                            {field: "isExtendRate", headerName: "연장할인여부", hide: true},
                            {field: "extendFirstRate", headerName: "1차연장할인율", hide: true},
                            {field: "extendSecondRate", headerName: "2차연장할인율", hide: true},
                            {field: "isRepairCost", headerName: "수리비청구여부", hide: true},
                            {field: "isInstallPrice", headerName: "설치비청구여부", hide: true},
                            {field: "installPrice", headerName: "설치비", hide: true},
                            {field: "specialRemark", headerName: "특이사항", hide: true},
                            {field: "billingType", headerName: "청구조건", hide: true},
                            {field: "billingDay", headerName: "청구일자", hide: true},
                            {field: "dueDateType", headerName: "납기조건", hide: true},
                            {field: "dueDateDay", headerName: "납기일자", hide: true},
                            {field: "paymentType", headerName: "결제방식 (현금,카드,어음등)", hide: true},
                            {field: "deposit", headerName: "보증금", hide: true},
                            {field: "isFreeAssignment", headerName: "무상양도여부", hide: true},
                            {field: "billingAddressSeq", headerName: "청구지 seq", hide: true},
                            {field: "billingAddressName", headerName: "청구지명", hide: true},
                            {field: "customerManagerSeq", headerName: "고객담당자 시퀀스", hide: true},
                        ]
                    }
                    isCheckBox={false}
                    useIntoTheTab={true}
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(EstimateSearch);
