import React, {useRef, useEffect, useState} from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import {Row, Col, Tab, Tabs, InputGroup, Form} from "react-bootstrap";
import {CContainer, CInputGroup, CCol, Subject, CSaveBtn, CSelectGroup, CalendarGroup,} from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import { AssetStore } from '../../store/asset/AssetStore';
import {getUserAuthGroup, getUserName} from '../../utils/userUtils';
import {findCommKrnm, getCommcode} from "../../utils/commcode";
import {dateTimeFormat} from "../../utils/dateUtils";
import {showToast} from "../../common/utils";
import {callConfirm} from "../../utils";
import LUtils from "../../utils/lodashUtils";
import {ICON_ADD, MULTIPART_HEADER} from "../../common/constants";
import { AppStore } from '../../store/AppStore';
import AddOptionPopup from '../../components/searchModal/AddOptionPopup';

const AssetDetail = ({assetNo}) => {
    const gridRef = useRef();
    const optionGridRef = useRef();
    const imgRef = useRef();

    const [selectTab, setSelectTab] = useState('repair');
    const [certificateCheckDate, setCertificateCheckDate] = useState({startDate: null, endDate: null});
    const [apronSeq, setApronSeq] = useState(null);

    /* 기본값 세팅 */
    useEffect(() => {
        Promise.all([getAsset(), getAssetOptionList(), getAssetHistory(), getAssetRepairHistory()]);
    }, []);

    /* 상세 조회 */
    const getAsset = async () => {
        const result = await axios.get('/asset', {params: {assetNo: assetNo}});
        AssetStore.assetDetail = result;

        if(result.certificateCheckDate != null){
            setCertificateCheckDate({startDate: result.certificateCheckDate, endDate: result.certificateCheckDate});
        }
    }

    /* asset option list 조회 */
    const getAssetOptionList = async () => {
        const result = await axios.get('/asset/option', {params: {assetNo: assetNo}});
        AssetStore.assetOptionList = result;
    }

    /* 자산 이력 조회 */
    const getAssetHistory =async()=> {
        const resultList = await axios.get('/asset/histories', { params: {assetNo: assetNo}});
        AssetStore.setAssetHistoryList(resultList);
    }

    /* 수리이력조회 */
    const getAssetRepairHistory =async()=> {
        const result = await axios.get('/asset/maintenance/details', {params: {assetNo: assetNo}});
        AssetStore.setAssetRepairHistoryList(result);
    }

    const columnDefs = [
        {field: "assetNo", headerName: "자산번호", minWidth: 120, hide: true},
        {field: "seq", headerName: "순번", minWidth: 60},
        {field: "optionProductName", headerName: "제품명", minWidth: 180},
        {field: "optionModel", headerName: "모델명", minWidth: 150},
        {field: "qty", headerName: "수량", minWidth: 90},
        {field: "optionAssetNo", headerName: "옵션 자산번호", minWidth: 150},
        {field: "storageNo", headerName: "옵션 입고번호", minWidth: 150, hide: true},
        {field: "isUse", headerName: "사용유무", width: 110, valueFormatter: v => v.value === 0 ? 'N' : 'Y'},
        {field: "creator", headerName: "출고자", valueFormatter: v => getUserName(v.value)},
        {field: "createdDate", headerName: "출고일자", minWidth: 180},
        {field: "updater", headerName: "수정자", valueFormatter: v => getUserName(v.value)},
        {field: "updatedDate", headerName: "수정일자", minWidth: 180},
        {field: "storageDate", headerName: "입고일자", minWidth: 180},
    ];

    const localeMoneyString =(val)=> {
        return val ? Number(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
    }

    const isChangeStatusCheck =(code)=> {
        //관리자, 기술팀만 수정 가능
        if(code && (getUserAuthGroup() === 1 || getUserAuthGroup() === 2 || getUserAuthGroup() === 9 || getUserAuthGroup() === 10)){
            if(code === '200' || code === '202'){
                return true;
            }else{
                return false;
            }
        }
    }

    const assetChangeSave =async()=> {

        const params = {
            managementNo: AssetStore.assetDetail.managementNo,
            serialNo: AssetStore.assetDetail.serialNo,
            exteriorStatus: AssetStore.assetDetail.exteriorStatus,
            tireStatus: AssetStore.assetDetail.tireStatus,
            assetStatus: AssetStore.assetDetail.assetStatus !== AssetStore.assetStatus ? AssetStore.assetStatus : null,
            certificateCheckDate: AssetStore.assetDetail.certificateCheckDate,
            assetCertificateUrl: AssetStore.assetDetail.assetCertificateUrl,
            assetCertificateFileName: AssetStore.assetDetail.assetCertificateFileName,
            remark: AssetStore.assetDetail.remark
        }

        if(!await callConfirm(`저장하시겠습니까?`)) return;


        await axios.put(`/asset/${AssetStore.assetDetail.assetNo}`, params);
        showToast('변경되었습니다.');

        await getAsset();
        await getAssetHistory();
    }

    /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData, type) => {

        if (LUtils.isEmpty(fileData.target.files) && type === 'assetCertificate') {
            AssetStore.assetDetail.assetCertificateUrl = '';
            return false;
        }else if (LUtils.isEmpty(fileData.target.files) && type === 'insurancePolicy') {
            AssetStore.assetDetail.insurancePolicyUrl = '';
            return false;
        }

        if(!await callConfirm('인증서를 업로드 하시겠습니까?')) return;

        const file = fileData.target.files[0];
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop();

        const formData = new FormData();
        formData.append('file', file);
        let result = [];
        if (fileExtension.toLowerCase() === 'pdf') {
            result = await axios.post('/file/upload/s3/pdf', formData, { headers: MULTIPART_HEADER });
        }else {
            result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });
        }

        if(type === 'assetCertificate') {
            AssetStore.assetDetail.assetCertificateUrl = result;
            AssetStore.assetDetail.assetCertificateFileName = file.name;
            await axios.put(`asset/certificateFile/${AssetStore.assetDetail.assetNo}/${type}`,
            {url: AssetStore.assetDetail.assetCertificateUrl, name: AssetStore.assetDetail.assetCertificateFileName});
        }else if(type === 'insurancePolicy') {
            AssetStore.assetDetail.insurancePolicyUrl = result;
            AssetStore.assetDetail.insurancePolicyFileName = file.name;
            await axios.put(`asset/certificateFile/${AssetStore.assetDetail.assetNo}/${type}`,
            {url: AssetStore.assetDetail.insurancePolicyUrl, name: AssetStore.assetDetail.insurancePolicyFileName});
        }
        showToast('완료되었습니다.');
    }

    const addOptionBtn = {
        isUsed: true,
        title: '옵션 결합',
        callbackFn: () => {setApronSeq(AssetStore.assetDetail.apronSeq); AppStore.toggleAddOptionPopup()},
        icon: ICON_ADD,
        variant: 'primary'
    };

    const deleteOptionBtn = {
        isUsed: true,
        title: '옵션 해제',
        callbackFn: () => deleteOption(),
        icon: 'fi fi-br-ban',
        variant: 'danger'
    };

    const deleteOption = async () => {
        if(!confirm('옵션 결합을 해제하시겠습니까?')) {
            return;
        }

        const selectedList = AssetStore.assetOptionList.filter(v => v.isSelected);
        await axios.put('asset/option/list', {assetOptionList: selectedList});

        getAssetOptionList();
        showToast('옵션 결합을 해제했습니다.');
    }

    return (
        <>
            {/* 검색조건 */}
            <CContainer search>
                <Subject>자산정보</Subject>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'자산번호'} labelId={'assetNo'}
                                     value={AssetStore.assetDetail.assetNo}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'관리번호'} labelId={'managementNo'}
                                     value={AssetStore.assetDetail.managementNo}
                                     onChange={v => AssetStore.assetDetail.managementNo = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'자산구분'} labelId={'assetType'}
                                     value={findCommKrnm(String(AssetStore.assetDetail.assetType), '7')}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup label={'자산상태'} labelId={'assetStatus'}
                                     options={getCommcode('8')}
                                     value={AssetStore.assetDetail.assetStatus}
                                     //disabled={!isChangeStatusCheck(AssetStore.assetDetail.assetStatus)}
                                     disabled={true}
                                     onChange={(e)=> AssetStore.setAssetStatus(e.target.value)}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'대분류'} labelId={'productGroupName'}
                                     value={AssetStore.assetDetail.productGroupName}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup label={'모델명'} labelId={'model'}
                                     value={AssetStore.assetDetail.productName}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup label={'시리얼번호'} labelId={'serialNo'}
                                     value={AssetStore.assetDetail.serialNo}
                                     onChange={v => AssetStore.assetDetail.serialNo = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'신품/중고'} labelId={'newOrUsed'}
                                     value={AssetStore.assetDetail.newOrUsed === '2' ? '중고' : '신품'}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'취득가'} labelId={'acquAmount'}
                                     value={localeMoneyString(AssetStore.assetDetail.acquAmount)}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'취득일'} labelId={'acquDate'}
                                     value={AssetStore.assetDetail.acquDate}
                                     disabled={true}
                        />
                    </CCol>
{/*
                    <CCol lg={2}>
                        <CInputGroup label={'상각코드'} labelId={'ifrsCode'}
                                     value={AssetStore.assetDetail.ifrsCode}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'장부가'} labelId={'booksPrice'}
                                     value={localeMoneyString(AssetStore.assetDetail.booksPrice)}
                                     disabled={true}
                        />
                    </CCol>
*/}
                    <CCol lg={2}>
                        <CInputGroup label={'매출총액'} labelId={'totalRentAmount'}
                                     mo
                                     value={localeMoneyString(AssetStore.assetDetail.totalRentAmount)}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'회수율'} labelId={'returnRate'}
                                     value={AssetStore.assetDetail.returnRate ? AssetStore.assetDetail.returnRate + '%' : ''}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'총 보유일수'} labelId={'totalRetentionDay'}
                                     value={AssetStore.assetDetail.totalRetentionDay ? AssetStore.assetDetail.totalRetentionDay+'일' : ''}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'재고 보유일수'} labelId={'stockRetentionDay'}
                                     value={AssetStore.assetDetail.stockRetentionDay ? AssetStore.assetDetail.stockRetentionDay + '일' : ''}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'연식'} labelId={'manufacturing'}
                                     value={AssetStore.assetDetail.manufacturing}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'구입방법'} labelId={'howToBuy'}
                                     value={AssetStore.assetDetail.howToBuy}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup label={'구입처'} labelId={'whereToBuy'}
                                     value={AssetStore.assetDetail.whereToBuy}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'주기장'} labelId={'apronSeq'}
                                     value={AssetStore.assetDetail.apronName}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup label={'외관상태'} labelId={'exteriorStatus'}
                                     options={[{value: 'A', name: 'A'}, {value: 'B', name: 'B'}, {value: 'C', name: 'C'}]}
                                     value={AssetStore.assetDetail.exteriorStatus}
                                     onChange={(e)=> AssetStore.assetDetail.exteriorStatus = e.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'타이어상태'} labelId={'tireStatus'}
                                     options={[{value: 'A', name: 'A'}, {value: 'B', name: 'B'}, {value: 'C', name: 'C'}]}
                                     value={AssetStore.assetDetail.tireStatus}
                                     onChange={(e)=> AssetStore.assetDetail.tireStatus = e.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'가동시간'} labelId={'operationTime'}
                                    value={AssetStore.assetDetail.operationTime}
                                    disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'주행거리'} labelId={'mileage'}
                                    value={AssetStore.assetDetail.mileage}
                                    disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'정기 유지보수 주기'} labelId={'maintenanceCycleMonth'}
                                    value={AssetStore.assetDetail.maintenanceCycleMonth}
                                    disabled={true}
                                    backLabel={'개월'}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Subject>마지막 계약 정보</Subject>
                    <CCol lg={3}>
                        <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                     value={AssetStore.assetDetail.contractNo}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'고객명'} labelId={'customerName'}
                                     value={AssetStore.assetDetail.customerName}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'최근출고일'} labelId={'lastReleaseDate'}
                                     value={AssetStore.assetDetail.lastReleaseDate}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Subject>매각정보</Subject>
                    <CCol lg={3}>
                        <CInputGroup label={'매각구분'} labelId={'sellType'}
                                     value={AssetStore.assetDetail.sellType}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'매각금액'} labelId={'sellAmount'}
                                     value={AssetStore.assetDetail.sellAmount}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'매각일자'} labelId={'sellDate'}
                                     value={AssetStore.assetDetail.sellDate}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Subject>장비서류</Subject>
                    <CCol lg={4}>
                        <CalendarGroup
                            label={'안전인증일'}
                            asSingle={true}
                            value={certificateCheckDate}
                            onChange={(v)=> {
                                setCertificateCheckDate(v);
                                AssetStore.assetDetail.certificateCheckDate = v.startDate;
                            }}

                        />
                    </CCol>
                    <CCol lg={5}>
                            <InputGroup>
                                <label style={{paddingTop: 5, marginRight: 10}}>
                                    {AssetStore.assetDetail.assetCertificateFileName ?
                                        <>
                                            안전인증서 :
                                            <a target={'_blank'} style={{paddingLeft: 4}} href={AssetStore.assetDetail.assetCertificateUrl}>
                                                {AssetStore.assetDetail.assetCertificateFileName}
                                            </a>
                                        </>
                                    : '안전인증서 업로드'}
                                </label>
                                {AssetStore.assetDetail.assetCertificateFileName ?
                                    <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> AssetStore.assetDetail.assetCertificateFileName = ''} />
                                :
                                    <Form.Control
                                        type="file"
                                        id="profileImg"
                                        size="sm"
                                        placeholder={'안전인증서 업로드'}
                                        onChange={(v) => fileUploadForS3(v, 'assetCertificate')}
                                        ref={imgRef}
                                    />
                                }
                            </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={4}>
                        <CalendarGroup
                            asSingle={true}
                            label={'임차자산 반납예정일'}
                            labelId={'hireEndDate'}
                            value={AssetStore.assetDetail.hireEndDate}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={5}>
                            <InputGroup>
                                <label style={{paddingTop: 5, marginRight: 10}}>
                                    {AssetStore.assetDetail.insurancePolicyFileName ?
                                        <>
                                            보험가입증 :
                                            <a target={'_blank'} style={{paddingLeft: 4}} href={AssetStore.assetDetail.insurancePolicyUrl}>
                                                {AssetStore.assetDetail.insurancePolicyFileName}
                                            </a>
                                        </>
                                    : '보험가입증 업로드'}
                                </label>
                                {AssetStore.assetDetail.insurancePolicyFileName ?
                                    <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> AssetStore.assetDetail.insurancePolicyFileName = ''} />
                                :
                                    <Form.Control
                                        type="file"
                                        id="profileImg"
                                        size="sm"
                                        placeholder={'보험가입증 업로드'}
                                        onChange={(v) => fileUploadForS3(v, 'insurancePolicy')}
                                        ref={imgRef}
                                    />
                                }
                            </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={9}>
                        {/*
                        <CInputGroup label={'비고'} labelId={'remark'}
                                     value={AssetStore.assetDetail.remark}
                                     onChange={(e)=> AssetStore.assetDetail.remark = e.target.value}
                        />
                        */}
                        <div className="mb-3">
                            <Subject>비고</Subject>
                            <textarea className="form-control" rows="2"
                                      value={AssetStore.assetDetail.remark}
                                      onChange={(e)=> AssetStore.assetDetail.remark = e.target.value}
                            />
                        </div>
                    </CCol>
                </Row>
                <Row>
                <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn style={{width: 100, height: 30}}
                                  title={'저장'}
                                  onClick={() => assetChangeSave()}
                        />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <Tabs
                    defaultActiveKey="history"
                    className="mb-3"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    activeKey={selectTab}
                    onSelect={k => setSelectTab(k)}
                >
                    <Tab eventKey="repair" title={'정비 이력'}>
                        <AgGridContainer
                            gridRef={gridRef}
                            height={30}
                            rowData={AssetStore.assetRepairHistoryList}
                            columnDefs={[
                                {field: "maintenanceDate", headerName: "정비완료일", width: 180},
                                {field: "mechanicName", headerName: "정비자"},
                                {field: "amount", headerName: "수리비", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 120},
                                {field: "content", headerName: "내용", width: 350, flex: 1},
                            ]}
                            useCsvDownload={true}
                            isCheckBox={true}
                            // useUpdated={true}
                            // callBackGridData={callBackRepairSave}
                        />
                    </Tab>
                    <Tab eventKey="option" title={'옵션'}>
                        <AgGridContainer
                            gridRef={optionGridRef}
                            height={30}
                            rowData={AssetStore.assetOptionList}
                            columnDefs={columnDefs}
                            useCsvDownload={true}
                            getRowStyle={(v) => {
                                if (v.data.isUse !== 1) {
                                    return { color: '#bababa', pointerEvents: 'none', };
                                }
                                return null;
                            }}
                            customBtnInfo={[addOptionBtn, deleteOptionBtn]}
                            isCheckBox={true}
                        />
                    </Tab>
                    <Tab eventKey="history" title="자산 상태 이력">
                        <AgGridContainer
                            height={30}
                            isCheckBox={false}
                            rowData={AssetStore.assetHistoryList}
                            columnDefs={[
                                {field: "assetHistorySeq", headerName: "순번", hide:true},
                                {field: "historyOrder", headerName: "순번", width: 80},
                                {field: "assetNo", headerName: "자산번호", width: 120},
                                {field: "assetType", headerName: "자산구분", width: 110, valueFormatter: v => Number(v.value) === 1 ? '당사자산' : '임차자산'},
                                {field: "conditionStatus", headerName: "자산상태", width: 110, hide: true},
                                {field: "workStatus", headerName: "처리상태", width: 110, hide: true},
                                {field: "assetStatus", headerName: "자산상태", width: 110, valueFormatter: v => findCommKrnm(v.value, '8')},
                                {field: "teamCode", headerName: "팀코드", hide: true},
                                {field: "customerName", headerName: "고객명", width: 140},
                                {field: "contractNo", headerName: "계약번호", width: 150},
                                {field: "contractType", headerName: "계약구분", width: 110, valueFormatter: v => findCommKrnm(v.value, '1')},
                                {field: "serialNo", headerName: "시리얼번호", width: 150},
                                {field: "exteriorStatus", headerName: "외관상태", width: 110},
                                {field: "tireStatus", headerName: "타이어상태", width: 120},
                                {field: "operationTime", headerName: "가동시간", width: 120},
                                {field: "mileage", headerName: "주행거리", width: 120},
                                /*        {field: "acquAmount", headerName: "취득가", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                        {field: "acquDate", headerName: "취득일", valueGetter: v => dateTimeFormat(v.data.acquDate)},*/
                                {field: "assetCertificateFileName", headerName: "안전인증서", width: 140},
                                {field: "insurancePolicyFileName", headerName: "보험가입증", width: 140},
                                {field: "sellAmt", headerName: "매각금액", width: 120, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                                {field: "sellDate", headerName: "매각일자", width: 120},
                                {field: "sellType", headerName: "매각구분", width: 120, valueFormatter: v => findCommKrnm(v.value, '9')},
                                {field: "hireEndDate", headerName: "임차자산 반납 예정일", width: 200, hide: !AssetStore.assetStatus.hireEndDate},
                                {field: "remark", headerName: "비고", width: 200},
                                {field: "creator", headerName: "수정자", valueFormatter: v => getUserName(v.value)},
                                {field: "createdDate", headerName: "수정일", minWidth: 200, flex:2, valueGetter: v => dateTimeFormat(v.data.createdDate)},
                            ]}
                            useCsvDownload={true}
                        />
                    </Tab>
                </Tabs>
            </CContainer>
            <AddOptionPopup assetNo={assetNo} apronSeq={apronSeq} selProductList={AssetStore.assetDetail} getAssetOptionList={getAssetOptionList} />
        </>
    );
};

export default observer(AssetDetail);
