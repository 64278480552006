import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import dayjs from "dayjs";

import { CCol, CContainer, CInputGroup, CInputDoubleGroup, CSelectGroup, CIconBtn, CalendarWithLabel } from "../../components/CustomContainer";
import { getCommcode } from "../../utils/commcode";
import { ContractCreateDetailStore } from "../../store/contract/ContractCreateDetailStore";
import ContractCreationInfo from "./tabs/ContractCreationInfo";
import { AppStore } from "../../store/AppStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import LUtils from "../../utils/lodashUtils";
import { groupByForTwoColumn } from "../../utils";
import { ContractTabsStore } from "../../store/contract/ContractTabsStore";
import EstimateSearch from "../../components/searchModal/EstimateSearch";
import contractUtils from "./contractUtils";
import {addDay, today} from "../../utils/dateUtils";
import {getCompanyList, getSessionUserCompanyCode, getSessionUserSeq, getUserName} from "../../utils/userUtils";
import {showToast} from "../../common/utils";

/*
    ContractCreateDetailStore.detailMode
    create : 계약등록, 출고의뢰전
    update : 출고의뢰 후
    read : 출고완료 및 확정이 완료된 계약
 */

const ContractCreation = () => {
    const [userColumn, setUserColumn] = useState('');
    const [optionList, setOptionList] = useState([{ contractSpecialOptionSeq: '', optionCode: '', qty: 1, amount: 0 }]);
    const [isOptionCost, setIsOptionCost] = useState(0);
    const [companyList, setCompanyList] = useState([]);

    useEffect(()=> {
        /*
        const contractNo = LUtils.get(location.state, 'contractNo', null) ;
        const releaseAskStatus = LUtils.get(location.state, 'releaseAskStatus', null) ;
        const sellDate = LUtils.get(location.state, 'sellDate', null) ;
        const isFix = LUtils.get(location.state, 'isFix', null) ;
        const pathname = LUtils.get(location, 'pathname', null);
        */
        ContractCreateDetailStore.setContractDetail(today(), 'contractDate');
        ContractCreateDetailStore.isFail = false;

        ContractCreateDetailStore.setContractDetailMode('create');
        // 영업담당자1 초기값
        ContractCreateDetailStore.setContractDetail(getSessionUserSeq(), 'saleUserSeq');
        ContractCreateDetailStore.setContractDetail(getUserName(getSessionUserSeq()), 'saleUserName');

        if(ContractCreateDetailStore.contractNo){
            ContractCreateDetailStore.init();
            // 영업담당자1 초기값
            ContractCreateDetailStore.setContractDetail(getSessionUserSeq(), 'saleUserSeq');
            ContractCreateDetailStore.setContractDetail(getUserName(getSessionUserSeq()), 'saleUserName');
        }

        async function getCompanyInfo(){
            //자회사정보담아두기
            setCompanyList(await getCompanyList());
            ContractCreateDetailStore.setContractDetail(await getSessionUserCompanyCode(),'companyCode');
        }
        getCompanyInfo();
    }, []);

    /* 계약 종료일 세팅 */
    useEffect(() => {
        const { rentMonth, rentDays } = ContractCreateDetailStore.createContractDays;
        const { startDate } = ContractCreateDetailStore.createContractStartDate;
        ContractCreateDetailStore.setContractDetail(rentMonth,  'rentMonth');
        ContractCreateDetailStore.setContractDetail(rentDays, 'rentDays');

        // 값이 있을때만 진행, 없으면 계약종료일 초기화
        if (!(startDate && (rentMonth || rentDays))) {
            ContractCreateDetailStore.createContractEndDate = { startDate: null, endDate: null };
            ContractCreateDetailStore.setContractDetail(null, 'contractEndDate');
            return;
        }

        let endDate = null;
        //반납형, 양도형 계약이면
        if(ContractCreateDetailStore.contractType == 1 || ContractCreateDetailStore.contractType == 2){
            // 종료일 계산
            endDate = contractUtils.contractEndDateFormat(startDate, rentMonth, rentDays);
        }else{
            endDate = startDate;
        }

        ContractCreateDetailStore.createContractEndDate = {startDate: endDate, endDate: endDate};
        ContractCreateDetailStore.setContractDetail(endDate, 'contractEndDate');

    }, [ContractCreateDetailStore.createContractDays, ContractCreateDetailStore.createContractStartDate]);

    /* 출고가능 상품 목록 조회 */
    const getProductList = async (storageNoList) => {
        const params = { storageType: 'sto_goods', storageNoList: '"' + storageNoList.join('","') + '"' };
        const result = await axios.get('/storage/list', { params });
        const groupList = groupByForTwoColumn(result, 'storageNo', 'productSeq', ['storageNo', 'productCode', 'model', 'productName', 'spec', 'productSeq']);
        ContractCreateDetailStore.setContractProductGroupList(groupList.map(v => LUtils.assign(v, { qty: v.rowCnt, availableQty: v.rowCnt })));
    }

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo =async(data) => {
        await getManager(data.customerNo);
        ContractCreateDetailStore.setContractDetail(data.customerName, 'customerName');
        ContractCreateDetailStore.setContractDetail(data.customerNo, 'customerNo');
        if (data.saleUserSeq) ContractCreateDetailStore.setContractDetail(data.saleUserSeq, 'saleUserSeq');
        if (data.saleUserName) ContractCreateDetailStore.setContractDetail(data.saleUserName, 'saleUserName');
        if (data.supportUserSeq) ContractCreateDetailStore.setContractDetail(data.supportUserSeq, 'supportUserSeq');
        if (data.supportUserName) ContractCreateDetailStore.setContractDetail(data.supportUserName, 'supportUserName');
        if (data.isPenaltyFee) ContractCreateDetailStore.setContractDetail(data.isPenaltyFee, 'isPenaltyFee');
        if (data.billingType) ContractCreateDetailStore.setContractDetail(data.billingType, 'billingType');
        if (data.billingDay) ContractCreateDetailStore.setContractDetail(data.billingDay, 'billingDay');
        if (data.dueDateType) ContractCreateDetailStore.setContractDetail(data.isPenaltyFee, 'isPenaltyFee');
        if (data.dueDateDay) ContractCreateDetailStore.setContractDetail(data.dueDateDay, 'dueDateDay');
        if (data.paymentType) ContractCreateDetailStore.setContractDetail(data.paymentType, 'paymentType');
        if (data.endDate || data.endDate === 0) ContractCreateDetailStore.setContractDetail(data.endDate, 'endDate');

        if(ContractCreateDetailStore.billingAddressSeq){
            ContractCreateDetailStore.setContractDetail('', 'billingAddressSeq');
            ContractCreateDetailStore.setContractDetail('', 'billingAddressName');
        }
    }

    /* 담당자명 조회 */
    const getManager = async (customerNo) => {
        const result = await axios.get('/customer/manager', { params: {customerNo: customerNo}});
        ContractCreateDetailStore.setContractDetail(result.customerManagerSeq, 'customerManagerSeq');
        ContractCreateDetailStore.setContractDetail(result.managerName, 'managerName');
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = (column) => {
        setUserColumn(column);
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {
        if (!userInfo || !column) {
            return false;
        }

        ContractCreateDetailStore.setContractDetail(userInfo.name, `${column}Name`);
        ContractCreateDetailStore.setContractDetail(userInfo.userSeq, `${column}Seq`);
        setUserColumn('');
    }

    /* 계약 기간 월 = 숫자, 최대 두 자리 */
    const handleMonthsChange = (event) => {
        if(event.target.value < 0){
            showToast('마이너스는 입력할 수 없어요.');
            return false;
        }
        const rentMonth = contractUtils.handleMonthsChange(event.target.value);
        ContractCreateDetailStore.createContractDays = { ...ContractCreateDetailStore.createContractDays, rentMonth };
        ContractCreateDetailStore.setContractDetail(event.target.value, 'rentMonth');
        ContractCreateDetailStore.contractProductList.forEach(item => {item.rentMonth = rentMonth;});
    };

    /* 계약 기간 일 = 숫자, 최대 30 */
    const handleDaysChange = (event) => {
        if(event.target.value < 0){
            showToast('마이너스는 입력할 수 없어요.');
            return false;
        }
        const rentDays = contractUtils.handleDaysChange(event.target.value);
        ContractCreateDetailStore.createContractDays = { ...ContractCreateDetailStore.createContractDays, rentDays };
        ContractCreateDetailStore.setContractDetail(event.target.value, 'rentDays')
        ContractCreateDetailStore.contractProductList.forEach(item => {item.rentDays = rentDays;});
    };

    /* 달력 선택 */
    const onChangeCalender = (target, value) => {
        /* 계약일자 */
        if (target === 'contractDate') {
            if (!contractUtils.dateDiffContractDate(value.startDate, ContractCreateDetailStore.createContractStartDate.startDate)) {
                showToast('계약개시일자는 계약일자보다 커야 합니다.');
                return false;
            }

            ContractCreateDetailStore.createContractDate = value;
            ContractCreateDetailStore.setContractDetail(value.startDate, target);
        }

        /* 계약시작일 */
        if (target === 'contractStartDate') {
            if (!contractUtils.dateDiffContractDate(ContractCreateDetailStore.createContractDate.startDate, value.startDate)) {
                showToast('계약개시일자는 계약일자보다 커야 합니다.');
                return false;
            }

            ContractCreateDetailStore.createContractStartDate = value;
            ContractCreateDetailStore.setContractDetail(value.startDate, target);

            //유통, 매각이면 청구조건을 개시일로 변경한다.
            if(ContractCreateDetailStore.contractType === '3' || ContractCreateDetailStore.contractType === '4'){
                const day = addDay(value.startDate, 0);
                ContractCreateDetailStore.setContractDetail(day, 'billingDay');
            }
        }
    }

    /* 견적 정보 콜백 함수 */
    const getEstimateInfo = (info) => {
        // estimateNo 로 견적 제품 목록 불러오기
        getEstimateProductList(info.estimateNo);

        ContractCreateDetailStore.companyCode = info.companyCode;
        //견적번호 저장
        ContractCreateDetailStore.estimateNo = info.estimateNo;
        // 입력값 하나라도 입력했을때는 확인창을 보여주고 선택하도록 한다
        ContractCreateDetailStore.contractType = info.contractType;
        ContractCreateDetailStore.customerNo = info.customerNo;
        ContractCreateDetailStore.customerName = info.customerName;
        ContractCreateDetailStore.customerManagerSeq = info.customerManagerSeq;
        if(info.customerManagerSeq) ContractCreateDetailStore.managerName = info.managerName;
        ContractCreateDetailStore.saleUserSeq = info.saleUserSeq;
        ContractCreateDetailStore.saleUserName = info.saleUserName;
        
        ContractCreateDetailStore.rentMonth = info.rentMonth;
        ContractCreateDetailStore.rentDays = info.rentDays;
        ContractCreateDetailStore.contractDate = dayjs(info.contractDate).format("YYYY-MM-DD");
        ContractCreateDetailStore.contractStartDate = dayjs(info.contractStartDate).format("YYYY-MM-DD");
        ContractCreateDetailStore.contractEndDate = dayjs(info.contractEndDate).format("YYYY-MM-DD");
        ContractCreateDetailStore.isPenaltyFee = info.isPenaltyFee;
        ContractCreateDetailStore.penaltyPercent = info.penaltyPercent;
        ContractCreateDetailStore.isDeliveryPrice = info.isDeliveryPrice;
        ContractCreateDetailStore.deliveryPrice = info.deliveryPrice;
        ContractCreateDetailStore.isExtendRate = info.isExtendRate;
        ContractCreateDetailStore.extendFirstRate = info.extendFirstRate;
        ContractCreateDetailStore.extendSecondRate = info.extendSecondRate;
        ContractCreateDetailStore.isRepairCost = info.isRepairCost;
        ContractCreateDetailStore.isInstallPrice = info.isInstallPrice;
        ContractCreateDetailStore.installPrice = info.installPrice;
        ContractCreateDetailStore.specialRemark = info.specialRemark;
        ContractCreateDetailStore.billingType = info.billingType;
        ContractCreateDetailStore.billingDay = info.billingDay;
        ContractCreateDetailStore.dueDateType = info.dueDateType;
        ContractCreateDetailStore.dueDateDay = info.dueDateDay;
        ContractCreateDetailStore.paymentType = info.paymentType;
        ContractCreateDetailStore.deposit = info.deposit || 0;
        ContractCreateDetailStore.isFreeAssignment = info.isFreeAssignment;
        ContractCreateDetailStore.billingAddressSeq = info.billingAddressSeq;
        ContractCreateDetailStore.billingAddressName = info.billingAddressName;
        //입력용과 전송용을 분리해서 사용한다.
        ContractCreateDetailStore.createContractDays = { rentMonth: info.rentMonth, rentDays: info.rentDays };
        ContractCreateDetailStore.createContractDate = { startDate: info.contractDate, endDate: info.contractDate };
        ContractCreateDetailStore.createContractStartDate = { startDate: info.contractStartDate, endDate: info.contractStartDate };
        ContractCreateDetailStore.createContractEndDate = { startDate: info.contractEndDate, endDate: info.contractEndDate };
    }

    /* 견적 제품 목록 불러오기 */
    const getEstimateProductList = async(estimateNo) => {
        const result = await axios.get('/estimateProduct/list', { params: {estimateNo: estimateNo} });

        if(result){
            ContractCreateDetailStore.setContractProductList(result.map(v => ({...v, qty: v.productQty})));
            ContractCreateDetailStore.setOriginContractProductList(result.map(v => ({...v, qty: v.productQty})));

            // 계약 제품을 조회 한 경우 출고가능 수량 확인을 위해 상품목록을 조회한다.
            //getProductList(LUtils.map(result, 'storageNo'));
        }
    }

    return(
        <div style={{marginBottom: 50, maxWidth: 2000}}>
            {/* content header */}
            {/*<ContentHeader title={title} depthList={['계약']} />*/}

            {/* 검색조건 */}
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup label={'회사'} labelId={'companyName'} 
                                    options={companyList}
                                    disabled={companyList.length === 1}
                                    value={ContractCreateDetailStore.companyCode}
                                    onChange={(e) => {ContractCreateDetailStore.setContractDetail(e.target.value, 'companyCode')}}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                     disabled={true}
                                     value={ContractCreateDetailStore.contractNo}
                        />
                    </CCol>
                    <CCol lg={1}>
                        <CInputGroup label={'회차'} labelId={'contractSeq'} disabled={true} value={ContractCreateDetailStore.contractSeq} />
                    </CCol>
                    {ContractCreateDetailStore.detailMode !== 'read' &&
                        <CCol lg={1}>
                            <CInputGroup label={'상태'}
                                         labelId={'contractStatus'}
                                         disabled={true}
                                         value={ContractCreateDetailStore.contractStatus === 0 ? '작성중' : '완료'}
                            />
                        </CCol>
                    }
                    <CCol lg={2}>
                        <CIconBtn
                            style={{width: 120}}
                            onClick={() => AppStore.toggleEstimateSearchModal()}
                            title={'견적불러오기'}
                            icon={'fi-rr-money-check-edit-alt'}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup
                            isValid={true}
                            label={'계약유형'}
                            options={getCommcode('1')}
                            value={ContractCreateDetailStore.contractType || ''}
                            onChange={v => {
                                //유통이나 매각 계약이면 1일로 처리한다
                                if(v.target.value === '3' || v.target.value === '4'){
                                    ContractCreateDetailStore.createContractDays = {rentDays: 1};
                                    ContractCreateDetailStore.setContractDetail(1, 'rentDays');

                                    //매각이면 매국구분을 선택한다.
                                    if(v.target.value === '4'){
                                        ContractCreateDetailStore.setContractDetail('1', 'sellType');
                                    }
                                }else{
                                    ContractCreateDetailStore.createContractDays = {rentMonth: '', rentDays: ''};
                                    ContractCreateDetailStore.setContractDetail('', 'rentDays');
                                }
                                ContractCreateDetailStore.setContractDetail(v.target.value, 'contractType');
                            }}
                            disabled={ContractCreateDetailStore.contractNo}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputDoubleGroup
                            isValid={true}
                            isValidCheck={!!ContractCreateDetailStore.customerName}
                            label={'고객명'}
                            labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            disabled={true} disabled2={true}
                            onCallbackBtn={openSearchCustomerPopup}
                            value={ContractCreateDetailStore.customerNo || ''}
                            value2={ContractCreateDetailStore.customerName || ''}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            isValid={true}
                            isValidCheck={!!ContractCreateDetailStore.saleUserSeq}
                            label={'영업담당자1'}
                            labelId={'saleUserName'}
                            value={ContractCreateDetailStore.saleUserName}
                            onCallbackBtn={() => openSearchUserPopup('saleUser')}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'영업담당자2'}
                            labelId={'supportUserName'}
                            value={ContractCreateDetailStore.supportUserName}
                            onCallbackBtn={() => openSearchUserPopup('supportUser')}
                            disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <Form.Label htmlFor="rentMonth" style={{color: '#DF013A'}}>계약기간</Form.Label>
                        <InputGroup size={'sm'}>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                value={ContractCreateDetailStore.rentMonth || ''}
                                onChange={v => {
                                    if(ContractCreateDetailStore.contractProductList.length > 0) {
                                        ContractCreateDetailStore.contractProductList.map(c => {
                                            c.rentMonth = v.target.value; 
                                            c.totalRentPrice = contractUtils.calculateTotalPrice(c);
                                            c.isUpdated = true;
                                        });
                                    }
                                    handleMonthsChange(v);
                                }}
                                type={'number'}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>개월</InputGroup.Text>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                value={ContractCreateDetailStore.rentDays || ''}
                                onChange={v => {
                                    if(ContractCreateDetailStore.contractProductList.length > 0) {
                                        ContractCreateDetailStore.contractProductList.map(c => {
                                            c.rentDays = v.target.value; 
                                            c.totalRentPrice = contractUtils.calculateTotalPrice(c);
                                            c.isUpdated = true;
                                        });
                                    }
                                    handleDaysChange(v);
                                }}
                                type={'number'}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>일</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <CalendarWithLabel
                            isValid={true}
                            asSingle={true}
                            label={'계약일자'}
                            value={ContractCreateDetailStore.createContractDate}
                            //minDate={today()}
                            onChange={v=> onChangeCalender('contractDate', v)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarWithLabel
                            isValid={true}
                            asSingle={true}
                            label={'계약개시일'}
                            value={ContractCreateDetailStore.createContractStartDate}
                            minDate={ContractCreateDetailStore.createContractDate.startDate}
                            onChange={v => onChangeCalender('contractStartDate', v)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarWithLabel
                            isValid={true}
                            asSingle={true}
                            label={'계약종료일'}
                            //minDate={today()}
                            value={ContractCreateDetailStore.createContractEndDate}
                            disabled={true}
                        />
                    </CCol>
                </Row>
            </CContainer>
            {/* modal */}
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo} column={userColumn}/>
            <EstimateSearch callbackFn={getEstimateInfo} />
            <CContainer>
                <Tabs
                    defaultActiveKey="contractInfo"
                    className="mb-3"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    activeKey={ContractTabsStore.contractTab}
                    onSelect={k => ContractTabsStore.setContractTab(k)}
                >
                    <Tab eventKey="contractInfo" title="기본정보">
                        <ContractCreationInfo
                                      contractDate={ContractCreateDetailStore.createContractDate.startDate}
                                      contractStartDate={ContractCreateDetailStore.createContractStartDate.startDate}
                                      contractEndDate={ContractCreateDetailStore.createContractEndDate.startDate}
                                      isOptionCost={isOptionCost}
                                      setIsOptionCost={setIsOptionCost}
                                      optionList={optionList}
                                      setOptionList={setOptionList}
                        />
                    </Tab>
                </Tabs>
            </CContainer>
        </div>
    );
}

export default observer(ContractCreation);
