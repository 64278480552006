import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Row } from "react-bootstrap";
import axios from 'axios';

import { CContainer } from "../../components/CustomContainer";
import { AppStore } from "../../store/AppStore";
import { getCommcode, findCommKrnm } from "../../utils/commcode";
import AgGridContainer from "../../components/AgGridContainer";
import { BillingListStore } from "../../store/billing/BillingListStore";
import { endOfAddMonth, addMonthYear, dateDiff, startDateForThisMonth, endDateForThisMonth, dateFormat } from '../../utils/dateUtils';
import ContractAssetSearch from "../../components/searchModal/ContractAssetSearch";
import { ICON_TRASH, ICON_ADD } from "../../common/constants";
import { callAlert, callConfirm } from '../../utils';
import BillingListSearch from "./BillingListSearch";
import { showToast } from "../../common/utils";
import BillingHistoryList from "./BillingHistoryList";
import CarryoverChargePopup from "./popup/CarryoverChargePopup";

const BillingList = () => {
    const gridRef = useRef();
    // const [selSearchUserType, setSelSearchUserType] = useState(0);
    const [billingList, setBillingList] = useState([]);
    const [selView, setSelView] = useState('contract');
    const [selectContractNo, setSelectContractNo] = useState('');
    const [selectAssetNo, setSelectAssetNo] = useState('');

    useEffect(() => {
        // 해당월 세팅
        const initSearchCondition = {
            startDate: startDateForThisMonth(),
            endDate: endDateForThisMonth(),
            strStartDate: startDateForThisMonth(),
            strEndDate: endDateForThisMonth(),
        }
        BillingListStore.setBillingSearchCondition(initSearchCondition);
    }, []);

    /* 청구 조회 */
    const getContractList = async()=> {
        const assetList = await axios.get('/billing/list', {params: BillingListStore.billingSearchCondition});
        const contractList = await axios.get('/billing/charge/list', {params: BillingListStore.billingSearchCondition});
        if(selView === 'contract' && contractList.length === 0){
            showToast('조회된 데이터가 없습니다.');
        }else if(selView === 'asset' && assetList.length === 0){
            showToast('조회된 데이터가 없습니다.');
        }
        BillingListStore.billingList = assetList;
        BillingListStore.billingChargeList = contractList;

        setBillingList(assetList);
    }

    /* 청구일 변경 시 이벤트 */
    const handleCellChargeDateChanged = async (e) => {
        let newDate = e.newValue;
        // isCreated 인 경우 원청구일 설정
        if (e.data.isCreated) {
            e.data.orgChargeDate = newDate;
        } else {
            // 원청구일 기준으로 한달 이상 불가능 => 입력된 날짜가 orgDate보다 한달 이후인지 확인
            const diffDays = dateDiff(e.data.orgChargeDate, newDate);

            if (diffDays > 30) {
                newDate = e.data.orgChargeDate;
                e.data.chargeDate = e.data.orgChargeDate;
                await callAlert('원청구일 기준 한달까지만 입력할 수 있습니다.');
            }
        }
        // 납기일 설정
        e.data.dueDate = e.data.dueDateDay === 0
            ? endOfAddMonth(newDate, e.data.dueDateType - 1)
            : addMonthYear(newDate, e.data.dueDateType - 1) + '-' + number.toString().padStart(e.data.dueDateDay, '0');
        gridRef.current.api.redrawRows();
    }

    /* 청구금액 변경 시 이벤트 */
    const handleCellChargeChanged = (e) => {
        if (e.data.isCreated) e.data.orgChargeAmount = e.newValue;
        e.data.chargeVat = e.newValue * 0.1;
        gridRef.current.api.redrawRows();
    }

    /* 청구부가세 변경 시 이벤트 */
    const onCellValueVatChanged = async (e) => {
        // 뒤의 두 자리를 제외한 부분이 같은지 비교
        const truncatedOldValue = e.oldValue.toString().slice(0, -2);
        const truncatedNewValue = e.newValue.toString().slice(0, -2);

        if (truncatedOldValue !== truncatedNewValue) {
            e.data.chargeVat = e.oldValue;
            showToast('10원단위만 변경 가능합니다.');
        }
        gridRef.current.api.redrawRows();
    }

    const onChangePayment =async(e)=> {
        gridRef.current.api.redrawRows();
    }

    /* 추가 버튼 */
    const customAddBtn = {
        isUsed: true,
        callbackFn: () => openContractAssetSearchPopup(),
        icon: ICON_ADD,
        title: '추가',
        width: 80,
    }

    /* 계약자산 조회 팝업 */
    const openContractAssetSearchPopup = () => {
        const selectedRow = BillingListStore.billingList.filter(v => v.isSelected === true)[0] || {};
        if (selectedRow !== undefined && Object.keys(selectedRow).length > 0) {
            const addIndex = BillingListStore.billingList.filter(v => v.isCreated).length || 0;
            const addRowInfo = {
                billingNo: '', contractNo: selectedRow.contractNo, assetNo: selectedRow.assetNo, managementNo: selectedRow.managementNo, billingType: '', billingSeq: selectedRow.billingSeq, paymentType: selectedRow.paymentType, customerName: selectedRow.customerName, siteName: selectedRow.siteName,
                billingAddressSeq: selectedRow.billingAddressSeq, orgChargeDate: selectedRow.orgChargeDate, chargeDate: selectedRow.chargeDate, dueDate: selectedRow.dueDate, billingPeriod: selectedRow.billingPeriod, orgChargeAmount: '', chargeAmount: '', chargeVat: '', chargeTotalAmount: '', billNo: '',
                isReceiveComplete: '', receiveNo: '', receiveAmount: '', notReceiveAmount: '', remark: '', dueDateType: selectedRow.dueDateType, dueDateDay: selectedRow.dueDateDay, isCreated: true, addRowId: addIndex
            }
    
            BillingListStore.setBillingList([...BillingListStore.billingList, addRowInfo]);
    
            setSelectContractNo('')
            setSelectAssetNo('');
        }else {
            if(selectedRow){
                setSelectContractNo(selectedRow.contractNo)
                setSelectAssetNo(selectedRow.assetNo);
            }

            AppStore.toggleContractAssetSearchModal();
        }
    }

    /* 계약자산 조회 콜백 함수 */
    const callbackContractAssetInfo = (data) => {
        const addIndex = BillingListStore.billingList.filter(v => v.isCreated).length || 0; 
        
        const addRowInfo = {
            billingNo: '', contractNo: data.contractNo, assetNo: data.assetNo, managementNo: data.managementNo, billingType: '', billingSeq: data.billingSeq, paymentType: data.paymentType, customerName: data.customerName, siteName: data.siteName,
            billingAddressSeq: data.billingAddressSeq, orgChargeDate: data.orgChargeDate, chargeDate: data.chargeDate, dueDate: data.dueDate, billingPeriod: data.billingPeriod, orgChargeAmount: '', chargeAmount: '', chargeVat: '', chargeTotalAmount: '', billNo: '',
            isReceiveComplete: '', receiveNo: '', receiveAmount: '', notReceiveAmount: '', remark: '', dueDateType: data.dueDateType, dueDateDay: data.dueDateDay, isCreated: true, addRowId: addIndex
        }

        BillingListStore.setBillingList([...BillingListStore.billingList, addRowInfo]);

        setSelectContractNo('')
        setSelectAssetNo('');
    }

    /* 저장버튼 클릭 이벤트 */
    const callBackGridData = async (gridData) => {
        // 선택된 행 중에서 계산서 발행된 행이 있다면 수정 불가
        if(BillingListStore.billingList.some(v => v.billNo)) {
            return showToast('계산서 발행된 청구는 수정할 수 없습니다.');
        }
        
        // 필수값 담기
        // const requiredParams = ['billingType', 'billingSeq', 'chargeDate', 'chargeAmount', 'chargeVat'];
        const requiredParams = ['billingType', 'billingSeq', 'chargeDate'];

        // 저장 확인
        if (!await callConfirm('저장 하시겠습니까?')) return;

        // 수정
        if (gridData.updatedList.length > 0) {
            if (gridData.updatedList.some(item => !requiredParams.every(param => item[param]))) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            }

            if(gridData.updatedList.some(item => !item.remark)){
                showToast('청구 수정시 비고를 반드시 입력해주세요.');
                return;
            }
            await axios.put('billing', { billingList: gridData.updatedList });
        }

        // 등록
        if (gridData.createdList.length > 0) {
            if (gridData.createdList.some(item => !requiredParams.every(param => item[param]))) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            }
            await axios.post('billing', { billingList: gridData.createdList });
        }
        showToast('저장되었습니다.');
        await getContractList();
    }

    /* 삭제 버튼 */
    const customCancelBtn = {
        isUsed: true,
        callbackFn: () => selectedGridCancel(),
        icon: ICON_TRASH,
        title: '청구삭제',
        width: 100,
        style: { backgroundColor: 'red' }
    }

    /* 청구 선택 삭제 callback */
    const selectedGridCancel = async () => {
        if(BillingListStore.billingList.some(v => v.isSelected && v.billComplete === 'Y')){
            showToast('계산서가 발행된 청구건은 삭제가 불가능합니다.');
            return;
        }

        let params = { billingNoList: [] };     // billingNo list 보내기
        BillingListStore.billingList.forEach(v => {
            if (v.isSelected === true) params.billingNoList.push(v.billingNo);
        });

        if(params.billingNoList.length === 0){
            showToast('선택된 청구건이 없습니다.');
            return;
        }
        
        // 삭제 확인
        if (!await callConfirm('선택된 청구를 삭제 하시겠습니까?')) return;

        await axios.put('billing/delete', params);
        await getContractList();

        showToast('삭제되었습니다.');
    }

    /* 청구 이월 버튼 */
    const customCarryoverBtn = {
        isUsed: true,
        callbackFn: () => openCarryoverChargePopup(),
        icon: 'fi-rr-redo',
        title: '청구이월',
        width: 100,
    }

    const openCarryoverChargePopup = () => {
        const selectedList = BillingListStore.billingChargeList.filter(v => v.isSelected);
        const isNotIssued = selectedList.every(v => !v.billNo);
        if(isNotIssued) {
            AppStore.toggleCarryoverChargePopup();
        }else {
            showToast('계산서가 발행되지 않은 행만 청구 이월이 가능합니다.');
        }
    }

    const carryoverCharge = async(data) => {
        const selectedList = BillingListStore.billingChargeList.filter(v => v.isSelected);
        await axios.put('/billing/carryover', {billingList: selectedList, carryoverMonth: data});
        showToast('이월했습니다.');
        getContractList();
    }

    /* billing_history 청구 수정 내역 조회 */
    const getBillingHistory = async (e) => {
        const result = await axios.get("/billing/history", { params: { billingNo: e.data.billingNo } });
        BillingListStore.setBillingHistoryList(result);

        if (result.length === 0) {
            showToast('조회된 목록이 없습니다.');
        }
    }

    const pinnedTopBillingData = [{
        orgChargeAmount: BillingListStore.billingList.reduce((total, v) => total + v.orgChargeAmount, 0),
        chargeAmount: BillingListStore.billingList.reduce((total, v) => Number(total) + Number(v.chargeAmount), 0),
        chargeVat: BillingListStore.billingList.reduce((total, v) => total + v.chargeVat, 0),
        chargeTotalAmount: BillingListStore.billingList.reduce((total, v) => total + v.chargeTotalAmount, 0),
    }];


    const pinnedTopBillingChargeData = [{
        orgChargeAmount: BillingListStore.billingChargeList.reduce((total, v) => total + v.orgChargeAmount, 0),
        orgChargeVat: BillingListStore.billingChargeList.reduce((total, v) => total + v.orgChargeVat, 0),
        orgChargeTotalAmount: BillingListStore.billingChargeList.reduce((total, v) => total + v.orgChargeTotalAmount, 0),
        chargeAmount: BillingListStore.billingChargeList.reduce((total, v) => total + v.chargeAmount, 0),
        chargeVat: BillingListStore.billingChargeList.reduce((total, v) => total + v.chargeVat, 0),
        chargeTotalAmount: BillingListStore.billingChargeList.reduce((total, v) => total + v.chargeTotalAmount, 0),
        plusAmount: BillingListStore.billingChargeList.reduce((total, v) => total + v.plusAmount, 0),
        minusAmount: BillingListStore.billingChargeList.reduce((total, v) => total + v.minusAmount, 0),
        receiveAmount: BillingListStore.billingChargeList.reduce((total, v) => total + v.receiveAmount, 0),
        notReceiveAmount: BillingListStore.billingChargeList.reduce((total, v) => total + v.notReceiveAmount, 0),
        isReceiveComplete: 'topRow',
    }];

    return (
        <>
            {/* 검색조건 */}
            <BillingListSearch selView={selView} setSelView={setSelView} getContractList={getContractList} />

            <CContainer>
                <Row>
                    {selView === 'asset' ? (
                        <>
                            <Row>
                                <AgGridContainer
                                    gridRef={gridRef}
                                    // height={55}
                                    height={60}
                                    rowData={BillingListStore.billingList}
                                    gridTitle={'자산별 청구 스케줄 조회'}
                                    rowBuffer={50}
                                    columnDefs={[
                                        { field: "billingNo", headerName: "청구번호", hide: true},
                                        { field: "contractNo", headerName: "계약번호", width: 160 },
                                        { field: "assetNo", headerName: "자산번호", width: 110, editable: (params) => { return params.node.data.isCreated === true; } },
                                        { field: "managementNo", headerName: "관리번호", width: 120 },
                                        { field: "model", headerName: "모델명", width: 140 },
                                        {
                                            field: "billingType",
                                            headerName: "청구구분",
                                            headerClass: 'grid-column-required',
                                            valueFormatter: (params) => findCommKrnm(params.value, '23'),
                                            cellEditor: 'agSelectCellEditor',
                                            cellEditorParams: {
                                                values: getCommcode('23').map(v => v.value),
                                            },
                                            width: 110,
                                            editable: (params) => { return params.node.data.isCreated === true; }
                                        },
                                        { field: "billingSeq", headerName: "회차", width: 80, headerClass: 'grid-column-editable', editable: (params) => { return params.node.data.isCreated === true; } },
                                        {
                                            field: "paymentType",
                                            headerName: "결제방식",
                                            headerClass: 'grid-column-editable',
                                            valueFormatter: (params) => findCommKrnm(params.value, '17'),
                                            width: 150,
                                            cellEditor: 'agSelectCellEditor',
                                            cellEditorParams: {
                                                values: getCommcode('17').map(v => v.value),
                                            },
                                            onCellValueChanged: onChangePayment,
                                            editable: (params) => { return params.node.data.isCreated === true; }
                                        },
                                        { field: "customerName", headerName: "고객명", width: 160 },
                                        { field: "siteName", headerName: "현장명", width: 160 },
                                        { field: "billingAddressSeq", headerName: "청구지번호", width: 120 },
                                        { field: "orgChargeDate", headerName: "원청구일", width: 120 },
                                        { field: "chargeDate", headerName: "청구일", headerClass: 'grid-column-required', width: 120, onCellValueChanged: handleCellChargeDateChanged, editable: (params) => { return params.node.data.billComplete !== 'Y'} },
                                        { field: "dueDate", headerName: "납기일", width: 120 },
                                        { field: "billingPeriod", headerName: "청구기간", width: 220 },
                                        { field: "orgChargeAmount", headerName: "원청구금액", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 130 },
                                        { 
                                            field: "chargeAmount", 
                                            headerName: "청구금액", 
                                            headerClass: 'grid-column-required', 
                                            onCellValueChanged: handleCellChargeChanged, 
                                            editable: (params) => { return params.node.data.billComplete !== 'Y'},
                                            valueFormatter: v => v.value?.toLocaleString(),
                                            cellClass: 'ag-grid-money-align', 
                                            width: 130 },
                                        {
                                            field: "chargeVat",
                                            headerName: "청구부가세",
                                            headerClass: 'grid-column-required',
                                            editable: (params) => { return params.node.data.billComplete !== 'Y'},
                                            valueFormatter: v => v.value?.toLocaleString(),
                                            cellClass: 'ag-grid-money-align',
                                            onCellValueChanged: onCellValueVatChanged,
                                            width: 120
                                        },
                                        {
                                            field: "chargeTotalAmount",
                                            headerName: "청구총액",
                                            valueGetter: v => {
                                                v.data.chargeTotalAmount = Number(v.data.chargeAmount) + Number(v.data.chargeVat);
                                                return v.data.chargeTotalAmount.toLocaleString()
                                            },
                                            cellClass: 'ag-grid-money-align',
                                            width: 130
                                        },
                                        { field: "remark", headerName: "청구비고", headerClass: 'grid-column-required', editable: true, width: 250 },
                                        { field: "billComplete", headerName: "계산서발행여부", width: 140 },
                                        { field: "creatorName", headerName: "등록자" },
                                        { field: "createdDate", headerName: "등록일자", width: 180 },
                                        { field: "updaterName", headerName: "변경자" },
                                        { field: "updatedDate", headerName: "변경일자", width: 180 },
                                    ]}
                                    isCheckBox={true}
                                    seqColumn={'billingNo'}
                                    originList={billingList}
                                    useUpdated={true}
                                    useIntoTheTab={true}
                                    callBackGridData={callBackGridData}
                                    customBtnInfo={[customCancelBtn, customAddBtn]}
                                    useCsvDownload={true}
                                    rowSearchCallback={v => getBillingHistory(v)}
                                    useRowSearch={true}
                                    getRowStyle={(params) => {
                                        if (params.data.billNo) {
                                            return { pointerEvents: 'none' };
                                        }
                                        if (params.data.isDel === 1) {
                                            return { pointerEvents: 'none', color: '#A6A6A6' };
                                        }
                                        return null;
                                    }}
                                    pinnedTopRowData={pinnedTopBillingData}
                                />
                            </Row>
                            <Row style={{ marginTop: 15 }}>
                                {/* 청구 수정 내역 조회 */}
                                <BillingHistoryList />
                            </Row>
                        </>
                    ) : (
                        <AgGridContainer
                            gridRef={gridRef}
                            height={60}
                            rowData={BillingListStore.billingChargeList}
                            gridTitle={'계약별 청구 조회'}
                            columnDefs={[
                                { field: "billingMonth", headerName: "청구년월", width: 110},
                                { field: "contractNo", headerName: "계약번호", width: 160 },
                                {
                                    field: "billingType",
                                    headerName: "청구구분",
                                    valueFormatter: (params) => findCommKrnm(params.value, '23'),
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values: getCommcode('23').map(v => v.value),
                                    },
                                    width: 110,
                                    editable: false
                                },
                                {
                                    field: "paymentType",
                                    headerName: "결제방식",
                                    valueFormatter: (params) => findCommKrnm(params.value, '17'),
                                    width: 130,
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values: getCommcode('17').map(v => v.value),
                                    },
                                    editable: false
                                },
                                { field: "customerName", headerName: "고객명", width: 130 },
                                { field: "siteName", headerName: "현장명", width: 160 },
                                { field: "billingAddressSeq", headerName: "청구지번호", width: 120 },
                                // {field: "chargeDate", headerName: "청구일", minWidth: 130, valueGetter: v => yearMonthFormat(v.value), editable: true},
                                { field: "dueDate", headerName: "납기일", width: 130 },
                                { field: "orgChargeAmount", headerName: "청구금액", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 120 },
                                { field: "orgChargeVat", headerName: "청구부가세", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 130 },
                                { field: "orgChargeTotalAmount", headerName: "청구총액", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 130 },
                                { field: "chargeAmount", headerName: "계산서발행금액", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 140 },
                                { field: "chargeVat", headerName: "계산서발행부가세", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 150 },
                                { field: "chargeTotalAmount", headerName: "계산서발행총액", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 140 },
                                { field: "plusAmount", headerName: "과청구", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 110 },
                                { field: "minusAmount", headerName: "미청구", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 110 },
                                { field: "billNo", headerName: "계산서번호", width: 160 },
                                { field: "billCount", headerName: "계산서발행수", width: 140 },
                                { field: "publishedCompany", headerName: "계산서발행 공급사", width: 180 },
                                {
                                    field: "isReceiveComplete",
                                    headerName: "수납완료",
                                    valueFormatter: v => v.data.receiveAmount != null ? v.data.chargeTotalAmount === v.data.receiveAmount ? '완료' : '미완료' : '미완료',
                                    cellClass: v => v.data.receiveAmount != null ? v.data.chargeTotalAmount === v.data.receiveAmount ? 'ag-grid-column-complete' : '' : '',
                                    width: 110,
                                },
                                { field: "receiveAmount", headerName: "수납금액", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 120 },
                                { field: "notReceiveAmount", headerName: "미수납액", valueFormatter: v => v.data.chargeAmount ? (Number(v.data.chargeAmount) + Number(v.data.chargeVat) - Number(v.data.receiveAmount)).toLocaleString() : '', cellClass: 'ag-grid-money-align', width: 120 },
                                { field: "creatorName", headerName: "등록자" },
                                { field: "createdDate", headerName: "등록일자", minWidth: 180 },
                                { field: "updaterName", headerName: "변경자" },
                                { field: "updatedDate", headerName: "변경일자", minWidth: 180 },
                            ]}
                            isCheckBox={true}
                            seqColumn={'billingChargeNo'}
                            useCsvDownload={true}
                            callBackGridData={callBackGridData}
                            pinnedTopRowData={pinnedTopBillingChargeData}
                            customBtnInfo={[customCarryoverBtn]}
                        />
                    )}
                </Row>
            </CContainer>
            <ContractAssetSearch selectContractNo={selectContractNo} selectAssetNo={selectAssetNo} callbackFn={callbackContractAssetInfo} />
            <CarryoverChargePopup callbackFn={carryoverCharge} />
        </>
    );
}

export default observer(BillingList);
