 import { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import {CContainer} from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import {HireAssetSearchStore} from '../../store/asset/HireAssetSearchStore';
import { AppStore } from "../../store/AppStore";
import AssetDetail from './AssetDetail';
import Modal from '../../components/Modal';
import { showToast } from '../../common/utils';
import { callConfirm } from '../../utils';
import HireAssetManagerSearch from './HireAssetManagerSearch';
import ReleaseAskPopup from './popup/ReleaseAskPopup';

const HireAssetManagerList = () => {
    const gridRef = useRef();
    const [assetNo, setAssetNo] = useState('');

    const [buyCustomerNo, setBuyCustomerNo] = useState('')
    const [returnAssetList, setReturnAssetList] = useState([]);

    useEffect(() => {
    }, [])

    const getAssetList = async () => {
        const result = await axios.get("/asset/hire/list", { params: HireAssetSearchStore.condition });
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
        }

        HireAssetSearchStore.setAssetList(result);
    }

    const columnDefs = [
        {field: "buyCustomerNo", headerName: "임대회사고객번호", width: 180, hide: true},
        {field: "buyCustomerName", headerName: "임대사", width: 180},
        {field: "assetNo", headerName: "자산번호", minWidth: 110, hide: true},
        {field: "managementNo", headerName: "관리번호", minWidth: 130},
        {field: "assetStatus", headerName: "자산상태", width: 120, hide: true},
        {field: "conditionStatus", headerName: "자산컨디션", width: 120, hide: true},
        {field: "assetStatusName", headerName: "자산상태", width: 120},
        {field: "model", headerName: "모델명", minWidth: 140},
        {field: "serialNo", headerName: "시리얼번호", minWidth: 160},
        {field: "acquDate", headerName: "당사 입고일", minWidth: 140},
        {field: "acquAmount", headerName: "월 임차료", width: 150, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
        {field: "totalPayPrice", headerName: "총 예상 지급 임차료(월임차료x개월)", width: 260, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
        {field: "chargeAmount", headerName: "매출총액", valueFormatter: v => v.value?.toLocaleString(), width: 160, cellClass: 'ag-grid-money-align'},
        {
            field: "marginAmount", 
            headerName: "마진", 
            minWidth: 130, 
            valueFormatter: v => v.value?.toLocaleString(), 
            cellClass: v => v.value > 0 ? 'ag-grid-money-align ag-grid-column-complete' : 'ag-grid-money-align ag-grid-column-cancel'
        },
        {
            field: "marginRate", 
            headerName: "마진율(%)", 
            minWidth: 130, 
            cellClass: v => v.value > 0 ? 'ag-grid-money-align ag-grid-column-complete' : 'ag-grid-money-align ag-grid-column-cancel'
        },
        {field: "siteName", headerName: "최근 현장", width:150},
        {field: "lastReleaseDate", headerName: "최근 출고일", minWidth: 130},
        {field: "contractNo", headerName: "최근 계약번호", minWidth: 160},
        {field: "contractCustomerName", headerName: "최근 고객명", width: 180},
        {field: "holdingDays", headerName: "총 보유일수", width:130},
        {field: "nonRelaseDays", headerName: "미출고일수", minWidth: 140, cellClass: 'ag-grid-column-cancel'},
    ];

    /* 임차자산 출고 버튼 */
    const releaseReturnOnLeaseBtn = {
        isUsed: true,
        callbackFn:() => releaseReturnOnLease(),
        icon: 'fi-rr-undo',
        title: '임차자산 반납',
        width: 140,
        variant: 'danger'
    }

    /* 임차자산 반납출고 버튼 callback */
    const releaseReturnOnLease = async () => {
        const selectedList = HireAssetSearchStore.assetList.filter(v => v.isSelected);
        const buyCustomerName = selectedList[0].buyCustomerName;
        const filteredLeaseableList = selectedList.filter(v => v.isSelected && v.conditionStatus !== '4');
        const filteredRentCompanyList = selectedList.filter(v => v.isSelected && v.buyCustomerName === buyCustomerName);

        // 선택한 데이터 유, 무 확인
        if(selectedList.length < 1) return showToast('자산을 선택해 주세요.');
        // 선택한 데이터 모두 임대가능이거나 모두 임대중인지 확인 + 출고완료
        if(selectedList.length !== filteredLeaseableList.length) return showToast('임대불가 자산(이미 반납된 자산)이 포함되어 있습니다.');
        if(selectedList.length !== filteredRentCompanyList.length) return showToast('임대처 같아야 합니다.');
        
        setBuyCustomerNo(selectedList[0].buyCustomerNo ? selectedList[0].buyCustomerNo : '');
        //반납해야할 자산 리스트 생성
        setReturnAssetList(selectedList.map(v => {return {assetNo: v.assetNo, model: v.model, qty: 1, managementNo: v.managementNo, serialNo: v.serialNo, assetStatus: v.assetStatus}}));
        // 계약번호로 청구지 순번, 회수의뢰 조회
        AppStore.toggleReleaseAskPopup();
    }

    /* 자산 상세 */
    const openAssetDetail = (v) => {
        setAssetNo(v.data.assetNo);
        AppStore.toggleModal();
    }

    /* 팝업 닫을 때 자산상세 초기화 */
    const closedModal = () => {
        setAssetNo('');
        setBuyCustomerNo('');
        setReturnAssetList([]);
    };

    const ReleaseAskCallBack =()=> {
        getAssetList();
        closedModal();
    }

    /* 행 고정 */
    const pinnedTopRowData = [{
        acquAmount: HireAssetSearchStore.assetList.reduce((total, v) => total + v.acquAmount, 0),
        totalPayPrice: HireAssetSearchStore.assetList.reduce((total, v) => total + v.totalPayPrice, 0),
        chargeAmount: HireAssetSearchStore.assetList.reduce((total, v) => total + v.chargeAmount, 0),
        marginAmount: HireAssetSearchStore.assetList.reduce((total, v) => total + v.marginAmount, 0),
        marginRate: Math.floor(HireAssetSearchStore.assetList.reduce((total, v) => total + v.marginRate, 0) / HireAssetSearchStore.assetList.filter(v => v.marginRate).length)
    }];

    return (
        <>
            {/* 검색조건 */}
            <HireAssetManagerSearch getAssetList={getAssetList}/>
            <CContainer>
                {/* grid */}
                <AgGridContainer
                    gridTitle={'임차 자산 목록'}
                    gridRef={gridRef}
                    height={60}
                    rowData={HireAssetSearchStore.assetList}
                    columnDefs={columnDefs}
                    seqColumn={'assetNo'}
                    useCsvDownload={true}
                    pinnedTopRowData={pinnedTopRowData}
                    rowDoubleClickCallback={openAssetDetail}
                    isCheckBox={true}
                    customBtnInfo={[releaseReturnOnLeaseBtn]}
                />
            </CContainer>
            <Modal title={'자산상세'} onExit={closedModal}>
                    <AssetDetail assetNo={assetNo} />
            </Modal>
            <ReleaseAskPopup callBackFn={ReleaseAskCallBack} customerNo={buyCustomerNo} assetList={returnAssetList} />
        </>
    );
};

export default observer(HireAssetManagerList);
