import React,{ useRef, useState, useEffect } from "react";
import {observer} from "mobx-react-lite";
import axios from 'axios';

import {CContainer} from "../../components/CustomContainer";
import {EstimateListStore} from "../../store/contract/EstimateListStore";
import {EstimateDetailStore} from "../../store/contract/EstimateDetailStore";
import Modal from "../../components/Modal";
import EstimateDetail from "./EstimateDetail";
import {AppStore} from "../../store/AppStore";
import { anyMonthAgo, today, dateFormat } from '../../utils/dateUtils';
import GridEstimateList from "./grid/GridEstimateList";
import EstimateSearchComponent from "./EstimateSearch";
import {getUserName} from "../../utils/userUtils";

const Estimate =()=> {
    const gridRef = useRef();
    const [estimateNo, setEstimateNo] = useState('');

    useEffect(() => {
        // 한 달 전 날짜 세팅
        const initSearchCondition = {
            startDate: anyMonthAgo(today(), 3),
            endDate: today(),
        }
        EstimateListStore.setEstimateSearchCondition(initSearchCondition);
    }, []);

    /* 견적 조회 */
    const getEstimateList = async()=> {
        const result = await axios.get('/estimate/list', { params: EstimateListStore.estimateSearchCondition });
        EstimateListStore.setEstimateList(result);
    };

    /* 견적 상세 */
    const openEstimateDetail = (v) => {
        setEstimateNo(v.data.estimateNo);
        AppStore.toggleModal();
    }

    const closedModal = () => {
        EstimateDetailStore.init();
    };

    return(
        <>
            {/* 검색조건 */}
            <EstimateSearchComponent getEstimateList={getEstimateList}
                                     setEstimateNo={setEstimateNo}/>
            <CContainer>
                {/* grid */}
                <GridEstimateList
                    gridTitle={'견적 목록'}
                    gridRef={gridRef}
                    height={60}
                    rowData={EstimateListStore.estimateList}
                    columnDefs={
                        [
                            {field: "companyName", headerName: "회사", width: 150},
                            {
                                field: "status",
                                headerName: "상태",
                                width: 80,
                                valueFormatter: v => v.value === 2 ? '완료' : v.value === 9 ? '취소' : '진행중',
                                cellClass: v => v.value === 2 ? 'ag-grid-column-complete' : v.value === 9 ? 'ag-grid-column-cancel' : '',
                            },
                            {field: "estimateNo", headerName: "견적번호", minWidth: 110},
                            {field: "estimateDate", headerName: "견적일자", valueGetter: v => dateFormat(v.data.estimateDate), minWidth: 120},
                            {field: "creator", headerName: "작성자", valueGetter: v => getUserName(v.data.creator)},
                            {field: "customerName", headerName: "고객명", minWidth: 180},
                            {field: "productName", headerName: "견적품목", minWidth: 250},
                            {field: "productQty", headerName: "수량"},
                            {field: "rentMonth", headerName: "대여월"},
                            {field: "rentDays", headerName: "대여일"},
                            {field: "contractStatus", headerName: "계약여부"},
                            {field: "remark", headerName: "비고", minWidth: 300},
                        ]
                    }
                    seqColumn={'estimateNo'}
                    useCsvDownload={true}
                    useRowSearch={true}
                    rowSearchCallback={openEstimateDetail}
                    rowDoubleClickCallback={openEstimateDetail}
                />
                {/* <AgGridContainer
                    gridTitle={'견적 목록'}
                    gridRef={gridRef}
                    height={60}
                    rowData={EstimateListStore.estimateList}
                    columnDefs={
                        [
                            {
                                field: "status",
                                headerName: "상태",
                                width: 70,
                                valueFormatter: v => v.value === 1 ? '완료' : '작성중',
                            },
                            {field: "estimateNo", headerName: "견적번호", minWidth: 110},
                            {field: "estimateDate", headerName: "견적일자", valueGetter: v => dateFormat(v.data.estimateDate), minWidth: 120},
                            {field: "customerName", headerName: "고객명", minWidth: 180},
                            {field: "productName", headerName: "견적품목", minWidth: 300},
                            {field: "productQty", headerName: "수량"},
                            {field: "rentMonth", headerName: "대여월"},
                            {field: "rentDays", headerName: "대여일"},
                            {field: "isContract", headerName: "계약여부"},
                            {field: "remark", headerName: "비고", minWidth: 150, flex: 1},
                        ]
                    }
                    getChangeList={[]}
                    seqColumn={'estimateNo'}
                    useCsvDownload={true}
                    useRowSearch={true}
                    rowSearchCallback={openEstimateDetail}
                    rowDoubleClickCallback={openEstimateDetail}
                /> */}
            </CContainer>
            <Modal title={'견적상세'} onExit={closedModal}>
                <EstimateDetail estimateNo={estimateNo} getEstimateList={getEstimateList}/>
            </Modal>
        </>
    );
}

export default observer(Estimate);
