import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";
import { today } from "../../utils/dateUtils";

export const EstimateDetailStore = observable({
    estimateDetail: {
            estimateNo: '',         // 견적번호
            contractType: '1',      // 계약구분
            customerNo: '',         // 고객번호
            customerName: '',       // 고객명
            saleUserSeq: '',        // 영업담당자 seq
            saleUserName: '',       // 영업담당자명
            rentMonth: '',          // 계약 월
            rentDays: '',           // 계약 일
            contractDate: today(),  // 계약일자
            contractStartDate: '',  // 계약시작일
            contractEndDate: '',    // 계약종료일
            isPenaltyFee: '0',      // 위약금 청구여부
            penaltyPercent: '40',   // 위약금율
            isDeliveryPrice: '0',   // 운반비 여부
            deliveryPrice: '0',     // 운반비
            isInstallPrice: '0',    // 설치비 여부
            installPrice: '0',      // 설치비
            specialRemark: '',      // 특이사항
            billingAddressSeq: '',  // 청구지순번
            billingName: '',        // 청구지명
            customerManagerSeq: '', // 고객 담당자 시퀀스
            managerName: '',        // 고객 담당자명
            billingTelephone: '',   // 청구지 전화번호
            billingPhoneNumber: '', // 청구지 핸드폰번호
            billingEmail: '',       // 청구지 이메일
            billingType: '1',       // 청구조건
            billingDay: '0',        // 청구일자
            dueDateType: '2',       // 납기조건
            dueDateDay: '0',        // 납기일자
            paymentType: '1',       // 결제방식(현금,카드,어음)
            deposit: '',            // 보증금
            isFreeAssignment: '',   // 무상양도여부
            assignmentAmount: '',   // 양도금액
            isRepairCost: '',       // 수리비 청구여부
            status: 0,              // 견적상태(0:작성중,1:완료)
            creator: '',            // 견적작성자
            createdDate: '',        // 견적작성일
            companyAddress: '',     // 담당자 회사 주소
            companyCode: '',        // 담당자 회사 코드
            companyName: '',        // 담당자 회사명
            department: '',         // 담당자 부서
            telephone: '',          // 담당자 전화번호
            phoneNumber: '',        // 담당자 핸드폰번호
            email: '',              // 담당자 이메일
            remark: '',             // 비고
            signatureImageUrl: '',  // 서명
            logoImageUrl: '',       // 로고
    },
    estimateProductList: [],
    seq: 0,
    subject: '',
    content: '',
    receiverEmail: '',

    setEstimateDetail(v) {
        LUtils.assign(this.estimateDetail, v);
    },
    setEstimateProductList(v) {
        this.estimateProductList = v;
    },
    setSeq(v) {
        this.seq = v;
    },

    init() {
        this.estimateDetail= {
            estimateNo: '', contractType: '1', customerNo: '', customerName: '', saleUserSeq: '', saleUserName: '', rentMonth: '', rentDays: '',
            contractDate: '', contractStartDate: '', contractEndDate: '', isPenaltyFee: '0', penaltyPercent: '', isDeliveryPrice: '0', deliveryPrice: '', isInstallPrice: '0', installPrice: '',
            specialRemark: '', billingAddressSeq: '', billingName: '', customerManagerSeq: '', managerName: '', billingTelephone: '', billingPhoneNumber: '', billingEmail: '', billingType: '1', billingDay: '0',
            dueDateType: '2', dueDateDay: '0', paymentType: '1', deposit: '', isFreeAssignment: '', status: 0, createdDate: '', companyAddress: '', companyCode: '',
            department: '', telephone: '', phoneNumber: '', email: '', remark: '', signatureImageUrl: '', logoImageUrl: '', 
        },
        this.estimateProductList= [],
        this.seq = 0,
        this.subject = '', this.content = '', this.receiverEmail = '', this.companyName = ''
    },
    clear() {
        this.estimateDetail= {
            estimateNo: this.estimateDetail.estimateNo, contractType: '1', customerNo: '', customerName: '', rentMonth: '',
            rentDays: '', contractStartDate: '', contractEndDate: '', isPenaltyFee: '0', isDeliveryPrice: '0', deliveryPrice: '', isInstallPrice: '0', installPrice: '', penaltyPercent: '',
            specialRemark: '', billingAddressSeq: '', billingName: '', customerManagerSeq: '', managerName: '', billingTelephone: '', billingPhoneNumber: '', billingEmail: '', billingType: '1', billingDay: '0',
            dueDateType: '2', dueDateDay: '0', paymentType: '1', deposit: '', isFreeAssignment: '', status: 0, createdDate: '', companyAddress: '', companyCode: '',
            department: '', telephone: '', phoneNumber: '', email: '', remark: '', signatureImageUrl: '', logoImageUrl: '', 
        },
        this.estimateProductList= [],
        this.seq = 0,
        this.subject = '', this.content = '', this.receiverEmail = '', this.companyName = ''
    },
    sendPopupInit() {
        this.subject = '', this.content = '', this.receiverEmail = '', this.companyName = ''
    }
});
