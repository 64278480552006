import { observer } from "mobx-react-lite"
import { useRef, useState } from "react";
import { Row, Form } from "react-bootstrap";

import { CContainer, CInputGroup, CSaveBtn } from "../../../components/CustomContainer";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import { showToast } from "../../../common/utils";
import { callConfirm } from "../../../utils";
import axios from "axios";
import { MULTIPART_HEADER } from "../../../common/constants";


const UploadDcoument = ({ releaseAskSeq, getDocument }) => {
    const ref = useRef();
    const [file, setFile] = useState([]);
    const [fileName, setFileName] = useState('');

    const refresh = () => {
        if (ref.current) {
            ref.current.value = '';
        }
        setFileName('');  
    }

    const uploadFile = async (fileData) => {
        const file = fileData.target.files[0];
        setFile(file);
    
        // 파일명에서 확장자 제거
        const fileNameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
        setFileName(fileNameWithoutExtension);
    }

    const saveDocument = async () => {
        if(!ref.current.value) return showToast('파일을 선택해주세요.');
        if(!fileName) return showToast('파일명을 입력해주세요.');

        if (!await callConfirm('업로드 하시겠습니까?')) {
            return false;
        }

        const mimeTypeToExtension = {
            'image/jpeg': 'jpg',
            'image/png': 'png',
            'image/gif': 'gif',
            'image/bmp': 'bmp',
            'image/webp': 'webp',
            'image/tiff': 'tiff',
            'image/svg+xml': 'svg',
            'application/pdf': 'pdf',
            'application/msword': 'doc',                
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
            'application/vnd.ms-excel': 'xls',         
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx', 
            'text/csv': 'csv',                      
            'application/vnd.ms-powerpoint': 'ppt',   
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx', 
            'application/zip': 'zip',                
            'application/x-rar-compressed': 'rar',    
            'application/vnd.hancom.hwp': 'hwp',       
            'application/x-7z-compressed': '7z',     
            'application/x-tar': 'tar',         
            'application/x-gzip': 'gz',         
            'application/x-bzip2': 'bz2',       
            'application/x-bzip': 'bz',         
            'text/plain': 'txt',                
            'application/json': 'json',            
            'application/javascript': 'js',       
            'application/xml': 'xml',         
            'application/x-httpd-php': 'php',     
            'text/html': 'html',               
            'application/rtf': 'rtf',                   
        };
    
        const extension = mimeTypeToExtension[file.type] || 'bin';

        const blob = new Blob([file], { type: file.type });
        const formData = new FormData();
        formData.append('file', new Blob([blob]), file.name);
        formData.append('releaseAskSeq', releaseAskSeq);
        formData.append('fileName', fileName);
        formData.append('fileType', extension);

        await axios.post('/releaseAsk/document', formData, {headers: MULTIPART_HEADER});
        showToast('업로드 했습니다.');
        getDocument(releaseAskSeq);
        refresh();
    }

    return (
        <>
            <Modal title={'문서 업로드'}
                onHide={() => AppStore.toggleUploadDcoumentPopup()}
                toggleValue={AppStore.isOpenUploadDocumentPopup}
                onExit={refresh}
                size={'sm'}
            >
                <CContainer>
                    <Row>
                        <Form.Control
                            type="file"
                            id="profileImg"
                            size="sm"
                            placeholder={'문서 업로드'}
                            onChange={uploadFile}
                            ref={ref}
                            style={{ marginLeft: 10, marginBottom: 10, width: 235 }}
                        />
                        <CInputGroup label={'파일명'} labelId={'fileName'}
                                    placeholder={'파일명을 입력해주세요.'}
                                    value={fileName}
                                    onChange={v => setFileName(v.target.value)}
                                    isValid='input-required'
                        />
                    </Row>

                    <Row className='d-flex flex-row-reverse mt-4'>
                        <CSaveBtn style={{ width: 80 }}
                            title={'저장'}
                            onClick={saveDocument}
                        />
                    </Row>
                </CContainer>
            </Modal>
        </>
    );
}

export default observer(UploadDcoument);
