import { Outlet } from "react-router-dom";
import GuestGuard from "../utils/route-guard/GuestGuard";
import BillLinkIndex from "../views/billing/link/BillLinkIndex";
import BillTemplate from "../views/billing/link/BillTemplate";

const TaxBillRoutes = {
    path: '/',
    element: (
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        ),
    children: [
        {path: '/taxBill/:billNo/index', element: <BillLinkIndex />},
        {path: '/taxBill/:billNo', element: <BillTemplate />},
    ]
}

export default TaxBillRoutes;
