import axios from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { decHex, decrypt, encHex } from "../../common/utils";
import { useNavigate, useSearchParams } from "react-router-dom";

const RedirectPage = () => {
    const [url, setUrl] = useState('');
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const fetchData = async () => {
            const searchParams = new URLSearchParams(location.search);
            let encId = searchParams.get("id");

            if (encId != null) {
                await getRedirectUrl(encId);
            }

            // await navigationRedirectUrl(url);
        }

        fetchData();
    }, [])

    const getRedirectUrl = async(encId) => {
        // url 받아오기
        const redirectUrl = await axios.get("redirect", {params: {encId: encId}});
        await navigationRedirectUrl(redirectUrl);
    }

    const navigationRedirectUrl = async (redirectUrl) => {
        navigate("/" + redirectUrl, {replace: true});
    }

    return (
        <>
        </>
    )
}

export default observer(RedirectPage);
 