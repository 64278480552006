import React, {useEffect, useState} from "react";
import { observer } from 'mobx-react-lite';
import {Container, Navbar, Dropdown, NavDropdown, Nav, Image, Badge, Button} from 'react-bootstrap';
import {AppStore} from "../../store/AppStore";
import {useLocation, useNavigate} from "react-router-dom";
import { getLocalStorage } from "../../utils";
import { BASE_URL, USER_INFO } from "../../common/constants";
import {useProSidebar} from "react-pro-sidebar";
import axios from "axios";
import NProgress from 'nprogress';
import { getTime } from "../../utils/dateUtils";

const Header = () => {
    const navigate = useNavigate();
    const [menuTitle, setMenuTitle] = useState('');
    const userInfo = getLocalStorage(USER_INFO);
    const [returnItemCount, setReturnItemCount] = useState('');
    const [badgeBgColor, setBadgeBgColor] = useState('#fff');
    const { collapseSidebar, collapsed } = useProSidebar();
    const [isNotPayPopup, setIsNotPayPopup] = useState(false);
    const [notPayAmount, setNotPayAmount] = useState(0);
    const [notPayDueDate, setNotPayDueDate] = useState('');
    const [notPayDays, setNotPayDays] = useState(0);

    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = menuTitle ? 'CAS - '+menuTitle : 'CAS';
    };

    useEffect(()=> {
        window.addEventListener('resize', screenResize);
        setLastOpenPageTitle();
    })

    useEffect(()=> {
        screenResize();
        //회수완료 미입고 장비 카운트 가져오기 (1분마다 실행)
        getReuntItemCount();
        setInterval(async()=> {
            getReuntItemCount();
        }, 60000);

        //렌트플랜 구독 연체료 검색
        getCompanyNotPayInfo();
    }, [])

    const setLastOpenPageTitle =()=>{
        if(AppStore.openPageHistory.length > 0){
            const menuName = AppStore.openPageHistory.filter(v => v.url === location.pathname).map(v => v.name);
            setMenuTitle(menuName[0]);
            updateTitle();
        }
    }

    const userLogout = async () => {
        await axios.put("/user/logout")
        .then(() => AppStore.resetUser()).then(() => window.location.href = '/login');
    }

    /* 헤더 프로필 드롭다운 커스텀 */
    const customDropdown = React.forwardRef(({ children, onClick }, ref) => (
            <a  style={{color: "black"}}
                href=""
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}>
                {children}
            </a>
    ));

    const screenResize =()=> {
        if(window.innerWidth <= 900){
            AppStore.setIsMobile(true);
            if(!AppStore.isOpenForSidebar){
                toggleSidebar();
            }
        }else if(window.innerWidth > 900) {
            AppStore.setIsMobile(false);
            if(AppStore.isOpenForSidebar){
                toggleSidebar();
            }
        }
    }

    /* sidebar toggle */
    const toggleSidebar = () => {
        collapseSidebar();
        AppStore.toggleSidebar();
    }

    const getReuntItemCount =async()=> {
        const nowTime = getTime().replaceAll(':', '');
        //9시 ~ 17시30분 까지만 체크한다.
        if(AppStore.jwt && nowTime > '090000' && nowTime < '180000'){
            const option = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + AppStore.jwt,
                }
            };
    
            fetch(`${BASE_URL}/api/v1/returnAskItem/complete/count`, option)
            .then((response) => response.json())  // fetch로 받은 data 객체를 JS 객체로 프라미스로 변환 [파싱]
            .then((data) => {
                if(returnItemCount !== data && data > 0){
                    setReturnItemCount(data);
                }
            }); 
        }
    }

    const badgeOverColor =(action)=> {
        //over
        if(action === 1){
            setBadgeBgColor('#f2f2f2');
        }else{
            setBadgeBgColor('#fff');
        }
    }

    const getCompanyNotPayInfo =async()=> {
        const data = await axios.get("/company/notPay");
        if(data.notPayAmount > 0){
            setNotPayAmount(data.notPayAmount);
            setNotPayDueDate(data.notPayDueDate);
            setNotPayDays(data.notPayDays);
            setIsNotPayPopup(true);
        }
    }

    return (
        <Navbar sticky={'top'} className='header-custom'>
            <Container fluid>
                <div className='d-flex align-items-center justify-content-center' style={{height: 70, width: collapsed ? 60 : 238, backgroundColor: '#fff', marginTop: 5, marginBottom: 10}}>
                    <Image style={{cursor:'pointer'}} src='/image/pelotonlab.png' width={collapsed ? 15 : 25} height={19} onClick={toggleSidebar}/>
                    <Image src='/image/rentplan.png' width={collapsed ? 40 : 70} style={{marginLeft: 10}}/>
                    {/* <span style={{marginLeft: 10, fontSize: collapsed ? 10 : 13,  fontWeight: '500'}}>Rent Plan</span> */}
                </div>
                <div className="me-auto" style={{marginLeft: 30}}>
                    {AppStore.openPageHistory.length > 0 &&
                        (!AppStore.isMobile ?
                                <ul className="nav nav-tabs" style={{position: 'absolute', bottom: 1, borderWidth: 0}}>
                                    {AppStore.openPageHistory.map((v, i)=> {
                                        return (
                                            <li className="nav-item" key={i}>
                                                <a className={`nav-link ${location.pathname === v.url && 'active'}`} style={{cursor: 'pointer', backgroundColor: location.pathname === v.url ? '#f8f9fc' : '#fff'}} onClick={()=> navigate(v.url)}>
                                                    {v.name}
                                                    <i onClick={async()=> {
                                                        AppStore.openPageHistory.splice(i,1);
                                                        if(i > 0){
                                                            const menu = await AppStore.openPageHistory.find((value, index) => index === (i-1));
                                                            navigate(menu.url);
                                                        }
                                                    }}
                                                       className="fi fi-rr-cross-small"
                                                       style={{marginLeft: 5}}
                                                    />
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                                :
                                <Nav>
                                    <NavDropdown title={menuTitle} id="basic-nav-dropdown" style={{fontSize: 18, color: 'rgb(0 0 0 / 79%)'}}>
                                        {AppStore.openPageHistory.map((v, i)=> {
                                            return (
                                                <NavDropdown.Item
                                                    key={i}
                                                    onClick={()=> navigate(v.url)}
                                                >{v.name}</NavDropdown.Item>
                                            )
                                        })}
                                    </NavDropdown>
                                </Nav>
                        )
                    }
                </div>
                <div className="header-badge" style={{backgroundColor: badgeBgColor}}
                    onMouseOver={()=> badgeOverColor(1)} onMouseLeave={()=> badgeOverColor(2)}>
                    <i className="fi-rr-garage position-relative" 
                        style={{fontSize: 20, color: '#585858'}} 
                        onClick={()=> {
                            AppStore.pushOpenPageHistory({url: '/storage/return', name: '반납입고'});
                            navigate('/storage/return')}
                        }
                    />
                    <Badge className="header-badge-alram" pill bg="danger">
                        {returnItemCount}
                    </Badge>
                </div>
                <div style={{borderWidth: 1, borderRadius: 6, paddingTop: 4, paddingBottom: 4, backgroundColor: 'rgb(250 250 250 / 80%)'}}>
                    <Dropdown>
                        <Dropdown.Toggle as={customDropdown} id="dropdown-custom-components">
                            <div className="d-inline-flex mx-4" style={{flexDirection: "row", alignItems: "center"}}>
                                <div>
                                    {userInfo.name} &nbsp;&nbsp;&nbsp;
                                </div>
                                <div style={{height: 38, width: 39, cursor: 'pointer', marginRight: 0, borderRadius: 20, boxShadow: '2px 2px 3px rgba(34, 51, 84, 0.2)'}}>
                                    {userInfo.imageUrl ?
                                        <Image src={userInfo.imageUrl} style={{height: 38, objectFit: "cover", borderRadius: "20px"}} alt="프로필 이미지"/>
                                        :
                                        <i className="fi fi-rr-user" style={{paddingLeft: 8, fontSize: 25}}/>
                                    }
                                </div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.ItemText eventkey="1">{userInfo.companyName}</Dropdown.ItemText>
                            <Dropdown.Item onClick={()=> navigate('/mypage')}>마이페이지</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={userLogout}>로그아웃</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {isNotPayPopup &&
                    <div style={{width: 400, height: 420, borderWidth: 1, backgroundColor: '#000', borderRadius: 7, position: 'absolute', top: 220, left: '44%'}}>
                        <div style={{padding: 20}}>
                            <p style={{color: '#fff'}}>안녕하세요 렌트플랜 입니다.</p>
                            <p style={{color: '#fff'}}>현재 렌트플랜 구독료가 연체되어 있습니다.</p>
                            <br/>
                            <p style={{color: '#fff'}}>🔴연체내역</p>
                            <p style={{color: '#fff'}}>☑️연체금액 : {notPayAmount} (부가세 포함)</p>
                            <p style={{color: '#fff'}}>☑️납기일 : {notPayDueDate}</p>
                            <p style={{color: '#fff'}}>☑️연체일 : {notPayDays}일째 연체중</p>
                            <p style={{color: '#fff'}}>☑️납부 계좌번호 : 943-021929-04-014 기업은행 (주)펠로톤랩</p>
                            <p style={{color: '#F5A9A9'}}>❗️연체일이 60일을 초과하면 계정이 정지됩니다.</p>
                            <br/>
                            <p style={{color: '#fff'}}>최상의 서비스로 보답드리겠습니다.<br/>감사합니다.🙏</p>
                        </div>
                        <div style={{height: 30, width: '100%', marginBottom: 10, backgroundColor: '#fff', borderWidth: 1, borderColor: '#000', paddingTop: 4, paddingRight: 12}}>
                            <p style={{textAlign: 'right', cursor: 'pointer'}} onClick={()=> setIsNotPayPopup(false)}>닫기</p>
                        </div>
                    </div>
                }
            </Container>
        </Navbar>
    );
};

export default observer(Header);
