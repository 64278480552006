import { useRef } from "react";
import { Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";

import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import {showToast} from "../../common/utils";
import { ICON_ADD, ICON_SAVE } from "../../common/constants";
import LUtils from "../../utils/lodashUtils";
import { OptionPopupStore } from "../../store/asset/OptionPopupStore";
import { callConfirm } from "../../utils";
import StockOptionSearch from "./StockOptionSearch";

const AddOptionPopup =({ callbackFn=null, apronSeq=null, selProductList, assetNo=null, getAssetOptionList, searchStorage })=> {
    const store = OptionPopupStore;
    const gridRef = useRef();

    const saveBtn = {
        isUsed: true,
        title: '옵션 결합',
        callbackFn: () => saveAssetOption(),
        icon: ICON_SAVE,
        variant: 'success'
    };

    const selectBtn = {
        isUsed: true,
        title: '옵션 선택',
        callbackFn: () => AppStore.toggleStockOptionSearchModal(),
        icon: ICON_ADD,
    };
    
    const saveAssetOption = async () => {
        if(!await callConfirm('옵션 결합하시겠습니까?')) return;

        if(assetNo) {
            await axios.post('/asset/option', {assetNo: assetNo, optionList: store.optionList});
        }else {
            await axios.post('/storage/option', {storageList: selProductList, optionList: store.optionList});
        }
        showToast('옵션 결합되었습니다.');
       
        if (typeof getAssetOptionList === 'function') {   
            getAssetOptionList();
        }
        if (typeof searchStorage === 'function') {   
            searchStorage();
        }
        AppStore.toggleAddOptionPopup()
    }

    /* 옵션 추가 콜백 함수 */
    const callbackOptionListSearch = (info) => {
        if (LUtils.isEmpty(info)) {
            return false;
        }

        if (apronSeq != null && !info.every(v => v.apronSeq === apronSeq)) {
            showToast('자산의 주기장과 같은 주기장의 옵션만 선택할 수 있습니다.');
            return;
        }

        let seq = store.optionList.length + 1;
        let newOptionList = [...store.optionList];

        for(let option of info) {
            const additionalRowInfo = {
                addRowId: seq,
                seq: seq,
                optionProductSeq: option.productSeq,
                optionProductName: option.productName,
                optionModel: option.model,
                optionProductCode: option.productCode,
                qty: 1,
                maxQty: option.stockQty,
                isSelected: true,
                isCreated: true,
                apronSeq: option.apronSeq
            };
            newOptionList.push(additionalRowInfo);
            seq++;
        }

        store.optionList = newOptionList;
    }

    const handleConsumQtyEdit = async(e)=> {
        if(e.data.maxQty < e.newValue) {
            showToast('입력 수량이 재고 수량보다 클 수 없습니다.');
            e.data.qty = e.oldValue;
        } else {
            e.data.qty = e.newValue;
        }
     
        gridRef.current.api.redrawRows();
    }

    /* 초기화 */
    const refresh = () => {
        store.optionList = [];
    }

    return (
        <Modal
            title={'옵션 결합'}
            show={AppStore.isOpenAddOptionPopup}
            onHide={() => AppStore.toggleAddOptionPopup()}
            toggleValue={AppStore.isOpenAddOptionPopup}
            size={'lg'}
            onExit={refresh}
        >
            <CContainer>
                {!assetNo &&
                    <Row>
                        <CCol lg={12} >
                            선택된 시리얼 번호 : {selProductList.map(v => v.serialNo).join(', ')} 
                        </CCol>
                    </Row>
                }
                <AgGridContainer
                    gridRef={gridRef}
                    height={20}
                    rowData={store.optionList}
                    columnDefs={[
                        {field: "seq", headerName: "순번", width: 80},
                        {field: "optionProductSeq", headerName: "제품 시퀀스", width: 120},
                        {field: "optionProductName", headerName: "제품명", width: 120},
                        {field: "optionModel", headerName: "모델명", width: 240},
                        {field: "qty", headerName: "수량", headerClass: 'grid-column-editable', onCellValueChanged: handleConsumQtyEdit, editable: true, width: 140, flex: 1},
                    ]}
                    isCheckBox={true}
                    customBtnInfo={[saveBtn, selectBtn]}
                />
            </CContainer>
            <StockOptionSearch callbackFn={callbackOptionListSearch} selProductList={assetNo ? [selProductList] : selProductList} isAddOption={true}/>
        </Modal>
    )
}

export default observer(AddOptionPopup);
