import { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {Col, Row, Form, Tabs, Tab, InputGroup} from "react-bootstrap";
import axios from 'axios';
import {CContainer, CCol, CInputGroup, CSaveBtn, CCancelBtn, CSelectGroup, CalendarGroup, CUpdateBtn, CRemoveBtn, CIconBtn, CAddBtn, Subject} from '../../components/CustomContainer';
import Address from '../../components/Address';
import LUtils from '../../utils/lodashUtils';
import { callConfirm } from '../../utils';
import { getCommcode } from "../../utils/commcode";
import Modal from '../../components/Modal';
import { AppStore } from '../../store/AppStore';
import Regex from '../../common/regex';
import CustomerHistory from './tabs/CustomerHistory';
import CustomerBillingAddress from './tabs/CustomerBillingAddress';
import CustomerManager from './tabs/CustomerManager';
import CustomerAccount from './tabs/CustomerAccount';
import CustomerLimit from './tabs/CustomerLimit';
import { CustomerStore } from '../../store/customer/CustomerStore';
import {CommcodeSMOptions} from "../../components";
import {getDays} from "../../utils/dateUtils";
import {showToast} from "../../common/utils";
import UserSearch from "../../components/searchModal/UserSearch";
import {getUserName} from "../../utils/userUtils";
import { MULTIPART_HEADER } from '../../common/constants';

/**
 * 고객상세는 모달로 열어야 한다.
 * @param {String} customerNo: 고객번호
 * @param {String} openReason: read(default), create, update
 * @param {Function} getCustomerList: (생략가능) 고객목록 조회 함수
 */
const CustomerDetail = ({openReason='read', customerNo, getCustomerList, setCustomerNo=null }) => {
    const store = CustomerStore;
    const detailStatus = {
        'create': '고객등록',
        'update': '고객 정보 수정',
        'read': '고객 정보',
        'readForAfterCreate': '고객 정보',
        'createForAfterCreate': '고객 등록',
        'updateForAfterCreate': '고객 정보 수정',
    }
    const customerInit = {customerNo:'', customerType:'1', customerDiv: '1', isAvailableContract: true, customerName:'', owner:'', companyType:'', businessNumber:'',
                            corporationNumber:'', establishmentDate:'', industryType:'', businessConditions:'', employeeCount:'', mainBank:'',
                            companyPostcode:'', companyAddress:'', companyAddressDetail:'', closedDate:null, inflowType: '',
                            saleUserSeq: '', saleUserName: '', saleUserSeq2: '', saleUserName2: '', isPenaltyFee: 0, penaltyPercent: 40,
                            paymentType: '', billingType: '', billingDay: 0, dueDateType: '', dueDateDay: 0, endDate: 0, isDeleted: 0, deposit: 0};

    const [customerInfo, setCustomerInfo] = useState(customerInit);
    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [isDisable, setIsDisable] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [calendar, setCalender] = useState({startDate: '', endDate: ''});
    const [modalMode, setModalMode] = useState('');
    const [searchUserType, setSearchUserType] = useState('');
    const [isRequire, setIsRequire] = useState(true);
    const [inputBusinessNumber, setInputBusinessNumber] = useState(false);
    const fileRef = useRef();

    useEffect(() => {
        if(customerInfo.customerDiv !== '1'){
            setIsRequire(false);
        }

    },[customerInfo, postcode, address, addressDetail, isDisable, isValid, calendar, modalMode]);

    /* modal open init */
    const modalInit = async () => {
        // 등록상태가 아니면 고객 조회
        if(['update', 'read', 'readForAfterCreate'].includes(openReason)) {
            await getCustomerInfo(customerNo);
        }

        // 등록상태면 초기화 한다.
        ['create', 'createForAfterCreate'].includes(openReason) && refresh();

        setModalMode(openReason);
        setIsDisable(['read', 'readForAfterCreate'].includes(openReason));
    }

    /* 사업자번호 검사 */
    const checkFormBusinessNumber = (value) => {
        let valueFormat;

        // 숫자만 입력하는지 검사
        const inputNumber = value.replaceAll('-', '');
        if (isNaN(inputNumber)) {
            showToast('숫자만 입력해야 합니다.');
            return false;
        }

        if(customerInfo.customerType !== '3'){
            // 12자리 넘어가면 return false
            if (value.length >= 13) {
                return false;
            }

            // 000-00-00000 형태로 만들기
            if (inputNumber.length < 4) {
                valueFormat = inputNumber;
            } else if (inputNumber.length > 3 && inputNumber.length < 6) {
                valueFormat = `${inputNumber.substring(0, 3)}-${inputNumber.substring(3, 5)}`
            } else {
                valueFormat = Regex.makeBusinessNumber(inputNumber);
            }
        } else {
            valueFormat = inputNumber;
        }

        onChangeCustomerInfo(valueFormat, 'businessNumber');
    }

    /* 고객 상세 조회 */
    const getCustomerInfo = async (customerNo) => {
        if (!customerNo) {
            showToast('고객번호가 없습니다.');
            return false;
        }

        const result = await axios.get(`/customer/${customerNo}`);
        result.businessNumber = result.businessNumber && Regex.makeBusinessNumber(result.businessNumber);
        setDataForInput(result);
    }

    /* 조회한 값 입력 */
    const setDataForInput = (result) => {
        setCalender({startDate: result.establishmentDate, endDate: result.establishmentDate});
        setCustomerInfo(result);
        store.fileName = result.businessLicenseFileName;
        store.fileUrl = result.businessLicenseUrl;
        setPostcode(result.companyPostcode);
        setAddress(result.companyAddress);
        setAddressDetail(result.companyAddressDetail);

        if (result.businessNumber.length > 0) {
            setInputBusinessNumber(true);
        } else if(result.businessNumber.length === 0) {
            setInputBusinessNumber(false);
        }
    }

    /* 사업자번호 조회 */
    const getBusinessNumber = async () => {
        if (LUtils.isEmpty(customerInfo.businessNumber)) {
            showToast('사업자번호를 입력해주세요.');
            return false;
        }

        if (customerInfo.customerType !== '3' && customerInfo.businessNumber.length !== 12) {
            await showToast('사업자번호 양식에 맞지 않습니다.');
            return false;
        }

        //사업자번호 유효성 검사(137137135 코드 체크 방식)
        var businessNumberMap = customerInfo.businessNumber.replaceAll(/-/gi,'').split('').map(function (number){return parseInt(number, 10);});
        if (businessNumberMap.length === 10) {
            var checkValueMap = new Array(1,3,7,1,3,7,1,3,5);
            var checkSum = 0;
            for (var i = 0; i < checkValueMap.length; ++i) {
                checkSum += checkValueMap[i] * businessNumberMap[i];
            }
            checkSum += parseInt((checkValueMap[8] * businessNumberMap[8]) / 10, 10);
            if (businessNumberMap[9] !== ((10 - (checkSum % 10)) % 10)) {
                showToast("유효하지 않은 사업자번호입니다. 번호순서나 숫자오타를 확인해 주세요.");
                return false;
            }
        }

        if(customerInfo.customerDiv === '1'){
            const result = await axios.get('/customer/businessNumber', {params: {businessNumber: customerInfo.businessNumber.replaceAll('-', '')}});
            if (!result) {
                showToast(`사용가능한 사업자번호입니다.`);
                setIsValid(true);
            } else {
                onChangeCustomerInfo('businessNumber', '');
                showToast(`이미 등록된 사업자번호 입니다.`);
                setIsValid(false);
                return false;
            }
        }else{
            setIsValid(true);
        }
    }


    const onCreate = async () => {
        // isValid 검사
        /*
        if (isRequire && !isValid) {
            showToast('사업자번호 중복확인 되어야 합니다.');
            return false;
        }
        */

        const params = {
            ...customerInfo,
            businessNumber: customerInfo.businessNumber.replaceAll('-', ''),
            isAvailableContract: customerInfo.isAvailableContract ? 1 : 0,
            establishmentDate: calendar.startDate,
            companyPostcode: postcode,
            companyAddress: address,
            companyAddressDetail: addressDetail,
            businessLicenseFileName:  store.fileName,
        }
        // 입력 필수값 검사
        if(!customerInfo.customerName){
            showToast('고객명을 입력해 주세요.');
            return false;
        }

        if(isRequire){
            /* 사업자번호 필수해제. 계약 확정 시에 체크
            if(!customerInfo.businessNumber){
                showToast('사업자번호를 입력해 주세요.');
                return false;
            }
            */
            if(!customerInfo.owner){
                showToast('대표자명을 입력해 주세요.');
                return false;
            }
            if(!customerInfo.industryType){
                showToast('업종을 입력해 주세요.');
                return;
            }
            if(!customerInfo.businessConditions){
                showToast('업태를 입력해 주세요.');
                return;
            }   
            if(!postcode){
                showToast('우편번호를 입력해 주세요.');
                return false;
            }
            if(!address){
                showToast('주소를 입력해 주세요.');
                return false;
            }
        }

        if (!await callConfirm('저장하시겠습니까?')) {
            return false;
        }

        const result = await axios.post("/customer", params);
        showToast('저장되었습니다.');

        setModalMode('readForAfterCreate');
        result.businessNumber = Regex.makeBusinessNumber(result.businessNumber);

        setDataForInput(result);
        setIsDisable(true);
        customerInfo.businessNumber.length > 0 && setInputBusinessNumber(true);

        getCustomerList && getCustomerList();
    }

    /* input onchange 함수 */
    const onChangeCustomerInfo = (value, target) => {
        target === 'businessNumber' && setIsValid(false);
        setCustomerInfo({...customerInfo, [target]: value});

        if(target === 'customerDiv'){
            if(value !== '1'){
                setIsRequire(false);
            }else{
                setIsRequire(true);
            }
        }
    }

    const onUpdate = async () => {
        const params = {
            ...customerInfo,
            isAvailableContract: customerInfo.isAvailableContract ? 1 : 0,
            establishmentDate: calendar.startDate,
            companyPostcode: postcode,
            companyAddress: address,
            companyAddressDetail: addressDetail,
        }
        // 입력 필수값 검사
        if(!customerInfo.customerName){
            showToast('고객명을 입력해 주세요.');
            return false;
        }

        if(isRequire){
            if(!customerInfo.businessNumber){
                showToast('사업자번호를 입력해 주세요.');
                return false;
            }
            if(!customerInfo.owner){
                showToast('대표자명을 입력해 주세요.');
                return false;
            }
            if(!customerInfo.industryType){
                showToast('업종을 입력해 주세요.');
                return;
            }
            if(!customerInfo.businessConditions){
                showToast('업태를 입력해 주세요.');
                return;
            }            
            if(!postcode){
                showToast('우편번호를 입력해 주세요.');
                return false;
            }
            if(!address){
                showToast('주소를 입력해 주세요.');
                return false;
            }
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return false;
        }

        await axios.put(`/customer/${customerInfo.customerNo}`, params);
        showToast('저장되었습니다.');

        setModalMode('read');
        setIsDisable(true);
        customerInfo.businessNumber.length > 0 && setInputBusinessNumber(true);

        await getCustomerInfo(customerInfo.customerNo);
        getCustomerList && getCustomerList();
    }

    /* 폐업처리 */
    const onClosedCustomer = async () => {
        if (!await callConfirm('폐업처리 하시겠습니까?')) {
            return false;
        }

        await axios.put(`/customer/${customerInfo.customerNo}/closed`);
        showToast('폐업처리 되었습니다.');
        await getCustomerInfo(customerInfo.customerNo);

    }

    /* 고객삭제 */
    const onDeleteCustomer = async () => {
        showToast('사용이 제한된 기능입니다.');
        return;
        if (!await callConfirm('삭제처리 하시겠습니까?')) {
            return false;
        }

        await axios.delete(`/customer/${customerInfo.customerNo}/deleted`);
        showToast('삭제처리 되었습니다.');
        getCustomerList && getCustomerList();
        AppStore.toggleCustomerModal();
    }

    /*----- ui mode 변경 ----*/

    /* update */
    const onUpdateMode = (mode) => {
        setModalMode(mode);
        setIsDisable(false);
    }

    /* update - cancel */
    const cancelUpdateMode = (mode) => {
        setModalMode(mode);
        setIsDisable(true);
        // getCustomerInfo(customerNo);

    }

    /* 추가등록 - 등록모드 */
    const onAdditionalCreateMode = () => {
        setModalMode('createForAfterCreate');
        setIsDisable(false);
        refresh();
    }

    /* 초기화 */
    const refresh = () => {
        setCustomerInfo(customerInit);
        setPostcode('');
        setAddress('');
        setAddressDetail('');
        setCalender({startDate: null, endDate: null});
        setIsValid(false);
        setInputBusinessNumber(true);
        setCustomerNo && setCustomerNo('');
        store.fileName = '';
        store.fileUrl = '';

        CustomerStore.initTabsList();
    };

    const callBackUser =(info)=> {
        if(searchUserType === '1'){
            customerInfo.saleUserSeq = info.userSeq;
            customerInfo.saleUserName = info.name;
        }else{
            customerInfo.saleUserSeq2 = info.userSeq;
            customerInfo.saleUserName2 = info.name;
        }

        setCustomerInfo({...customerInfo});
    }

     /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData) => {
        if(LUtils.isEmpty(fileData.target.files)) {
            return false;
        }

        if(customerInfo.customerNo) {
            if(!await callConfirm('사업자등록증을 업로드하시겠습니까?')) return;
        }
        store.fileName = fileData.target.files[0].name;

        let result = '';
        const file = fileData.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        if(customerInfo.customerNo){
            formData.append('customerNo', customerInfo.customerNo);
            result = await axios.post('/customer/business/upload', formData, { headers: MULTIPART_HEADER });
        }else {
            result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });
        }

        customerInfo.businessLicenseUrl = result;
        customerInfo.businessLicenseFileName = file.name;
        store.fileName = file.name;
    }

    return (
        <>
        <Modal title={detailStatus[modalMode]} onHide={()=>AppStore.toggleCustomerModal()} toggleValue={AppStore.isOpenForCustomerModal} onShow={modalInit} onExit={refresh}>
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup label={'고객번호'}
                                     value={customerInfo.customerNo || ''}
                                     disabled={true}
                                     onChange={e => onChangeCustomerInfo(e.target.value, 'customerNo')}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'*고객유형'}
                            value={customerInfo.customerType || ''}
                            onChange={e => onChangeCustomerInfo(e.target.value, 'customerType')}
                            options={getCommcode('19')} disabled={isDisable}
                            labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'*고객구분'}
                            value={customerInfo.customerDiv || ''}
                            onChange={e => onChangeCustomerInfo(e.target.value, 'customerDiv')}
                            options={getCommcode('49')} disabled={isDisable}
                            labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={3}>
                        <Form.Check
                            checked={customerInfo.isAvailableContract || ''}
                            type="switch"
                            onChange={e => onChangeCustomerInfo(e.target.checked, 'isAvailableContract')}
                            label="계약가능여부"
                            id='isAvailableContract'
                            style={{paddingTop: 8}}
                            disabled={isDisable}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'*고객명'}
                            value={customerInfo.customerName || ''}
                            disabled={isDisable}
                            onChange={e => onChangeCustomerInfo(e.target.value,'customerName')}
                            labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'*대표자'}
                            value={customerInfo.owner || ''}
                            disabled={isDisable}
                            onChange={e => onChangeCustomerInfo(e.target.value,'owner')}
                            labelClassName={isRequire && 'input-required'}
                        />
                    </CCol>
{/*
                    <CCol lg={3}>
                        <CInputGroup
                            label={'기업형태'}
                            value={customerInfo.companyType || ''}
                            disabled={isDisable}
                            onChange={e => onChangeCustomerInfo(e.target.value,'companyType')}
                        />
                    </CCol>
*/}
                </Row>
                <Row>
                    <CCol lg={3}>
                        {(['create','createForAfterCreate'].includes(modalMode) || !inputBusinessNumber) 
                        ?
                            <CInputGroup
                                label={'사업자번호'}
                                value={customerInfo.businessNumber || ''}
                                onChange={e => checkFormBusinessNumber(e.target.value)}
                                onCallbackBtn={getBusinessNumber}
                                buttonLabel={'중복검사'}
                                //isValid={isValid}
                                enterKeyHint={getBusinessNumber}
                                //labelClassName={isRequire && 'input-required'}
                                disabled={isDisable}
                            />
                        :
                            <CInputGroup label={'*사업자번호'} value={customerInfo.businessNumber || ''} disabled labelClassName={isRequire && 'input-required'} />
                        }
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'법인번호'} value={customerInfo.corporationNumber || ''} disabled={isDisable} onChange={e => onChangeCustomerInfo(e.target.value,'corporationNumber')}/>
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup label='설립일' asSingle={true} value={calendar || ''} onChange={e => setCalender(e)} disabled={isDisable}  />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup label={'*업태'} value={customerInfo.industryType || ''} disabled={isDisable} onChange={e => onChangeCustomerInfo(e.target.value,'industryType')} labelClassName={isRequire && 'input-required'} />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'*종목'} value={customerInfo.businessConditions || ''} disabled={isDisable} onChange={e => onChangeCustomerInfo(e.target.value,'businessConditions')} labelClassName={isRequire && 'input-required'} />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'주거래은행'} value={customerInfo.mainBank || ''} disabled={isDisable} onChange={e => onChangeCustomerInfo(e.target.value,'mainBank')}/>
                    </CCol>
                    {/*
                    <CCol lg={3}>
                        <CInputGroup label={'소속그룹'} value={customerInfo.groupCompany || ''} disabled={isDisable} onChange={e => onChangeCustomerInfo(e.target.value,'groupCompany')}/>
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'종업원수'} value={customerInfo.employeeCount || ''} disabled={isDisable} onChange={e => onChangeCustomerInfo(e.target.value,'employeeCount')}/>
                    </CCol>
                    */}
                </Row>
                <Row>
                    <CCol lg={10} md={12}>
                        <Address
                            label='*본사주소'
                            setPostcode={setPostcode}
                            setAddress={setAddress}
                            setAddressDetail={setAddressDetail}
                            postcode={postcode}
                            address={address}
                            addressDetail={addressDetail}
                            disabled={isDisable}
                            labelClassName={isRequire && 'input-required'}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'유입경로'}
                            options={[{value:'', name:'선택'}, ...getCommcode('16')]}
                            value={customerInfo.inflowType || ''}
                            onChange={(v)=> onChangeCustomerInfo(v.target.value, 'inflowType')}
                            disabled={isDisable}
                        />
                    </CCol>
                    {customerNo &&
                        <CCol lg={3}>
                            <CInputGroup label={'보증금'} value={customerInfo.deposit ? customerInfo.deposit .toLocaleString() : 0} disabled={true} backLabel={'원'} align={'right'}/>
                        </CCol>
                    }
                     <CCol lg={6}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {store.fileUrl ?
                                    <>
                                        사업자등록증 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={store.fileUrl}>
                                            {store.fileName}
                                        </a>
                                    </>
                                : '사업자등록증 업로드'}
                            </label>
                            {store.fileName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> store.fileName = ''} />
                            :
                                <Form.Control
                                    type="file"
                                    id="profileImg"
                                    size="sm"
                                    placeholder={'사업자등록증 업로드'}
                                    onChange={fileUploadForS3}
                                    ref={fileRef}
                                />
                            }
                        </InputGroup>
                    </CCol>
{/* 
                    <CCol lg={4} >
                        <InputGroup>
                            <Form.Control
                                type="file"
                                id="attachFile"
                                size="sm"
                                onChange={fileUploadForS3}
                                ref={fileRef}
                                style={{ display: 'none' }} // 숨김
                            />
                            <label htmlFor="attachFile" className="custom-file-upload">
                                사업자등록증
                            </label>
                            {store.fileName && <span className="align-self-lg-center">{store.fileName}</span>}
                        </InputGroup>
                    </CCol> */}

                </Row>
                <Row>
                    {!!customerInfo.closedDate &&
                        <CCol lg={3}>
                            <CInputGroup label={'폐업일자'} value={customerInfo.closedDate || ''} onChange={e => onChangeCustomerInfo(e.target.value,'closedDate')} disabled/>
                        </CCol>
                    }
                </Row>
                <Row>
                    <Subject>계약관리</Subject>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'영업 담당자'}
                            labelId={'saleUserName'}
                            value={customerInfo.saleUserName ? customerInfo.saleUserName : getUserName(customerInfo.saleUserSeq)}
                            onCallbackBtn={() => {
                                setSearchUserType('1');
                                AppStore.toggleUserSearchModal();
                            }}
                            disabled={true}
                            btnDisabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'지원 담당자'}
                            labelId={'saleUserName2'}
                            value={customerInfo.saleUserName2 ? customerInfo.saleUserName2 : getUserName(customerInfo.saleUserSeq2)}
                            onCallbackBtn={() => {
                                setSearchUserType('2');
                                AppStore.toggleUserSearchModal();
                            }}
                            disabled={true}
                            btnDisabled={isDisable}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'마감일자'}
                            options={
                                getDays().map((d,i) => {
                                    return {name: d.text, value: d.value};
                                })
                            }
                            value={customerInfo.endDate || ''}
                            onChange={(v)=> onChangeCustomerInfo(v.target.value, 'endDate')}
                            disabled={isDisable}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <Form.Check
                            type="switch"
                            id="isPenaltyFee"
                            label="위약금 청구"
                            style={{marginBottom: 8}}
                            checked={!!customerInfo.isPenaltyFee}
                            onChange={
                                v => {
                                    !v.target.checked && onChangeCustomerInfo(0, 'penaltyPercent');
                                    onChangeCustomerInfo(v.target.checked ? 1 : 0, 'isPenaltyFee')
                                }}
                            disabled={isDisable}
                        />
                        <InputGroup size={'sm'}>
                            <CSelectGroup
                                label={'잔여 렌탈료의'}
                                options={[
                                    {name: '10%', value: 10},
                                    {name: '20%', value: 20},
                                    {name: '30%', value: 30},
                                    {name: '40%', value: 40},
                                    {name: '50%', value: 50},
                                    {name: '60%', value: 60},
                                    {name: '70%', value: 70},
                                    {name: '80%', value: 80},
                                    {name: '90%', value: 90},
                                ]}
                                value={customerInfo.penaltyPercent || ''}
                                onChange={(v)=> onChangeCustomerInfo(v.target.value, 'penaltyPercent')}
                                disabled={isDisable}
                            />
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <Form.Label>청구조건</Form.Label>
                        <InputGroup size={'sm'}>
                            <Form.Select
                                aria-label="선택"
                                style={{width: '55%'}}
                                value={customerInfo.billingType || ''}
                                onChange={(v) => onChangeCustomerInfo(v.target.value, 'billingType')}
                                disabled={isDisable}
                            >
                                <CommcodeSMOptions commKind={'21'} />
                            </Form.Select>
                            <Form.Select
                                aria-label="선택"
                                value={customerInfo.billingDay || 0}
                                onChange={(v) => onChangeCustomerInfo(v.target.value, 'billingDay')}
                                disabled={isDisable}
                            >
                                {getDays().map((d,i) => {
                                    return (<option key={i} value={d.value}>{d.text}</option>);
                                })}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <Form.Label>납기조건</Form.Label>
                        <InputGroup size={'sm'}>
                            <Form.Select
                                aria-label="선택"
                                style={{width: '55%'}}
                                value={customerInfo.dueDateType || ''}
                                onChange={(v) => onChangeCustomerInfo(v.target.value, 'dueDateType')}
                                disabled={isDisable}
                            >
                                <CommcodeSMOptions commKind={'22'} />
                            </Form.Select>
                            <Form.Select
                                aria-label="선택"
                                value={customerInfo.dueDateDay || ''}
                                onChange={(v) => onChangeCustomerInfo(v.target.value, 'dueDateDay')}
                                disabled={isDisable}
                            >
                                {getDays().map((d,i) => {
                                    return (<option key={i} value={d.value}>{d.text}</option>);
                                })}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <Form.Label>결제방식</Form.Label>
                        <InputGroup size={'sm'}>
                            <Form.Select
                                aria-label="선택"
                                value={customerInfo.paymentType || ''}
                                onChange={(v) => onChangeCustomerInfo(v.target.value, 'paymentType')}
                                disabled={isDisable}
                            >
                                <CommcodeSMOptions commKind={'17'} />
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        {modalMode === 'create' &&
                            <>
                            <CSaveBtn onClick={onCreate} />
                            </>
                        }

                        {modalMode === 'read' &&
                            <>
                            <CUpdateBtn onClick={() => onUpdateMode('update')} />
                            {!customerInfo.closedDate &&
                                <CIconBtn title={'폐업처리'} icon={'fi-rr-door-closed'} onClick={onClosedCustomer} style={{width: 90}} />
                            }
                            <CRemoveBtn title={'고객삭제'} onClick={onDeleteCustomer} style={{width: 90}} />
                            </>
                        }

                        {modalMode === 'update' &&
                            <>
                            <CSaveBtn onClick={onUpdate} />
                            <CCancelBtn onClick={() => cancelUpdateMode('read')} />
                            </>
                        }
                        {modalMode === 'readForAfterCreate' &&
                            /* 추가등록, 수정 */
                            <>
                            <CUpdateBtn onClick={() => onUpdateMode('updateForAfterCreate')} />
                            <CAddBtn title={'추가등록'} style={{width: 80}} onClick={onAdditionalCreateMode}/>
                            </>
                        }
                        {modalMode === 'createForAfterCreate' &&
                            /* 취소, 저장 */
                            <>
                            <CSaveBtn onClick={onCreate} />
                            <CCancelBtn onClick={() => cancelUpdateMode('readForAfterCreate')} />
                            </>
                        }
                        {modalMode === 'updateForAfterCreate' &&
                            /* 취소, 저장 */
                            <>
                            <CSaveBtn onClick={onUpdate} />
                            <CCancelBtn onClick={() => cancelUpdateMode('readForAfterCreate')} />
                            </>
                        }
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <Row>
                    <Tabs defaultActiveKey="billingAddress" className="mb-3" mountOnEnter={true} unmountOnExit={true} >
                        <Tab eventKey="billingAddress" title="고객 청구지">
                            <CustomerBillingAddress customerNo={customerNo} customerInfo={customerInfo} />
                        </Tab>
                        <Tab eventKey="manager" title="고객담당자">
                            <CustomerManager customerNo={customerNo} customerInfo={customerInfo} />
                        </Tab>
                        <Tab eventKey="account" title="고객계좌">
                            <CustomerAccount customerNo={customerNo} customerInfo={customerInfo} />
                        </Tab>
                        <Tab eventKey="limit" title="고객한도">
                            <CustomerLimit customerNo={customerNo} customerInfo={customerInfo} />
                        </Tab>
                        <Tab eventKey="history" title="고객변경이력">
                            <CustomerHistory customerNo={customerNo} />
                        </Tab>
                    </Tabs>
                </Row>
            </CContainer>
            <UserSearch callbackFn={callBackUser}/>
        </Modal>
        </>
    );
};

export default observer(CustomerDetail);

