import { observer } from 'mobx-react-lite';
import {Row, Col, Form} from "react-bootstrap";
import {CContainer, CInputGroup, CCol, CSearchBtn, CalendarGroup, CSelectGroup, CSaveBtn} from '../../components/CustomContainer';
import {AssetSearchStore} from '../../store/asset/AssetSearchStore';
import {getCommcode} from "../../utils/commcode";
import { AppStore } from "../../store/AppStore";
import ContractSearch from "../../components/searchModal/ContractSearch";
import CustomerSearch from '../../components/searchModal/CustomerSearch';
import {HireAssetSearchStore} from "../../store/asset/HireAssetSearchStore";
import {useEffect, useState} from "react";

const HireAssetManagerSearch = ({getAssetList}) => {

    useEffect(()=> {
    },[]);

     /* 계약조회 팝업 콜백 */
     const callbackContractInfo = (info) => {
        HireAssetSearchStore.setCondition('contractNo', info.contractNo);
    }

    /* 고객 조회 콜백 */
    const searchCustomerInfo =(data)=> {
        HireAssetSearchStore.setCondition('buyCustomerName', data.customerName);
    }

    return (
        <>
            {/* 검색조건 */}
            <CContainer search>
                <Row>
                    <CCol>
                        <CInputGroup label={'관리번호'} labelId={'managementNo'}
                                     value={HireAssetSearchStore.condition.managementNo}
                                     onChange={v => HireAssetSearchStore.setCondition('managementNo', v.target.value)}
                                     onEmptyBtn={()=> {HireAssetSearchStore.setCondition('managementNo', '')}}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol>
                        <CInputGroup label={'현장명'} labelId={'siteName'}
                                     value={HireAssetSearchStore.condition.siteName}
                                     onChange={v => HireAssetSearchStore.setCondition('siteName', v.target.value)}
                                     onEmptyBtn={()=> {HireAssetSearchStore.setCondition('siteName', '')}}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                     value={HireAssetSearchStore.condition.contractNo}
                                     onCallbackBtn={() => AppStore.toggleContractSearchModal()}
                                     onChange={v => HireAssetSearchStore.setCondition('contractNo', v.target.value)}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'모델명'} labelId={'model'}
                                     value={HireAssetSearchStore.condition.model}
                                     onChange={v=> HireAssetSearchStore.setCondition('model', v.target.value)}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'시리얼번호'} labelId={'serialNo'}
                                     value={HireAssetSearchStore.condition.serialNo.replaceAll(' ', ',')}
                                     onChange={v => HireAssetSearchStore.setCondition('serialNo', v.target.value)}
                                     onEmptyBtn={()=> {HireAssetSearchStore.setCondition('serialNo', '')}}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'자산상태'} labelId={'assetStatus'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('8'))}
                                      value={HireAssetSearchStore.condition.assetStatus || ''}
                                      onChange={v => HireAssetSearchStore.setCondition('assetStatus', v.target.value)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'임차회사'} labelId={'buyCustomerName'}
                                    onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                                    value={HireAssetSearchStore.condition.buyCustomerName || ''}
                                    onChange={v => HireAssetSearchStore.setCondition('buyCustomerName', v.target.value)}
                                    enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup label={'입고시작일'}
                                        asSingle={true}
                                        value={{
                                            startDate: HireAssetSearchStore.condition.acquStartDate,
                                            endDate: HireAssetSearchStore.condition.acquStartDate,
                                        }}
                                        onChange={v => {HireAssetSearchStore.condition.acquStartDate = v.startDate}}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup label={'입고종료일'}
                                        asSingle={true}
                                        value={{
                                            startDate: HireAssetSearchStore.condition.acquEndDate,
                                            endDate: HireAssetSearchStore.condition.acquEndDate,
                                        }}
                                        onChange={v => {HireAssetSearchStore.condition.acquEndDate = v.endDate}}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6} style={{marginTop: 5}}>
                        <Form.Check
                            inline
                            label="전체"
                            name="selStatus"
                            type={'radio'}
                            value={0}
                            id={`all`}
                            checked={HireAssetSearchStore.condition.status === 0}
                            onChange={()=> HireAssetSearchStore.setCondition('status', 0)}
                        />
                        <Form.Check
                            inline
                            label="사용중"
                            name="selStatus"
                            type={'radio'}
                            id={'use'}
                            value={1}
                            checked={HireAssetSearchStore.condition.status === 1}
                            onChange={()=> HireAssetSearchStore.setCondition('status', 1)}
                        />
                        <Form.Check
                            inline
                            label="반납"
                            name="selStatus"
                            type={'radio'}
                            value={2}
                            id={'return'}
                            checked={HireAssetSearchStore.condition.status === 2}
                            onChange={()=> HireAssetSearchStore.setCondition('status', 2)}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getAssetList} />
                    </Col>
                </Row>
            </CContainer>
            {AssetSearchStore.isVirtualStorage &&
                <CContainer search>
                    <Row>
                        <CCol lg={3}>
                            <CInputGroup label={'입고비고*'} labelId={'remark'}
                                         placeholder={'입고 비고를 입력해 주세요.'}
                                         isValid={true}
                                         value={AssetSearchStore.remark}
                                         onChange={v => AssetSearchStore.setRemark(v.target.value)}
                            />
                        </CCol>
                        <Col><CSaveBtn title={'입고'} onClick={()=>{}}/></Col>
                    </Row>
                </CContainer>
            }
            <ContractSearch callbackFn={callbackContractInfo}/>
            <CustomerSearch callbackFn={searchCustomerInfo}/>
        </>
    );
};

export default observer(HireAssetManagerSearch);
