import { useState, useRef, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn, CInputDoubleGroup } from "../CustomContainer";
import CustomerSearch from "./CustomerSearch";
import LUtils from "../../utils/lodashUtils";
import { findCommKrnm } from "../../utils/commcode";
import AgGridContainer from "../AgGridContainer";
import {showToast} from "../../common/utils";

const ContractAssetSearch =({ callbackFn=null, column='', ...props })=> {
    const gridRef = useRef();

    const {contractType, selectContractNo, selectAssetNo} = props;
    const [searchList, setSearchList] = useState([]);
    const [contractNo, setContractNo] = useState('');
    const [customerNo, setCustomerNo] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [assetNo, setAssetNo] = useState('');
    //const [productCode, setProductCode] = useState('');
    const [model, setModel] = useState('');
    const [managementNo, setManagementNo] = useState('');
    const [searchAssetType, setSearchAssetType] = useState('availableRent');

    useEffect(()=> {
        setContractNo(selectContractNo);
        setAssetNo(selectAssetNo);
    }, [selectContractNo, selectAssetNo])

    const onSearch = async () => {

        let result = [];
        if(contractType != null && contractType === '4' && searchAssetType === 'availableRent') {
            if (LUtils.isEmpty(contractNo) && LUtils.isEmpty(customerNo) && LUtils.isEmpty(assetNo) && LUtils.isEmpty(model) && LUtils.isEmpty(managementNo)) {
                showToast("검색조건을 입력해주세요!");
                return false;
            }
            result = await axios.get('/asset/list/popup', { params: { contractNo, assetNo, model }});
        }else {
            if (LUtils.isEmpty(contractNo) && LUtils.isEmpty(customerNo) && LUtils.isEmpty(assetNo) && LUtils.isEmpty(managementNo)) {
                showToast("검색조건을 입력해주세요!");
                return false;
            }
            result = await axios.get('/contractAsset/list', { params: { contractNo, customerNo, assetNo }});
        }

        if(result.length === 0) showToast('검색된 데이터가 없습니다.');
        setSearchList(result);
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data);
        AppStore.toggleContractAssetSearchModal();
    }

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        setCustomerNo(customerInfo.customerNo);
        setCustomerName(customerInfo.customerName);
    }


    /* 초기화 */
    const refresh = () => {
        setContractNo('');
        setCustomerNo('');
        setCustomerName('');
        setAssetNo('');
        setSearchList([]);
    }

    const callBackGridData = ({ selectedList }) => {
        callbackFn && callbackFn(selectedList);
        AppStore.toggleContractAssetSearchModal();
    }

    return (
        <Modal
            title={'계약자산조회'}
            show={AppStore.isOpenContractAssetSearch}
            onHide={() => AppStore.toggleContractAssetSearchModal()}
            toggleValue={AppStore.isOpenContractAssetSearch}
            className={'search-modal-6'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                {(contractType != null && contractType === '4') &&
                    <CCol lg={2}>
                        <Form.Check
                            inline
                            label={"임대가능"}
                            name="availableRent"
                            type={'radio'}
                            id='searchAssetType-2'
                            value={'availableRent'}
                            checked={searchAssetType === 'availableRent'}
                            onChange={(e)=> {setSearchAssetType(e.target.value), setSearchList([])}}
                        />
                        <Form.Check
                            inline
                            label={"계약중"}
                            name="underContract"
                            type={'radio'}
                            id='searchAssetType-1'
                            value={'underContract'}
                            checked={searchAssetType === 'underContract'}
                            onChange={(e)=> {setSearchAssetType(e.target.value), setSearchList([])}}
                        />
                    </CCol>
                }
                {/*
                    <CCol lg={2}>
                        <CInputGroup
                            label={'제품코드'}
                            labelId={'productCode'}
                            value={productCode}
                            onChange={(v)=> setProductCode(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                */}
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            labelId={'contractNo'}
                            value={contractNo}
                            onChange={(v)=> setContractNo(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    {contractType === null || searchAssetType !== 'availableRent' &&
                        <CCol lg={3}>
                            <CInputDoubleGroup
                                label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                placeholder={'고객코드'} placeholder2={'고객명'}
                                disabled={false}
                                disabled2={true}
                                onCallbackBtn={openSearchCustomerPopup}
                                value={customerNo || ''}
                                value2={customerName || ''}
                                onChange={(v)=> {setCustomerNo(v.target.value), setCustomerName('')}}
                            />
                        </CCol>
                    }
                    <CCol lg={2}>
                        <CInputGroup
                            label={'자산번호'}
                            labelId={'assetNo'}
                            value={assetNo}
                            onChange={(v)=> setAssetNo(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'관리번호'}
                            labelId={'managementNo'}
                            value={managementNo}
                            onChange={(v)=> setManagementNo(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={model}
                            onChange={(v)=> setModel(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearch} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                {(contractType != null && contractType === '4') ?
                    <AgGridContainer
                        gridRef={gridRef}
                        height={40}
                        rowData={searchList}
                        columnDefs={[
                            {field: "assetNo", headerName: "자산번호", width: 130, hide: true},
                            {field: "managementNo", headerName: "관리번호", width: 130},
                            {field: "model", headerName: "모델명", width: 130},
                            {field: "assetStatus", headerName: "자산상태", valueFormatter: v => findCommKrnm(v.value, '8'), width: 110},
                            {field: "acquDate", headerName: "취득일", width: 130},
                            {field: "acquAmount", headerName: "취득가",  cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), width: 110,},
                            {field: "", headerName: "장부가", width: 130, hide: true},
                            {field: "contractStatus", headerName: "계약상태", valueFormatter: v => searchAssetType === 'availableRent'? 'N' : 'Y', width: 130},
                            {field: "contractNo", headerName: "최근 계약번호", width: 140},
                            {field: "paymentType", headerName: "결제방식", hide: true},
                            {field: "customerNo", headerName: "고객코드", hide: true},
                            {field: "customerName", headerName: "최근 고객명", width: 150},
                            {field: "billingAddressSeq", headerName: "청구지번호", hide: contractType && searchAssetType === 'availableRent' ? true : false},
                            {field: "billingName", headerName: "청구지명", width: 180, hide: contractType && searchAssetType === 'availableRent' ? true : false},
                            {field: "contractStartDate", headerName: "계약시작일", width: 120, hide: contractType && searchAssetType === 'availableRent' ? true : false},
                            {field: "contractEndDate", headerName: "계약종료일", width: 120, hide: contractType && searchAssetType === 'availableRent' ? true : false},
                            {field: "storageNo", headerName: "입고번호", width: 110, hide: contractType && searchAssetType === 'availableRent' ? true : false},
                            {field: "storageDate", headerName: "일고일자", width: 110, hide: contractType && searchAssetType === 'availableRent' ? true : false},
                            {field: "dueDateType", headerName: "납기조건", width: 130, valueFormatter: (v) => findCommKrnm(v.value, '22'), hide: contractType && searchAssetType === 'availableRent' ? true : false},
                            {field: "dueDateDay", headerName: "납기일자", valueFormatter: v => v.value === 0 ? '말일' : v.value+'일', hide: contractType && searchAssetType === 'availableRent' ? true : false},
                        ]}
                        isCheckBox={true}
                        callBackGridData={callBackGridData}
                        selectBtnInfo={{isUsed: true}}
                        btnTitles={{save: '선택'}}
                        enableCellTextSelection={true}
                        rowDoubleClickCallback={selectedRow}
                    />
                :
                    <AgGridContainer
                        gridRef={gridRef}
                        height={40}
                        rowData={searchList}
                        columnDefs={[
                            {field: "contractNo", headerName: "계약번호", width: 130},
                            {field: "assetNo", headerName: "자산번호", width: 130, hide: true},
                            {field: "managementNo", headerName: "관리번호", width: 130},
                            {field: "model", headerName: "모델명", width: 130},
                            {field: "assetStatus", headerName: "자산상태", valueFormatter: v => findCommKrnm(v.value, '8'), width: 130},
                            {field: "paymentType", headerName: "결제방식", hide: true},
                            {field: "customerNo", headerName: "고객코드", hide: true},
                            {field: "customerName", headerName: "고객명", width: 130},
                            {field: "billingAddressSeq", headerName: "청구지번호"},
                            {field: "billingName", headerName: "청구지명", width: 180},
                            {field: "contractStartDate", headerName: "계약시작일", width: 120},
                            {field: "contractEndDate", headerName: "계약종료일", width: 120},
                            {field: "storageNo", headerName: "입고번호", width: 100},
                            {field: "storageDate", headerName: "일고일자", width: 100},
                            {field: "dueDateType", headerName: "납기조건", width: 130, valueFormatter: (v) => findCommKrnm(v.value, '22')},
                            {field: "dueDateDay", headerName: "납기일자", valueFormatter: v => v.value === 0 ? '말일' : v.value+'일'},
                        ]}
                        rowDoubleClickCallback={selectedRow}
                    />
                }
            </CContainer>
            <CustomerSearch callbackFn={getCustomerInfo}/>
        </Modal>
    )
}

export default observer(ContractAssetSearch);
