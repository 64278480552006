import { observable } from "mobx";
import axios from 'axios';
import LUtils from "../utils/lodashUtils";
import { setAxiosAuthorization } from "../utils/axiosSetup";
import { LINK_USER_INFO, USER_INFO } from '../common/constants';
import {slice} from "lodash";

const JWT_AWP_RENTAL = 'JWT_AWP_RENTAL';

export const AppStore = observable({
    // --------------  Observer   --------------

    /* 계정관련 */
    user: {userSeq: '', id: '', name: '', authorities: '', companyCode: '', companyList: []},
    jwt: '',
    isLogin: false,
    isMobile: false,
    
    /* axios 세팅 */
    isAxiosSetting: false,

    /* aggrid 관련 */
    gridData: [],
    gridTitle: '',

    /* UI 관련 */
    isOpenForSidebar: false,
    isOpenForModal: false,
    isOpenForCustomerModal: false,
    isOpenCustomerSearch: false,    // 고객조회모달
    isOpenCustomerBillingAddressSearch: false,  // 고객청구지조회 모달
    isOpenUserSearch: false,        // 사원조회 모달
    isOpenProductSearch: false,     // 렌탈가능물품조회 모달
    isOpenEstimateSearch: false,    // 견적조회 모달
    isOpenOrderSearch: false,       // 발주조회 모달
    isOpenProductListSearch: false, // 제품조회 모달
    isStockProductSearch: false,    //상품 및 자산 재고 조회 모달
    isStockAssetSearch: false,      //자산 재고 조회 모달
    isStockOptionSearch: false,    //옵션 재고 조회 모달
    isStockGoodsSearch: false,      //상품(옵션,소모품) 재고 조회 모달
    isReservationAssetSearch: false,    //예약가능자산조회 모달
    isOpenAssetSearch: false,       // 자산조회 모달
    isOpenContractAssetSearch: false,    // 계약자산조회 모달
    isOpenContractSearch: false,    // 계약조회 모달
    isOpenConsumablesSearch: false,      //소모품조회 모달
    isOpenDelayPopup: false,        // 자산 연장 모달
    isOpenShortenPopup: false,      // 자산 단축 모달
    isOpenReturnItemPopup: false,   // 회수제품 리스트 팝업
    isOpenConfirmPopup: false,      // 인수인도확인서
    isOpenReleaseAssetCheckPopup: false, //출고자산검수 팝업
    isOpenCustomerManagerSearchPopup: false,  //고객담당자
    isRunningSpinner: false,   // 공용스피너
    isOpenRepairAssetPopup: false, // 수리자산 등록
    isOpenCertificatePopup: false,  // 인증서 팝업
    isOpenAssetConsumPopup: false,  //자산에 연결된 소모품 리스트
    isOpenAgreePopup: false,    //책임 동의 팝업
    isOpenApprovalPostingDatePopup: false,     //선택전표승인 기표일자 선택 팝업
    isOpenAccountingCodePopup: false,    // 계정과목조회 팝업
    isOpenStatementPopup: false,    // 전표 조회 팝업
    isOpenReleaseAskDetail: false,  //출고의뢰 상세
    isOpenBillPublishPopup: false, //계산서 발행
    isOpenPasswordCheckPopup: false,    //비밀번호 확인 팝업
    isOpenCertificateListPopup: false,  // 인증서 목록 팝업
    isOpenWorkLogPopup: false,      //업무일지
    isOpenLedgerDetail: false,      // 거래처 원장 상세
    isOpenSiteDetailPopup: false,   //현장 상세 정보
    isOpenSiteNameSearchPopup: false,     // 현장명 조회 팝업
    isOpenAsDetail: false,          // AS 접수 상세
    isOpenInsertProduct: false,     // 제품 추가
    isOpenReleaseAskPopup: false,   // 출고의뢰 팝업
    isOpenSendEstimatePopup: false,   // 견적서 메일 팝업
    isOpenSendContractPopup: false,   // 계약서 메일 팝업
    isOpenSendTradingStatementPopup: false,   // 거래명세서 메일 팝업
    isOpenReserveDetailPopup: false, //예약 상세 팝업
    isOpenSendPayStubPopup: false,  // 명세서 전송 팝업
    isOpenStockDetailPopup: false, //
    isOpenConfirmMailPopup: false, // 메일 전송 확인 팝업
    isOpenModifyCompanyContractPopup: false, // 회사계약정보변경 팝업
    isOpenSendTaxBillPopup: false, // 세금계산서 국세청 발행 팝업(이메일 리스트)
    isOpenSendMailPopup: false, // 인수인도 확인서 메일 전송 팝업
    isOpenSelectEmailPopup: false, // 메일 선택 
    isOpenSiteManagerSearchPopup: false, // 현장 담당자 팝업
    isOpenPreviewCertificationContentPopup: false, // 내용증명 템플릿 미리보기 팝업
    isOpenCertificationContentDetailPopup: false, // 내용증명 문서 상세 팝업
    isOpenSendCertificationContentPopup: false, // 내용증명 메일 전송 팝업
    isOpenSendNonPaymentSmsPopup: false, // 미납 독촉 문자 발송 팝업
    isOpenNonpaymentDetailPopup: false, // 미납 상세 팝업
    isOpenAssetOptionPopup: false,  // 자산 옵션 팝업
    isOpenSiteCustomerManagerSearch: false, // 현장, 고객 담당자 조회 팝업
    isOpenAsManagerUserSearch: false,   // A/S 담당 사원 조회
    isOpenAddOptionPopup: false,        // 옵션 결합 팝업
    isOpenAsManagerUserSearch: false,   // A/S 담당 사원 조회
    isOpenBillItemPopup: false,         // 계산서 발행 대상의 품목 팝업
    isOpenContractAssetOptionPopup: false,  //계약 상세 자산별 옵션품목 팝업
    isOpenContractDetailPopup: false,   // 계약상세팝업
    isOpenOutsourcingInfo: false,       // 외주 배송 정보 팝업
    isOpenPeriodSearchPopup: false,         // 기간 조회 팝업
    isOpenTaxBillDetailPopup: false,        // 세금계산서 상세 팝업
    isOpenUploadDocumentPopup: false,       // 문서 업로드 팝업
    isOpenAsSchedulePopup: false,           // as 스케쥴 상세 팝업
    isOpenCarryoverChargePopup: false,      // 청구 이월 팝업
    isOpenChangePricePopup: false,          // 렌탈료 변경 팝업
    isOpenChangePriceSavePopup: false,      // 렌탈료 변경 저장 팝업
    isOpenExpirationAssetSms: false,        // 만기자산 알림톡 전송 팝업
    isShipManagerPopup: false,              // 배송 담당자
    isApronSearchPopup: false,              // 주기장 조회 팝업
    isApronSettingPopup: false,             // 주기장 설정 팝업
    isOpenSendTaxBillSmsPopup: false,       // 세금계산서 알림톡 발송 팝업 
    
    /* Open page history */
    /* Open page history */
    openPageHistory: [],


    // --------------  Action   --------------

    /* 로그인 유저정보 설정 */
    setUser(user) {
        const {userSeq, id, name, authorities, jwt: token, companyCode, companyName, mdCategories, smCategories, imageUrl,
            businessNumber, owner, companyAddress, returnAddress, apronSeq, companyList} = user;

        this.user.userSeq = userSeq;
        this.user.id = id;
        this.user.name = name;
        this.user.companyCode = companyCode;
        this.user.companyName = companyName;
        this.user.authorities = LUtils.split(authorities, ',');
        this.user.mdAuthList = LUtils.split(mdCategories, ',');
        this.user.smAuthList = LUtils.split(smCategories, ',');
        this.user.imageUrl = imageUrl;
        this.user.businessNumber = businessNumber;
        this.user.owner = owner;
        this.user.companyAddress = companyAddress;
        this.user.returnAddress = returnAddress;
        this.user.apronSeq = apronSeq;
        this.user.companyList = companyList;

        localStorage.setItem(USER_INFO, JSON.stringify(this.user))

        this.isLogin = true;

        if (!!token) {
            this.jwt = token;
            localStorage.setItem(JWT_AWP_RENTAL, token);
        }

        setAxiosAuthorization();
    },

    /* 로그인 유저정보 설정 (링크 접속) */
    setLinkUser(user) {
        const {userSeq, id, name, authorities, jwt: token, companyCode, companyName, mdCategories, smCategories, imageUrl} = user;

        this.user.userSeq = userSeq;
        this.user.id = id;
        this.user.name = name;
        this.user.companyCode = companyCode;
        this.user.companyName = companyName;
        this.user.authorities = LUtils.split(authorities, ',');
        this.user.mdAuthList = LUtils.split(mdCategories, ',');
        this.user.smAuthList = LUtils.split(smCategories, ',');
        this.user.imageUrl = imageUrl;
        localStorage.setItem(LINK_USER_INFO, JSON.stringify(this.user))

        this.isLogin = true;

        if (!!token) {
            this.jwt = token;
            localStorage.setItem("JWT_LINK_AWP_RENTAL", token);
        }

        setAxiosAuthorization();
    },

    /* 계약 문서 접속 설정 */
    setContractLink(info) {
        const {contractNo, jwt: token} = info;

        this.isLogin = true;

        if(!!token) {
            this.jwt = token;
            localStorage.setItem("JWT_CONTRACT_DOCUMENT", token);
        }
        setAxiosAuthorization();
    },

    /* 세금계산서 문서 접속 설정 */
    setHometaxBillLink(info) {
        const {billNo, jwt: token} = info;

        this.isLogin = true;

        if(!!token) {
            this.jwt = token;
            localStorage.setItem("JWT_TAXBILL_DOCUMENT", token);
        }
        setAxiosAuthorization();
    },

    /* 로그인 유저정보 초기화 */
    resetUser() {
        this.user.id = '';
        this.user.name = '';
        this.user.authorities = [];

        this.jwt = '';
        this.isLogin = false;

        // localStorage.setItem(JWT_AWP_RENTAL, null);
        localStorage.removeItem(JWT_AWP_RENTAL);

        setAxiosAuthorization();
    },

    /* 로그인 여부 확인 */
    async checkLogin() {
        const jwt = localStorage.getItem(JWT_AWP_RENTAL);

        if (!jwt) {
            setAxiosAuthorization();
            window.location.href = '/login';
            return false;
        } else {
            AppStore.jwt = jwt;
            setAxiosAuthorization();

            this.isLogin = true;

            const userInfo = JSON.parse(localStorage.getItem(USER_INFO));
            this.user = userInfo;
        }

        try {
            const result = await axios.get('/user/token/info');
            this.setUser(result);
        } catch (error) {
            setAxiosAuthorization();
            window.location.href = '/login';
        }
    },

    setIsMobile(v){
        this.isMobile = v;
    },

    /* 스피너 작동 */
    setRunningSpinner() {
        this.isRunningSpinner = true;
    },

    /* 스피너 중지 */
    setStopSpinner() {
        this.isRunningSpinner = false;
    },

    /* sidebar toggle */
    toggleSidebar() {
        this.isOpenForSidebar = !this.isOpenForSidebar;
    },

    /* modal toggle */
    toggleModal() {
        this.isOpenForModal =!this.isOpenForModal;
    },
    toggleCustomerModal() {
        this.isOpenForCustomerModal =!this.isOpenForCustomerModal;
    },
    toggleCustomerSearchModal() {
        this.isOpenCustomerSearch = !this.isOpenCustomerSearch;
    },
    toggleCustomerBillingAddressSearchModal() {
        this.isOpenCustomerBillingAddressSearch = !this.isOpenCustomerBillingAddressSearch;
    },
    toggleUserSearchModal() {
        this.isOpenUserSearch = !this.isOpenUserSearch;
    },
    toggleProductSearchModal() {
        this.isOpenProductSearch = !this.isOpenProductSearch;
    },
    toggleEstimateSearchModal(){
        this.isOpenEstimateSearch = !this.isOpenEstimateSearch;
    },
    toggleStockProductSearchModal(){
        this.isStockProductSearch = !this.isStockProductSearch;
    },
    toggleStockAssetSearchModal(){
        this.isStockAssetSearch = !this.isStockAssetSearch;
    },
    toggleStockOptionSearchModal(){
        this.isStockOptionSearch = !this.isStockOptionSearch;
    },
    toggleStockGoodsSearchModal(){
        this.isStockGoodsSearch = !this.isStockGoodsSearch;
    },
    toggleReservationAssetSearchModal(){
        this.isReservationAssetSearch = !this.isReservationAssetSearch;
    },
    toggleOrderSearchModal(){
        this.isOpenOrderSearch = !this.isOpenOrderSearch;
    },
    toggleProductListSearchModal(){
        this.isOpenProductListSearch = !this.isOpenProductListSearch;
    },
    toggleAssetSearchModal(){
        this.isOpenAssetSearch = !this.isOpenAssetSearch;
    },
    toggleContractAssetSearchModal(){
        this.isOpenContractAssetSearch = !this.isOpenContractAssetSearch;
    },
    toggleContractSearchModal(){
        this.isOpenContractSearch = !this.isOpenContractSearch;
    },
    toggleConsumablesSearchModal(){
        this.isOpenConsumablesSearch = !this.isOpenConsumablesSearch;
    },
    toggleDelayPopup(){
        this.isOpenDelayPopup = !this.isOpenDelayPopup;
    },
    toggleShortenPopup(){
        this.isOpenShortenPopup = !this.isOpenShortenPopup;
    },
    pushOpenPageHistory(v){
        if(!this.openPageHistory.some(p => p.name === v.name)){
            this.openPageHistory.push(v);
        }
    },
    deleteOpenPageHistory(v){
        const findIndex = findIndex(this.openPageHistory, v);
        this.openPageHistory = slice(this.openPageHistory, findIndex, 1);
    },
    toggleReturnItemPopup(){
        this.isOpenReturnItemPopup = !this.isOpenReturnItemPopup;
    },
    toggleConfirmPopup(){
        this.isOpenConfirmPopup = !this.isOpenConfirmPopup;
    },
    toggleReleaseAssetCheckPopup(){
        this.isOpenReleaseAssetCheckPopup = !this.isOpenReleaseAssetCheckPopup;
    },
    toggleCustomerManagerSearchPopup(){
        this.isOpenCustomerManagerSearchPopup = !this.isOpenCustomerManagerSearchPopup;
    },
    toggleRepairAssetPopup(){
        this.isOpenRepairAssetPopup = !this.isOpenRepairAssetPopup;
    },
    toggleCertificatePopup(){
        this.isOpenCertificatePopup = !this.isOpenCertificatePopup;
    },
    toggleAssetConsumPopup(){
        this.isOpenAssetConsumPopup = !this.isOpenAssetConsumPopup;
    },
    toggleAgreePopup(){
        this.isOpenAgreePopup = !this.isOpenAgreePopup;
    },
    toggleApprovalPostingDatePopup(){
        this.isOpenApprovalPostingDatePopup = !this.isOpenApprovalPostingDatePopup;
    },
    toggleAccountingCodePopup(){
        this.isOpenAccountingCodePopup = !this.isOpenAccountingCodePopup;
    },
    toggleStatementPopup(){
        this.isOpenStatementPopup = !this.isOpenStatementPopup;
    },
    toggleReleaseAskDetailPopup(){
        this.isOpenReleaseAskDetail = !this.isOpenReleaseAskDetail;
    },
    toggleBillPublishPopup(){
        this.isOpenBillPublishPopup = !this.isOpenBillPublishPopup;
    },
    togglePasswordCheckPopup() {
        this.isOpenPasswordCheckPopup = !this.isOpenPasswordCheckPopup;
    },
    toggleCertificateListPopup(){
        this.isOpenCertificateListPopup = !this.isOpenCertificateListPopup;
    },
    toggleWorkLogPopup(){
        this.isOpenWorkLogPopup = !this.isOpenWorkLogPopup;
    },
    toggleLedgerDetail(){
        this.isOpenLedgerDetail = !this.isOpenLedgerDetail;
    },
    toggleSiteDetail(){
        this.isOpenSiteDetailPopup = !this.isOpenSiteDetailPopup;
    },
    toggleSiteNameSearchPopup(){
        this.isOpenSiteNameSearchPopup = !this.isOpenSiteNameSearchPopup;
    },
    toggleAsDetail(){
        this.isOpenAsDetail = !this.isOpenAsDetail;
    },
    toggleInsertProduct(){
        this.isOpenInsertProduct = !this.isOpenInsertProduct;
    },
    toggleReleaseAskPopup(){
        this.isOpenReleaseAskPopup = !this.isOpenReleaseAskPopup;
    },
    toggleSendEstimatePopup() {
        this.isOpenSendEstimatePopup = !this.isOpenSendEstimatePopup;
    },
    toggleSendContractPopup() {
        this.isOpenSendContractPopup = !this.isOpenSendContractPopup;
    },
    toggleSendTradingStatementPopup() {
        this.isOpenSendTradingStatementPopup = !this.isOpenSendTradingStatementPopup;
    },
    toggleReserveDetailPopup(){
        this.isOpenReserveDetailPopup = !this.isOpenReserveDetailPopup;
    },
    toggleSendPayStubPopup() {
        this.isOpenSendPayStubPopup = !this.isOpenSendPayStubPopup;
    },
    toggleStockDetailPopup() {
        this.isOpenStockDetailPopup = !this.isOpenStockDetailPopup;
    },
    toggleConfirmMailPopup() {
        this.isOpenConfirmMailPopup = !this.isOpenConfirmMailPopup;
    },
    toggleModifyCompanyContractPopup() {
        this.isOpenModifyCompanyContractPopup = !this.isOpenModifyCompanyContractPopup;
    },
    toggleSendTaxBillPopup() {
        this.isOpenSendTaxBillPopup = !this.isOpenSendTaxBillPopup;
    },
    toggleSendMailPopup() {
        this.isOpenSendMailPopup = !this.isOpenSendMailPopup;
    },
    toggleSelectEmailPopup() {
        this.isOpenSelectEmailPopup = !this.isOpenSelectEmailPopup;
    },
    toggleSiteManagerSearchPopup() {
        this.isOpenSiteManagerSearchPopup = !this.isOpenSiteManagerSearchPopup;
    },
    togglePreviewCertificationContentPopup() {
        this.isOpenPreviewCertificationContentPopup = !this.isOpenPreviewCertificationContentPopup;
    },
    toggleSendCertificationContentPopup() {
        this.isOpenSendCertificationContentPopup = !this.isOpenSendCertificationContentPopup;
    },
    toggleSendNonPaymentSmsPopup() {
        this.isOpenSendNonPaymentSmsPopup = !this.isOpenSendNonPaymentSmsPopup;
    },
    toggleOpenNonpaymentDetailPopup() {
        this.isOpenNonpaymentDetailPopup = !this.isOpenNonpaymentDetailPopup;
    },
    toggleOpenCertificationContentDetailPopup() {
        this.isOpenCertificationContentDetailPopup = !this.isOpenCertificationContentDetailPopup;
    },
    toggleOpenAssetOptionPopup () {
        this.isOpenAssetOptionPopup = !this.isOpenAssetOptionPopup;
    },
    toggleOpenSiteCustomerManagerSearch() {
        this.isOpenSiteCustomerManagerSearch = !this.isOpenSiteCustomerManagerSearch;
    },
    toggleOpenAsManagerUserSearch() {
        this.isOpenAsManagerUserSearch = !this.isOpenAsManagerUserSearch;
    },
    toggleOpenBillItemPopup() {
        this.isOpenBillItemPopup = !this.isOpenBillItemPopup;
    },
    toggleAddOptionPopup() {
        this.isOpenAddOptionPopup = !this.isOpenAddOptionPopup;
    },
    toggleOpenContractAssetOptionPopup(){
        this.isOpenContractAssetOptionPopup = !this.isOpenContractAssetOptionPopup;
    },
    toggleOpenContractDetailPopup(){
        this.isOpenContractDetailPopup = !this.isOpenContractDetailPopup;
    },
    toggleOutsourcingInfoPopup(){
        this.isOpenOutsourcingInfo = !this.isOpenOutsourcingInfo;
    },
    togglePeriodSearchPopup(){
        this.isOpenPeriodSearchPopup = !this.isOpenPeriodSearchPopup;
    },
    toggleTaxBillDetailPopup(){
        this.isOpenTaxBillDetailPopup = !this.isOpenTaxBillDetailPopup;
    },
    toggleUploadDcoumentPopup(){
        this.isOpenUploadDocumentPopup = !this.isOpenUploadDocumentPopup;
    },
    toggleAsSchedulePopup(){
        this.isOpenAsSchedulePopup = !this.isOpenAsSchedulePopup;
    },
    toggleCarryoverChargePopup(){
        this.isOpenCarryoverChargePopup = !this.isOpenCarryoverChargePopup;
    },
    toggleChangePricePopup(){
        this.isOpenChangePricePopup = !this.isOpenChangePricePopup;
    },
    toggleChangePriceSavePopup(){
        this.isOpenChangePriceSavePopup = !this.isOpenChangePriceSavePopup;
    },
    toggleExpirationAssetSmsPopup() {
        this.isOpenExpirationAssetSms = !this.isOpenExpirationAssetSms;
    },
    toggleShipManagerPopup() {
        this.isShipManagerPopup = !this.isShipManagerPopup;
    },
    toggleApronSearchPopup() {
        this.isApronSearchPopup = !this.isApronSearchPopup;
    },
    toggleApronSettingPopup() {
        this.isApronSettingPopup = !this.isApronSettingPopup;
    },
    toggleSendTaxBillSmsPopup() {
        this.isOpenSendTaxBillSmsPopup = !this.isOpenSendTaxBillSmsPopup;
    },
});
