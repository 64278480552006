import { useEffect, useRef, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import { CommcodeSMOptions } from "../index";
import AgGridContainer from "../AgGridContainer";
import {showToast} from "../../common/utils";
import { ConsumableStore } from "../../store/search/ConsumableStore";
import { getLocalStorage } from "../../utils";
import { USER_INFO } from "../../common/constants";
import { MaintenanceStore } from "../../store/asset/MaintenanceStore";


const ConsumablesSearch =({ callbackFn=null, ...props })=> {
    const store = ConsumableStore;
    const gridRef = useRef();
    const [searchList, setSearchList] = useState([]);
    const [productCode, setProductCode] = useState('');
    const [model, setModel] = useState('');
    const [brandCode, setBrandCode] = useState('');
    const [selProductNames, setSelProductNames] = useState('');
    const [available, setAvailable] = useState('stock');
    const [apronList, setApronList] = useState([]);

    useEffect(()=> {
        let products = '';
        if(props.selProductList.length > 0){
            props.selProductList.forEach((v,i) => {
                if(i === 0){
                    products = v.model;
                }else{
                    products += `, ${v.model}`;
                }
            });

            setSelProductNames(products);

            if(props.isMaintenanceDetail) {
                onSearch();
                props.isMaintenanceDetail = false;

            }
        }
    }, [props.selProductList]);

    useEffect(() => {
        getApronList();
        if (props.isMaintenanceDetail && AppStore.isOpenConsumablesSearch) {
            store.search.apronSeq = MaintenanceStore.detail.apronSeq ? MaintenanceStore.detail.apronSeq : getLocalStorage(USER_INFO).apronSeq;
        } 
    }, [AppStore.isOpenConsumablesSearch]);

    const getApronList = async() => {
        const result = await axios.get("/user/apron");
        const resultList = result.map(v => {
            v.name = v.apronName;
            v.value = v.apronSeq;
            return v;
        })
        setApronList(resultList);
    }

    /* 조회 */
    const onSearch = async () => {
        const params = {
            productCode: productCode,
            productName: store.search.productName,
            model: model,
            serialNo: store.search.serialNo,
            brandCode: brandCode,
            available: available,
            apronSeq: store.search.apronSeq,
            isDeleted: 0,
        };
        const result = await axios.get('/asset/consumable/list', { params });
        if(result.length === 0){
            showToast('검색된 데이터가 없습니다.');
        }
        setSearchList(result);
    }

    /* 제조사 값 설정 */
    const onChangeBrandName = (v) => {
        setBrandCode(v.target.value);
    }


    const callBackGridData = ({ selectedList }) => {
        if(selectedList.some(v => v.rentAvailableCnt <= 0)){
            showToast('재고가 없는 소모품이 선택되었습니다.');
            return;
        }

        callbackFn && callbackFn(selectedList);
        AppStore.toggleConsumablesSearchModal();
    }

    const onRowDoubleClicked = (e) => {
        const selectedList = [];
        selectedList.push({...e.data});

        callbackFn(selectedList);
        AppStore.toggleConsumablesSearchModal();
    }

    /* 초기화 */
    const refresh = () => {
        setSearchList([]);
        store.init();
    }

    return (
        <Modal
            title={'소모품 조회'}
            show={AppStore.isOpenConsumablesSearch}
            onHide={() => AppStore.toggleConsumablesSearchModal()}
            toggleValue={AppStore.isOpenConsumablesSearch}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'소모품명'}
                            labelId={'productName'}
                            value={store.search.productName}
                            onChange={(v)=> store.search.productName = v.target.value}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={model}
                            onChange={(v)=> setModel(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'시리얼번호'}
                            labelId={'serialNo'}
                            value={store.search.serialNo}
                            onChange={(v)=> store.search.serialNo = v.target.value}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    {/* <CCol lg={2}>
                        <CInputGroup
                            label={'제품코드'}
                            labelId={'productCode'}
                            value={productCode}
                            onChange={(v)=> setProductCode(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol> */}
                </Row>
                <Row>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select aria-label="선택" value={brandCode} onChange={onChangeBrandName}>
                                <option value={''}>- 제조사 -</option>
                                { <CommcodeSMOptions commKind={'18'} />}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select aria-label="선택" value={store.search.apronSeq || ''} onChange={(v) => store.search.apronSeq= v.target.value}>
                                <option value={''}>- 주기장 -</option>
                                {apronList.map((option, idx)=> {
                                    return(
                                        <option key={idx} value={option.value}>{option.name}</option>
                                    );
                                })}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <>
                            <Form.Check
                                inline
                                label="전체"
                                name="availableStock"
                                type={'radio'}
                                id="all"
                                value={''}
                                checked={available === ''}
                                onChange={(e)=> setAvailable(e.target.value)}
                            />
                            <Form.Check
                                inline
                                label="임대가능"
                                name="availableStock"
                                type={'radio'}
                                id='stock'
                                value={'stock'}
                                checked={available === 'stock'}
                                onChange={(e)=> setAvailable(e.target.value)}
                            />
                        </>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        선택된 제품 : {selProductNames}  <span style={{color: 'red'}}>(선택된 모델 모두 적용됩니다.)</span>
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearch} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={40}
                    rowData={searchList}
                    columnDefs={[
                        // {field: "consumProductCode", headerName: "제품코드", width: 120},
                        {field: "consumModel", headerName: "모델명", width: 150},
                        {field: "consumProductName", headerName: "제품명", width: 200},
                        {field: "brandName", headerName: "제조사"},
                        {field: "apronName", headerName: "주기장", width: 100},
                        {field: "qty", headerName: "재고수량", width: 110},
                        {field: "storageNo", headerName: "입고번호", width: 120},
                        {field: "storageSeq", headerName: "입고시퀀스", width: 120, hide: true},
                        {field: "serialNo", headerName: "시리얼번호", width: 180},
                        {field: "spec", headerName: "스펙", minWidth: 200},
                        {field: "apronSeq", headerName: "주기장순번", hide: true},
                    ]}
                    isCheckBox={true}
                    useIntoTheTab={true}
                    callBackGridData={callBackGridData}
                    selectBtnInfo={{isUsed: true}}
                    btnTitles={{save: '선택'}}
                    getRowStyle={(params) => {
                        if (params.data.qty <= 0) {
                            return { pointerEvents: 'none', color: '#848484' };
                        }
                        return null;
                    }}
                    rowDoubleClickCallback={e => onRowDoubleClicked(e)}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(ConsumablesSearch);
