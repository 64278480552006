import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useReactToPrint } from "react-to-print";
import { Col, Row } from "react-bootstrap";

import { businessNumberFormat, decrypt } from "../../../common/utils";
import { CContainer, CIconBtn } from "../../../components/CustomContainer";
import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import "./tax-bill-styles.css"; 
import axios from "axios";
import { TaxBillStore } from "../../../store/billing/TaxBillStore";

const TaxBillDetailPopup =( props, ref )=> {
    const divRef = useRef(null);
    const [info, setInfo] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [isSendSmsBtn, setIsSendSmsBtn] = useState(false);

    useEffect(() => {
        setInfo(props.taxBillData);
        if(props.taxBillData.taxBillSeq) {
            getBillItem();
        } else if (props.taxBillData.billNo) {
            getBillItemByBillNo();
        }
        if(props.isSendSmsBtn) {
            setIsSendSmsBtn(props.isSendSmsBtn);
        }
    }, [props.taxBillData]);

    const getBillItem = async () => {
        const result = await axios.get(`/taxbill/detail/${props.taxBillData.taxBillSeq}`);
        setItemList(result);
    }

    const getBillItemByBillNo = async () => {
        const result = await axios.get(`/bill/item/${props.taxBillData.billNo}`);
        setItemList(result.map(v => {return {itemName: v.item, standard: '', qty: v.qty, unitAmount: v.unitAmount, supplyAmount: v.supplyAmount, vatAmount: v.taxAmount, remark: v.billItemNote}}));
    }

    const printBtn = () => {
        print();
    }

    const print = useReactToPrint({
        content: () => divRef.current,
    });

    const refresh = () => {

    }

    const openSendSmsPopup = () => {
        AppStore.toggleSendTaxBillSmsPopup();
    }

    return (
        <Modal title={'세금계산서'}
            onHide={() => AppStore.toggleTaxBillDetailPopup()}
            toggleValue={AppStore.isOpenTaxBillDetailPopup}
            onExit={refresh}
        >
            <div ref={divRef} className="print-template">
                <div style={{display: 'flex', alignItems: 'center', alignContent: 'center', flexDirection: 'column'}}>
                    <table className="bill-table" style={{borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, borderBottomWidth: 1}}>
                        <tbody>
                            <tr style={{backgroundColor: '#D8D8D8'}}>
                                <th colSpan={6} style={{fontWeight: 'bold', fontSize: 20, borderRightWidth: 0.5, borderRightColor: '#A4A4A4'}}>전자세금계산서</th>
                                <th colSpan={2} style={{borderRightWidth: 0.5, borderRightColor: '#A4A4A4'}}>승인번호</th>
                                <td colSpan={4}>{info.approvalNo}</td>
                            </tr>
                            <tr>
                                <th className="red" rowSpan={5} style={{width: 25, backgroundColor: '#ffd9d9', color: '#e10d0d', fontWeight: 'bold'}}>공급자</th>
                                <th className="red-column" style={{backgroundColor: '#fff2f2'}}>등록번호</th>
                                <td className="red" colSpan={2} style={{width: '20%', textAlign: 'center', fontWeight: 'bold', fontSize: 15}}>{businessNumberFormat(info.supplyBusinessNumber) || ''}</td>
                                <th className="red-column">종사업장<br/>번호</th>
                                <td className="red" style={{width: 70}}></td>
                                <th className="blue" rowSpan={5} style={{width: 25, backgroundColor: '#c2d9f2', color: '#0a57cb', fontWeight: 'bold'}}>공급받는자</th>
                                <th className="blue-column">등록번호</th>
                                <td className="blue" colSpan={2} style={{width: '20%', textAlign: 'center', fontWeight: 'bold', fontSize: 15}}>{businessNumberFormat(info.customerBusinessNumber) || ''}</td>
                                <th className="blue-column">종사업장<br/>번호</th>
                                <td className="blue" style={{width: 70}}></td>
                            </tr>
                            <tr>
                                {/* 공급자 */}
                                <th className="red-column">상호<br/>(법인명)</th>
                                <td className="red" colSpan={2}>{info.supplyCompanyName || ''}</td>
                                <th className="red-column">성명</th>
                                <td className="red">{info.supplyOwner || ''}</td>
                                {/* 공급받는자 */}
                                <th className="blue-column">상호<br/>(법인명)</th>
                                <td className="blue" colSpan={2}>{info.customerCompanyName || ''}</td>
                                <th className="blue-column">성명</th>
                                <td className="blue">{info.customerOwner || ''}</td>
                            </tr>
                            <tr>
                                {/* 공급자 */}
                                <th className="red-column">사업장</th>
                                <td className="red" colSpan={4}>{info.supplyCompanyAddress || ''}</td>
                                {/* 공급받는자 */}
                                <th className="blue-column">사업장</th>
                                <td className="blue" colSpan={4}>{info.customerCompanyAddress || ''}</td>
                            </tr>
                            <tr>
                                {/* 공급자 */}
                                <th className="red-column">업태</th>
                                <td className="red" style={{width: '13%'}}>{info.supplyCategory || ''}</td>
                                <th className="red-column">종목</th>
                                <td className="red" colSpan={2}>{info.supplyItem || ''}</td>
                                {/* 공급받는자 */}
                                <th className="blue-column">업태</th>
                                <td className="blue" style={{width: '13%'}}>{info.customerCategory || ''}</td>
                                <th className="blue-column">종목</th>
                                <td className="blue" colSpan={2}>{info.customerItem || ''}</td>
                            </tr>
                            <tr>
                                {/* 공급자 */}
                                <th className="red-column">이메일</th>
                                <td className="red" colSpan={4}>{info.supplyEmail || ''}</td>
                                {/* 공급받는자 */}
                                <th className="blue-column">이메일</th>
                                <td className="blue" colSpan={4}>{info.customerEmail || ''}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="bill-table" style={{borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2}}>
                        <tbody>
                            <tr>
                                <th className="gray-column" style={{width: '15%'}}>작성일자</th>
                                <th className="gray-column" style={{width: '20%'}}>공급가액</th>
                                <th className="gray-column"  style={{width: '20%'}}>세액</th>
                                <th className="gray-column">수정사유</th>
                            </tr>
                            <tr>
                                <td className="gray-content">{info.issueDate}</td>
                                <td className="gray-content right">{info.itemSupplyAmount?.toLocaleString()}</td>
                                <td className="gray-content right">{info.itemVatAmount?.toLocaleString()}</td>
                                <td className="gray-content"></td>
                            </tr>
                            <tr>
                                <th className="gray-column">비고</th>
                                <td className="gray-column" colSpan={3} style={{backgroundColor: '#fff'}}></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="bill-table" style={{borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, borderBottomWidth: 2}}>
                        <tbody>
                            <tr>
                                <th className="gray-column" style={{width: '3%'}}>월</th>
                                <th className="gray-column" style={{width: '3%'}}>일</th>
                                <th className="gray-column" style={{width: '25%'}}>품목</th>
                                <th className="gray-column" style={{width: '6%'}}>규격</th>
                                <th className="gray-column" style={{width: '6%'}}>수량</th>
                                <th className="gray-column" style={{width: '12%'}}>단가</th>
                                <th className="gray-column" style={{width: '15%'}}>공급가액</th>
                                <th className="gray-column" style={{width: '12%'}}>세액</th>
                                <th className="gray-column">비고</th>
                            </tr>
                            {itemList.length > 0 && itemList.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="gray-content">{info.issueDate?.substring(5, 7) || ''}</td>
                                        <td className="gray-content">{info.issueDate?.substring(8, 10) || ''}</td>
                                        <td className="gray-content">{data.itemName || ''}</td>
                                        <td className="gray-content">{data.standard || ''}</td>
                                        <td className="gray-content">{data.qty}</td>
                                        <td className="gray-content right">{data.unitAmount?.toLocaleString()}</td>
                                        <td className="gray-content right">{data.supplyAmount?.toLocaleString()}</td>
                                        <td className="gray-content right">{data.vatAmount?.toLocaleString()}</td>
                                        <td className="gray-content">{data.remark}</td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                            </tr>
                            <tr>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="bill-table" style={{borderTopWidth: 1, borderLeftWidth: 2, borderRightWidth: 2, borderBottomWidth: 2}}>
                        <tbody>
                            <tr>
                                <th className="gray-column" style={{width: '14%'}}>합계금액</th>
                                <th className="gray-column" style={{width: '14%'}}>현금</th>
                                <th className="gray-column" style={{width: '14%'}}>수표</th>
                                <th className="gray-column" style={{width: '14%'}}>어음</th>
                                <th className="gray-column" style={{width: '14%'}}>외상미수금</th>
                                <th className="gray-content" rowSpan={2} style={{width: '30%'}}>이 금액을 ( 청구 ) 함</th>
                            </tr>
                            <tr>
                                <td className="gray-content right">{info.totalAmount?.toLocaleString()}</td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                                <td className="gray-content"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <CContainer>
                <Row>
                    <Col className='d-flex flex-row-reverse' style={{paddingRight: 165}}>
                        <CIconBtn title={'프린트'} style={{width: 120}} variant={'primary'} icon={'fi-rr-print'} onClick={printBtn} />
                        {isSendSmsBtn &&
                            <CIconBtn title={'알림톡 전송'} style={{width: 120}} variant={'success'} icon={'fi-rr-envelope'} onClick={openSendSmsPopup} />
                        }
                    </Col>
                </Row>
            </CContainer>
            
        </Modal> 
    );
}

export default observer(TaxBillDetailPopup);
