import { observable } from "mobx";

export const CertificateStore = observable({
    certificateList: [],
    comapnyList: [],
    expiration: 'available',
    data: {
        certificateSeq: '',
        certName: '',
        certPassword: '',
        signcertDer: '',
        signpriKey: '',
        derPath: '',
        keyPath: '',
        expirationDate: '',
        certUsage: '1',
        certId: '',
        businessNumber: '',
        companyCode: '',
        companyName: '',
        remark: '',
    },

    setCompanyList(v){
        this.comapnyList = v;
    },

    dataInit() {
        this.data.certificateSeq = '';
        this.data.certName = '';
        this.data.certPassword = '';
        this.data.signcertDer = '';
        this.data.signpriKey = '';
        this.data.derPath = '';
        this.data.keyPath = '';
        this.data.expirationDate = '';
        this.data.certUsage = '1';
        this.data.certId = '';
        this.data.businessNumber = '';
        this.data.companyCode = '';
        this.data.companyName = '';
        this.data.remark = '';
    },
})