import { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../../store/AppStore";
import { CContainer } from "../../../components/CustomContainer";
import { showToast } from "../../../common/utils";
import AgGridContainer from "../../../components/AgGridContainer";
import Modal from "../../../components/Modal";
import { ContractDetailStore } from "../../../store/contract/ContractDetailStore";
import StockOptionSearch from "../../../components/searchModal/StockOptionSearch";
import { ICON_TRASH, OPTION_CHARGE_TYPE } from "../../../common/constants";
import LUtils from "../../../utils/lodashUtils";
import { callConfirm } from "../../../utils";

const ContractAssetOptionPopup =({ callbackFn=null, column='', ...props })=> {
    const optionGridRef = useRef();
    const optionRef = useRef();

    const {searchContract, searchContractProduct, checkRowSeq} = props;

    useEffect(()=> {
        if(checkRowSeq){
            searchProductOption();
        }
    }, [checkRowSeq]);

    /* 계약 제품 소모품 조회 */
    const searchProductOption = async() => {
        const result = await axios.get(`/contract/product/option/${ContractDetailStore.contractNo}/${checkRowSeq}`);

        result.map(v => {
            if(v.monthRentPrice && v.qty){
                return Object.assign(v, {totalRentPrice: v.monthRentPrice * v.qty});
            }
        })

        ContractDetailStore.setProductOptionList(result);
    }


    const handleOptionQtyEdit =async(e)=> {
        optionGridRef.current.api.redrawRows();
    }
        
    const handleOptionRemarkEdit =async(e)=> {
        e.data.remark = e.newValue;
        optionGridRef.current.api.redrawRows();
    }

    /* 소모품 그리드 저장,수정 */
    const callBackOptionSave = async ({ updatedList, createdList }) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        const isCreate = !LUtils.isEmpty(createdList);

        // 재고수량 확인
        let checkQty = 0;
        if (isUpdate) {
            updatedList.map(v => {
                if(v.maxQty < v.qty){
                    checkQty++;
                }
            });
        } else if (isCreate) {
            createdList.map(v => {
                if(v.maxQty < v.qty){
                    checkQty++;
                }
            });
        }

        if (!isUpdate && !isCreate) {
            showToast('저장할 내용이 없습니다.');
            return false;
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return false;
        }

        isCreate && await axios.post(`/contract/product/option/${ContractDetailStore.contractNo}`, createdList);
        isUpdate && await axios.put(`/contract/product/option/${ContractDetailStore.contractNo}`, updatedList);

        showToast('저장 되었습니다.');

        searchContract(ContractDetailStore.contractNo);
        searchContractProduct(ContractDetailStore.contractNo);
        searchProductOption();
    }

    /* 소모품 추가 콜백 함수 */
    const callbackOptionListSearch = (info) => {
        if (!info) {
            return false;
        }

        // 동일한 제품코드가 있는지 체크
        for(let data of info){
            for(let row of ContractDetailStore.productOptionList){
                if(data.optionProductSeq == row.optionProductSeq){
                    showToast('같은 제품코드가 이미 추가되어 있습니다.');
                    return;
                }
            }
        }

        // 계약의 주기장과 같은지 체크
        if (!info.every(v => v.apronSeq === ContractDetailStore.apronSeq)) {
            showToast('계약제품과 같은 주기장의 옵션만 선택할 수 있습니다.');
            return;
        }

        let newSeq = ContractDetailStore.productOptionList.length + 1;
        const product = ContractDetailStore.contractProductList.find(v => v.seq === checkRowSeq);

        for(let i in info){
            const option = info[i];
            let seq = newSeq++;
            const additionalRowInfo = {
                addRowId: seq
                ,optionRowNumber: seq
                ,seq: seq
                ,contractProductSeq: product.seq
                ,productSeq: product.productSeq
                ,productCode: product.productCode
                ,productName: product.productName
                ,model: product.model
                ,optionProductSeq: option.productSeq
                ,optionProductName: option.productName
                ,optionModel: option.model
                ,optionProductCode: option.productCode
                ,qty: 1
                ,maxQty: option.stockQty
                ,monthRentPrice: option.monthUnitPrice
                ,totalRentPrice: option.monthUnitPrice
                ,billingChargeType: '1'
                ,isSelected: true
                ,isCreated: true /* 추가하려면 isCreated를 반드시 추가해 준다.  */
            };
            ContractDetailStore.pushProductOptionList(additionalRowInfo);
        }

        optionGridRef.current.api.redrawRows();
    }

    /* 소모품 선택항목 삭제 */
    const removeOptionSelectedRows = async ({selectedList}) => {
        if (LUtils.isEmpty(selectedList)) {
            showToast('삭제 할 항목이 없습니다.');
            return false;
        }

        if (!await callConfirm('삭제 하시겠습니까?')) {
            return false;
        }

        await axios.delete(`/contract/product/option/${ContractDetailStore.contractNo}`, {data: selectedList});

        showToast('삭제 되었습니다.');
        searchContract(ContractDetailStore.contractNo);
        searchContractProduct(ContractDetailStore.contractNo);
        searchProductOption();
    }
        
    /* 선택 버튼 옵션 - 삭제 */
    const optionSelectBtnInfo = {
        isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
        title: '선택 삭제',
        callbackFn: e => removeOptionSelectedRows(e),
        icon: ICON_TRASH
    };

    const customOptionAddBtnInfo = [
        {
            isUsed: ContractDetailStore.detailMode !== 'read' && ContractDetailStore.contractNo,
            callbackFn: () => AppStore.toggleStockOptionSearchModal(),
            title: '옵션추가',
            icon: 'fi-rr-add',
            width: 120
            //color: '#FE2E64'
        }
    ];

    const getCellChange = (e) => {
        const { field } = e.colDef;

        // 숫자만 입력 가능한 컬럼 검사
        const numberColumns = ['monthRentPrice', 'qty'];
        if (LUtils.some(LUtils.values(LUtils.pick(e.data, numberColumns)), v => isNaN(v))) {
            showToast('숫자만 입럭 가능합니다.');
            e.data[field] = 0;
        }

        // 총 렌탈료 계산
        if (LUtils.includes(['monthRentPrice', 'qty'], field)) {
            e.data.totalRentPrice = e.data.monthRentPrice * e.data.qty;
        }

        // 수량 검사
        if (field === 'qty') {
            if (Number(e.data.qty) > Number(e.data.maxQty)) {
                showToast(`가능 수량을 초과 했습니다. 가능 수량 [${e.data.maxQty}]`);
                e.data.qty = e.data.maxQty;
                e.data.totalRentPrice = e.data.monthRentPrice * e.data.maxQty;
            }
        }

        // 그리드 전체 다시 그리기
        optionGridRef.current.api.redrawRows();
    }

    const getBillingTypeName =(value)=> {
        if(value){
            return OPTION_CHARGE_TYPE.find(v => v.value === value).name;
        }
    }

    return (
        <Modal
            title={'옵션내역'}
            show={AppStore.isOpenContractAssetOptionPopup}
            onHide={() => AppStore.toggleOpenContractAssetOptionPopup()}
            toggleValue={AppStore.isOpenContractAssetOptionPopup}
            className={'search-modal-h6'}
        >
            <CContainer>
                <p>모델명에 추가된 옵션내역 입니다. 옵션을 추가할 경우 옵션추가 버튼을 클릭하여 옵션을 추가하세요</p>
                <AgGridContainer
                    gridRef={optionGridRef}
                    ref={optionRef}
                    height={40}
                    rowData={ContractDetailStore.productOptionList}
                    columnDefs={[
                        {field: "contractProductSeq", headerName: "contract_product의 제품 순번", width: 70, hide: true},
                        /* 사용안함 s */
                        {field: "productSeq", headerName: "제품시퀀스", hide: true},
                        {field: "productCode", headerName: "제품코드", width: 120, hide: true},
                        {field: "productName", headerName: "제품명", width: 200, hide: true},
                        {field: "model", headerName: "장비 모델명", width: 200, hide: true},
                        /* 사용안함 e */
                        {field: "optionRowNumber", headerName: "옵션 순번", width: 110},
                        {field: "optionSeq", headerName: "옵션 실제 순번", width: 100, headerClass: 'grid-column-required', hide: true},
                        {field: "optionProductSeq", headerName: "추가 옵션 productSeq", width: 200, headerClass: 'grid-column-required', hide: true},
                        {field: "optionProductName", headerName: "옵션명", headerClass: 'grid-column-required', width: 170},
                        {field: "optionModel", headerName: "옵션 모델명", width: 200, headerClass: 'grid-column-required', hide: true},
                        {
                            field: "qty",
                            headerName: "옵션 수량",
                            width: 110,
                            onCellValueChanged: handleOptionQtyEdit,
                            headerClass: 'grid-column-required',
                            editable: true
                        },
                        {field: "maxQty", headerName: "최대수량", hide: true},
                        {
                            field: "monthRentPrice",
                            headerName: "대당 청구금액",
                            width: 140,
                            editable: ContractDetailStore.detailMode !== 'read',
                            headerClass: 'grid-column-editable',
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                        },
                        {
                            field: "totalRentPrice",
                            headerName: "청구 합계",
                            width: 130,
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                        },
                        {
                            field: "billingChargeType", 
                            headerName: "청구방식", 
                            width: 130,
                            headerClass: 'grid-column-editable',
                            editable: true,
                            cellEditor: 'agSelectCellEditor',
                            valueFormatter: (params) => getBillingTypeName(params.value),
                            cellEditorParams: {
                                values: OPTION_CHARGE_TYPE.map(v => v.value),
                            },
                        },
                        {
                            field: "dayRentPrice",
                            headerName: "대당 일렌탈료",
                            width: 140,
                            editable: ContractDetailStore.detailMode !== 'read',
                            headerClass: 'grid-column-editable',
                            cellClass: 'ag-grid-money-align',
                            valueFormatter: v => v.value?.toLocaleString(),
                            hide: true
                        },
                        {field: "assetNo", headerName: "자산번호", width: 120, headerClass: 'grid-column-required', hide: true},
                        {field: "storageNo", headerName: "입고번호", width: 120, hide: true},
                        {
                            field: "remark",
                            headerName: "비고",
                            minWidth: 200,
                            flex: 1,
                            headerClass: 'grid-column-editable',
                            onCellValueChanged: handleOptionRemarkEdit,
                            editable: true
                        },
                    ]}
                    seqColumn={'agId'}
                    isCheckBox={true}
                    originList={[]}
                    useUpdated={ContractDetailStore.detailMode !== 'read'}
                    callBackGridData={callBackOptionSave}
                    selectBtnInfo ={optionSelectBtnInfo}
                    customBtnInfo={customOptionAddBtnInfo}
                    getCellChange={getCellChange}
                />
            </CContainer>
            {/* 옵션재고조회 팝업 */}
            <StockOptionSearch callbackFn={callbackOptionListSearch} />
        </Modal>
    )
}

export default observer(ContractAssetOptionPopup);
