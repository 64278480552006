import { observable } from "mobx";

export const HireAssetSearchStore = observable({
    condition: {
        managementNo: '',
        contractNo: '',
        siteName: '',
        assetStatus: '',
        model: '',
        serialNo: '',
        buyCustomerNo: '',
        buyCustomerName: '',
        acquStartDate: null,
        acquEndDate: null,
        status: 1,
    },

    assetList: [],

    setCondition(target, v) {
        this.condition[target] = v;
    },

    setAssetList(v){
        this.assetList = v;
    },

    init() {}
});
