import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Container, Col, Row } from "react-bootstrap";

import { CContainer } from '../../components/CustomContainer';
import { AccountStore } from '../../store/commCode/AccountStore';
import AgGridContainer from "../../components/AgGridContainer";
import { showToast } from "../../common/utils";
import CertificatePopup from "./popup/CertificatePopup";
import {findCommKrnm} from "../../utils/commcode";
import AccountManagementSearch from "./AccountManagementSearch";
import AccountManagementInfo from "./AccountManagementInfo";
import { getCompanyList, getSessionUserCompanyCode } from "../../utils/userUtils";

const AccountManagementList = () => {
    const store = AccountStore;
    const gridRef = useRef();
    const [accountList, setAccountList] = useState([]);

    useEffect(() => {
        setAccountList(store.accountList);
        //사업자 리스트 가져오기
        setCompanyInfo();
    }, []);

    const columnDefs = [
        { field: "accountManagementSeq", headerName: "계좌관리 seq", hide: true },
        { field: "companyName", headerName: "회사명", width: 160},
        { field: "bankCode", headerName: "은행", width: 140, valueFormatter: v => findCommKrnm(v.value, '24') },
        { field: "accountNumber", headerName: "계좌번호", width: 180  },
        { field: "accountHolder", headerName: "예금주", width: 160  },
        { field: "loginId", headerName: "로그인아이디", width: 120 },
        { field: "isCertificate", headerName: "인증서사용", valueGetter: v => v.data.accountManagementType === '2' ? 'Y' : '', width: 110 },
        { field: "expirationDate", headerName: "인증서만료일", width: 120},
        { field: "isRepresentative", headerName: "대표계좌여부", valueGetter: v => v.data.isRepresentative === 1 ? 'Y' : '', width: 120},
        { field: "createdDate", headerName: "등록일", minWidth: 180, flex: 1},
    ];

    /* 계좌 목록 조회 */
    const getAccountList = async () => {
        const result = await axios.get('/account/management/list', { params: store.search });
        if (result.length === 0) {
            showToast('조회된 데이터가 없습니다.');
        }
        store.accountList = result;
        setAccountList(result);
    }

    /* 계좌 상세 조회 */
    const selectAccountDetail = async (v) => {
        store.data = v.data;
    }

    /* 인증서 팝업 callback */
    const callbackCertificatePopup = (data) => {
        store.data.certName = data.certName;
        store.data.certPassword = null;
        store.data.derPath = data.derPath;
        store.data.keyPath = data.keyPath;
        store.data.expirationDate = data.expirationDate;
    }

    const setCompanyInfo = async () => {
        store.setCompanyList(await getCompanyList());
        store.data.companyCode = getSessionUserCompanyCode();
    }

    return (
        <>
            {/* 검색조건 */}
            <AccountManagementSearch getAccountList={getAccountList} />
            <Container fluid>
                <Row>
                    {/* 회원 리스트 */}
                    <Col lg={6}>
                        <CContainer>
                            <AgGridContainer
                                girdTitle={'계좌 목록'}
                                gridRef={gridRef}
                                height={60}
                                rowData={accountList}
                                columnDefs={columnDefs}
                                isCheckBox={false}
                                rowSearchCallback={selectAccountDetail}
                                rowDoubleClickCallback={selectAccountDetail}
                            />
                        </CContainer>
                    </Col>

                    {/* 계좌 상세 조회 */}
                   <AccountManagementInfo getAccountList={getAccountList} />
                </Row>
            </Container>
            <CertificatePopup callbackFn={callbackCertificatePopup}/>
        </>
    );
};

export default observer(AccountManagementList);
