
import GuestGuard from "../utils/route-guard/GuestGuard";
import { Outlet } from "react-router-dom";
import RedirectPage from "../views/redirect/RedirectPage";

const RedirectPageRoutes = {
    path: '/',
    element: (
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        ),
    children: [
        {path: '/redirect', element: <RedirectPage />}
    ]
}

export default RedirectPageRoutes;
