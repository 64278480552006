import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import axios from 'axios';

import { CCol, CContainer, CInputGroup, CSelectGroup, CIconBtn, Subject, CalendarGroup } from "../../../components/CustomContainer";
import {ReleaseAskDetailStore} from "../../../store/release/ReleaseAskDetailStore";
import Address from "../../../components/Address";
import AgGridContainer from "../../../components/AgGridContainer";
import {encrypt, decrypt, autoHyphen, localAutoHyphen} from "../../../common/utils";
import {getLocalStorage} from '../../../utils';
import {USER_INFO} from '../../../common/constants';
import {dateFormat, getDay, today} from '../../../utils/dateUtils';
import {callConfirm} from '../../../utils';
import {AppStore} from "../../../store/AppStore";
import { showToast } from "../../../common/utils";
import { getCommcode } from "../../../utils/commcode";
import Modal from "../../../components/Modal";

const ReleaseAskDetail =({customerNo, assetList, callBackFn })=> {
    const gridRef = useRef();
    const etcReleaseGridRef = useRef();

    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [returnPostcode, setReturnPostcode] = useState('');
    const [returnAddress, setReturnAddress] = useState('');
    const [returnAddressDetail, setReturnAddressDetail] = useState('');
    const [isReturnAsk, setIsReturnAsk] = useState(false);  //임차자산 반납 출고 여부

    const userInfo = getLocalStorage(USER_INFO);

    /* 기본값 세팅 */
    useEffect(()=> {
        //임차자산 반납에서 넘어올때임.
        if(customerNo) {
            ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus = '2';
            ReleaseAskDetailStore.releaseAskDetail.releaseAskType = '4';
            ReleaseAskDetailStore.releaseAskDetail.releaseAskDate = today();
            ReleaseAskDetailStore.releaseAskDetail.creator = userInfo.userSeq;
            ReleaseAskDetailStore.releaseAskDetail.creatorName = userInfo.name;
            ReleaseAskDetailStore.releaseAskDetail.releaseAskRemark = "임차 자산 반납 출고 의뢰";
            ReleaseAskDetailStore.releaseAskItemList = assetList;
            //반납하는 자산의 상태가 출고완료, 배송대기, 배송중, 임대중
            if(assetList.some(v => ['201','300','301','302'].includes(v.assetStatus))){
                setIsReturnAsk(true);
            }
            //임대회사 정보 가져오기
            customerSearch();
        }
    }, [customerNo, assetList]);

    const customerSearch = async () => {
        const result = await axios.get(`/customer/${customerNo}`);
        ReleaseAskDetailStore.releaseAskDetail.customerName = result.customerName;
        ReleaseAskDetailStore.releaseAskDetail.telephone = result.telephone;
        ReleaseAskDetailStore.releaseAskDetail.phoneNumber = result.phoneNumber;
        ReleaseAskDetailStore.releaseAskDetail.receiveUserDept = result.receiveUserDept;
        ReleaseAskDetailStore.releaseAskDetail.receiveUserPosition = result.receiveUserPosition;
        ReleaseAskDetailStore.releaseAskDetail.receiveUserName = result.receiveUserName;
        ReleaseAskDetailStore.releaseAskDetail.shipRemark = result.shipRemark;
        ReleaseAskDetailStore.releaseAskDetail.shipHopeDate = today();
        setPostcode(result.companyPostcode);
        setAddress(result.companyAddress);
        setAddressDetail(result.companyAddressDetail);
    }

    /* 출고 의뢰 */
    const saveReleaseAskStatus = async () => {
        // 필수값 확인
        let requiredParams = ['phoneNumber', 'receiveUserName', 'shipHopeDate', 'shipType'];
        if (!requiredParams.every(param => ReleaseAskDetailStore.releaseAskDetail[param]) || !postcode || !address || !addressDetail) {
            showToast('필수값을 모두 입력해야 합니다.');
            return;
        }

        // 저장 확인
        if(!isReturnAsk) {
            if(!await callConfirm('출고의뢰 하시겠습니까?')) return;
        }else{
            if(!await callConfirm('임대중 장비의 경우 가상입고 후 임대불가 자산으로 변경됩니다. 반납회수 하시겠습니까?')) return;
        }

        //ReleaseAskDetailStore.releaseAskDetail.contractNo = contractNo;
        //ReleaseAskDetailStore.releaseAskDetail.assetStatus = assetStatus;
        ReleaseAskDetailStore.releaseAskDetail.releaseAskItemList = ReleaseAskDetailStore.releaseAskItemList;
        ReleaseAskDetailStore.setReleaseAskDetail({postCode: postcode, address: address, addressDetail: addressDetail, returnPostcode: returnPostcode, returnAddress: returnAddress, returnAddressDetail: returnAddressDetail});    // 주소 세팅

        // 출고의뢰 생성 || 회수의뢰 생성
        await axios.post('/releaseAsk/return/lease', ReleaseAskDetailStore.releaseAskDetail);

        AppStore.toggleReleaseAskPopup();
        showToast('완료되었습니다.');
        callBackFn && callBackFn();
    }

    /* 초기화 */
    const refresh = () => {
        ReleaseAskDetailStore.init();
        setPostcode('');
        setAddress('');
        setAddressDetail('');
        setReturnPostcode('');
        setReturnAddress('');
        setReturnAddressDetail('');
    }

    return(
        <Modal
            title={'임차자산 반납출고의뢰'}
            show={AppStore.isOpenReleaseAskPopup}
            onHide={() => AppStore.toggleReleaseAskPopup()}
            toggleValue={AppStore.isOpenReleaseAskPopup}
            onExit={refresh}
        >
            {/* 검색조건 */}
            <CContainer search>
                <Subject>의뢰정보</Subject>
                <Row>
                    <CCol lg={3}>
                        <CSelectGroup label={'출고의뢰구분'} labelId={'releaseAskType'}
                                      placeholder={'출고의뢰구분'}
                                      options={getCommcode('39')}
                                      value={ReleaseAskDetailStore.releaseAskDetail.releaseAskType || ''}
                                      disabled={true}
                                      labelClassName="input-required"
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup asSingle={true}  label={'출고의뢰일'}
                                       value={{
                                            startDate: ReleaseAskDetailStore.releaseAskDetail.releaseAskDate,
                                            endDate: ReleaseAskDetailStore.releaseAskDetail.releaseAskDate
                                        }}
                                       disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'의뢰자명'}
                                     labelId={'creatorName'}
                                     disabled={true}
                                     value={ReleaseAskDetailStore.releaseAskDetail.creatorName || ''}
                        />
                    </CCol>
                </Row>

                <hr/>
                {isReturnAsk &&
                    <Row>
                        <CCol lg={12}>
                            <div className="mb-3">
                                <label className="form-label">반납의뢰비고</label>
                                <textarea className="form-control" rows="3"
                                            value={ReleaseAskDetailStore.releaseAskDetail.returnAskRemark || ''}
                                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnAskRemark: v.target.value})}
                                            placeholder='현장에서 회수하여 반납해야할 경우에 회수지 현장정보등을 입력합니다. 예)OO현장에서 회수 후 반납진행'
                                />
                            </div>
                        </CCol>
                    </Row>
                }
                <Row>
                    <Subject>반납지 배송정보</Subject>
                    <CCol lg={3}>
                        <CInputGroup label={'고객명'} labelId={'customerName'}
                                        disabled={true}
                                        value={ReleaseAskDetailStore.releaseAskDetail.customerName}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text>사무실</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'telephone'}
                                        placeholder={'전화번호'}
                                        value={localAutoHyphen(ReleaseAskDetailStore.releaseAskDetail.telephone) || ''}
                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({telephone: v.target.value})}
                            />
                            <InputGroup.Text className='input-required'>휴대폰</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'phoneNumber'}
                                        value={autoHyphen(decrypt(ReleaseAskDetailStore.releaseAskDetail.phoneNumber)) || ''}
                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({phoneNumber: encrypt(v.target.value)})}
                            />
                        </InputGroup>
                    </CCol>
                    <CCol lg={5}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text>부서명</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'receiveUserDept'}
                                        value={ReleaseAskDetailStore.releaseAskDetail.receiveUserDept || ''}
                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserDept: v.target.value})}
                            />
                            <InputGroup.Text>직급</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'receiveUserPosition'}
                                        value={ReleaseAskDetailStore.releaseAskDetail.receiveUserPosition || ''}
                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserPosition: v.target.value})}
                            />
                            <InputGroup.Text className="input-required">받는분</InputGroup.Text>
                            <Form.Control style={{flex: 2, textAlign: 'left'}} aria-describedby={'receiveUserName'}
                                        value={ReleaseAskDetailStore.releaseAskDetail.receiveUserName || ''}
                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserName: v.target.value})}
                            />
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={7} style={{marginTop: 12}}>
                        <Row>
                            <CCol lg={5}>
                                <CalendarGroup asSingle={true}  label={'배송희망일'}
                                            value={{
                                                    startDate: ReleaseAskDetailStore.releaseAskDetail.shipHopeDate,
                                                    endDate: ReleaseAskDetailStore.releaseAskDetail.shipHopeDate
                                                }}
                                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipHopeDate: dateFormat(v.startDate)})}
                                            minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                            labelClassName="input-required"
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CSelectGroup label={'시간'} labelId={'shipHopeTime'}
                                                options={[{name: '선택', value: ''}].concat(getCommcode('29'))}
                                            value={ReleaseAskDetailStore.releaseAskDetail.shipHopeTime || '1'}
                                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipHopeTime: v.target.value})}
                                            labelClassName="input-required"
                                />
                            </CCol>
                            <CCol lg={4}>
                                <CSelectGroup label={'배송방법'} labelId={'shipType'}
                                            placeholder={'배송방법'}
                                            options={[{name: '선택', value: ''}].concat(getCommcode('4'))}
                                            value={ReleaseAskDetailStore.releaseAskDetail.shipType || ''}
                                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipType: v.target.value})}
                                            labelClassName="input-required"
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={12} md={12}>
                                <Address label='주소'
                                        setPostcode={setPostcode}
                                        setAddress={setAddress}
                                        setAddressDetail={setAddressDetail}
                                        postcode={postcode}
                                        address={address}
                                        addressDetail={addressDetail}
                                        labelClassName='input-required'
                                />
                            </CCol>
                        </Row>
                    </CCol>
                    <CCol lg={5}>
                        <div className="mb-3">
                            <label className="form-label">배송 비고</label>
                            <textarea className="form-control" rows="2"
                                    value={ReleaseAskDetailStore.releaseAskDetail.shipRemark || ''}
                                    placeholder={'배송 상세 시간 / 배송 요청 사항'}
                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipRemark: v.target.value})}
                            />
                        </div>
                    </CCol>
                </Row>                
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn style={{width: isReturnAsk ? 180 : 110}}
                                title={isReturnAsk ? '반납회수 및 출고의뢰' : '출고의뢰'}
                                icon={'fi-rr-box-check'}
                                onClick={() => saveReleaseAskStatus()}
                        />
                        <Form.Check
                            type="switch"
                            id="isShipCompleted"
                            label="배송스케쥴 없이 완료 처리"
                            value={ReleaseAskDetailStore.releaseAskDetail.isShipCompleted === 1}
                            style={{marginTop: 8, marginRight: 20}}
                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({isShipCompleted: v.target.checked ? 1 : 0})}
                        />
                    </Col>
                </Row>

            </CContainer>

            <CContainer>
                <Subject>출고 제품 리스트</Subject>
                <AgGridContainer
                    ref={etcReleaseGridRef}
                    gridRef={gridRef}
                    height={20}
                    rowData={ReleaseAskDetailStore.releaseAskItemList}
                    columnDefs={
                        [
                            {field: "releaseAskSeq", headerName: "출고순번", hide: true},
                            {field: "productCode", headerName: "제품코드", width: 120, hide: true},
                            {field: "productSeq", headerName: "제품시퀀스", width: 120, hide: true},
                            {field: "productName", headerName: "제품명", width: 150, hide: true},
                            {field: "model", headerName: "모델명", width: 150},
                            {field: "managementNo", headerName: "관리번호", width: 150},
                            {
                                field: "qty",
                                headerName: "수량",
                                width: 90,
                            },
                            {
                                field: "assetNo",
                                headerName: "자산번호",
                                width: 120,
                                editable: false,
                            },
                            {field: "serialNo", headerName: "시리얼번호", width: 200},
                            {field: "spec", headerName: "스펙", width: 200, hide: true},
                            {field: "remark", headerName: "비고", headerClass: 'grid-column-editable', width: 200, flex: 1, editable: true},
                        ]
                    }
                />
            </CContainer>
        </Modal>
    );
}

export default observer(ReleaseAskDetail);
